import React, { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { message as msg } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import Back from "../icons/back.svg";
import "./style/contactUs.scss";

const ContactUsPage = () => {
  const history = useHistory();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [processing, setProcessing] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setProcessing(true);

    let data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("subject", subject);
    data.append("message", message);

    const config = {
      headers: {
        "content-type": "contactUs_form",
      },
    };

    axios
      .post(`${app.baseUrl}/api/send_email`, data, config)
      .then((res) => {
        msg.success(
          "Your mail has been sent, our agent will contact you shortly"
        );

        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);

        msg.error("Something went wrong");
        setProcessing(false);
      });
  };

  return (
    <div className="contact-us">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost) history.push("/");
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Contact us</div>
          </div>
        </div>
      </div>

      <div className="contact-us__body">
        <form onSubmit={submit} className="contact-us__form">
          <label htmlFor="name">
            <h4>Your name</h4>
          </label>

          <input
            id="name"
            type="text"
            className="rd-custom-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label htmlFor="email">
            <h4>Your email</h4>
          </label>

          <input
            id="email"
            type="email"
            className="rd-custom-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="subject">
            <h4>Subject</h4>
          </label>

          <input
            id="subject"
            type="text"
            className="rd-custom-input"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <label htmlFor="message">
            <h4>Message</h4>
          </label>

          <textarea
            id="message"
            type="text"
            className="rd-custom-textarea"
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
          >
            {message}
          </textarea>

          <Button
            disabled={processing}
            className="bookN w-100 mt-3"
            type="submit"
          >
            {processing ? "Sending..." : "Send"}
          </Button>
        </form>

        <div className="contact-us__card">
          <div className="contact-us__info">
            <h5>Corporate office</h5>
            <p>JUMEIRAH LAKES TOWER</p>
          </div>

          <div className="contact-us__info">
            <h5>Direct contact</h5>
            <p>
              <strong>Email:</strong> info@roomdaddy.com
            </p>
          </div>

          <div className="contact-us__info">
            <h5>Company department</h5>
            <p>To contact our team, please select one of the following:</p>
          </div>

          <div className="contact-us__links">
            <p>Sales</p>
            <p>Careers</p>
            <p>Disputes</p>
            <p>Help</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
