import React, { useState, createContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import app from "../utils/appConfig";
import moment from "moment";
import axios from "axios";
import { getLsValue, removeLsValue } from "../../utils/functions";
import { CACHE__AUTH_USER } from "../data/cacheKeys";

export const AppContext = createContext();

let check_in = moment().format("YYYY-MM-DD");
let check_out = moment().add(30, "days").format("YYYY-MM-DD");
let user = {};
let entireHome = {};
let calendarData={};

export const AppContextProvider = (props) => {
  const [numOfRooms, setNumOfRooms] = useState(1);
  const [addons, setAddons] = useState({
    breakfast: false,
    breakfast_price: 0,
    numberOfBreakfast: 1,
    internet: false,
    internet_price: 0,
    parking: false,
    parking_price: 0,
    pets: false,
    pets_price: 0,
    full_board: false,
    full_board_price: 0,
    half_board: false,
    half_board_price: 0,
    numberOfPersons: 1,
  });
  const [selectedRooms, setSelectedRooms] = useState({});
  const [selectedHotel, setSelectedHotel] = useState({});
  const [editRoomDetail, setEditRoomDetail] = useState({});
  const [editHomeDetail, setEditHomeDetail] = useState({});
  const [editHomeStepsDetail, setEditHomeStepsDetail] = useState({});
  const [editCalendarDetail, setEditCalendarDetail] = useState({});
  const [userInfo, setUserInfo] = useState({
    checkIn: check_in,
    checkOut: check_out,
    diffInDays: moment(check_out).diff(moment(check_in), "days"),
    adult: 2,
    child: 0,
    address: "Dubai - United Arab Emirates",
    lat: 25.2048493,
    lng: 55.2707828,
  });

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart"));

    if (cart) {
      setSelectedRooms(cart.selectedRooms);
      setUserInfo(cart.userInfo);
      setAddons(cart.state);
    }
  }, []);

  useEffect(() => {
    try {
      user = getLsValue(CACHE__AUTH_USER);
      setUserInfo({ ...userInfo, isLoggedIn: user.isLoggedIn, ...user.user });
    } catch {
      removeLsValue(CACHE__AUTH_USER);
      return <Redirect to="/login" />;
    }
  }, []);

  useEffect(() => {
    console.log(editRoomDetail);

    if (editRoomDetail.roomEditId) {
      axios
        .get(`${app.baseUrl}/api/edit-listing/${editRoomDetail.roomEditId}`)
        .then((res) => {
          // console.log(res);
          let {
            tv_amenities,
            window_amenities,
            fridge_amenities,
            natural_light_amenities,
            private_balcony_amenities,
            wifi_amenities,
            parking_amenities,
            elevator_amenities,
            washing_amenities,
            dishwasher_amenities,
            garden_amenities,
            terrace_amenities,
            living_room_amenities,
            wheelcheer_amenities,
            lawn_tennis,
            table_tennis,
            squash,
            gym,
            pool_amenities,
            house_rules_children,
            house_rules_couple,
            house_rules_pet,
            house_rules_smoker,
          } = res.data.room_details;
          let amenites = {
            RM: [
              { key: tv_amenities, value: "TV" },
              { key: window_amenities, value: "Window" },
              { key: fridge_amenities, value: "Fridge" },
              { key: natural_light_amenities, value: "Natural Light" },
              { key: private_balcony_amenities, value: "Private Balcony" },
            ],
            HM: [
              { key: wifi_amenities, value: "Wifi" },
              { key: parking_amenities, value: "Parking" },
              { key: elevator_amenities, value: "Elevator" },
              { key: washing_amenities, value: "Washing Machine" },
              { key: private_balcony_amenities, value: "Common Balcony" },
              { key: dishwasher_amenities, value: "Dish Washer" },
              { key: terrace_amenities, value: "Terrace" },
              { key: living_room_amenities, value: "Living Room" },
              { key: wheelcheer_amenities, value: "Wheelchair Friendly" },
            ],
            ODM: [
              { key: lawn_tennis, value: "Lawn tennis" },
              { key: table_tennis, value: "Table Tennis" },
              { key: squash, value: "Squash" },
              { key: gym, value: "Gym" },
              { key: pool_amenities, value: "Pool" },
              { key: garden_amenities, value: "Garden" },
            ],
            HRM: [
              { key: house_rules_children, value: "Children accepted" },
              { key: house_rules_couple, value: "Couples accepted" },
              { key: house_rules_pet, value: "Pet friendly" },
              { key: house_rules_smoker, value: "Smoker friendly" },
            ],
          };
          for (const key in amenites) {
            // console.log(key);
            let tempArray = [];
            for (const amanity of amenites[key]) {
              if (amanity.key === 1) {
                tempArray.push(amanity.value);
              }
            }
            amenites[key] = tempArray;
            console.log(amenites);
          }
          if (res.data.room_details.bath_type) {
            res.data.room_details.bath_type = res.data.room_details.bath_type
              .toLowerCase()
              .replace(" ", "_");
          }
          // console.log(amenites);
          setEditRoomDetail({
            ...res.data.room_details,
            ...res.data.total,
            ...amenites,
            roommate_gender: res.data.room_details.gender_room,
            other_rules: res.data.room_details.other_house_rules,
          });
          setUserInfo({
            ...userInfo,
            address: res.data.room_details.fld_address,
            lat: Number(res.data.room_details.fld_latitude),
            lng: Number(res.data.room_details.fld_longitude),
          });
        })
        .catch((err) => console.log(err));
    }
    else if (editHomeDetail.homeEditId) {
     
      axios
        .get(`${app.homeBaseUrl}/api/get_hotel_edit/${editHomeDetail.homeEditId}`)
        .then((res) => {
           console.log("hoteldetail:",res.data);
          
          entireHome =  res.data[0];
         
          setEditHomeDetail({
            ...entireHome,
          });
          setUserInfo({
            ...userInfo,
            address: entireHome.hotel_pin_location,
            lat: Number(entireHome.hotel_latitude),
            lng: Number(entireHome.hotel_longitude),
          });
        })
        .catch((err) => console.log(err));
    }

  }, [editRoomDetail,editHomeDetail]);

  useEffect(() => {
  if(editCalendarDetail.ApartmentId){
    let data = {
      room_id:'',
      appartment_id:editCalendarDetail.ApartmentId,
  };
    let uri = `${app.homeBaseUrl}/api/get_calendar_data`;
              axios.post(uri,data).then(response => {
                    calendarData = response.data;
                setEditCalendarDetail({
                  ...calendarData,
                });
              });
  }
},[editCalendarDetail]);
  const passVals = {
    selectedRooms,
    setSelectedRooms,
    selectedHotel,
    setSelectedHotel,
    userInfo,
    setUserInfo,
    editRoomDetail,
    setEditRoomDetail,
    editHomeDetail,
    setEditHomeDetail,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
    editCalendarDetail,
    setEditCalendarDetail,
    editHomeStepsDetail,
    setEditHomeStepsDetail,
  };

  return (
    <AppContext.Provider value={passVals}>{props.children}</AppContext.Provider>
  );
};
