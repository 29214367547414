import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import ModalProfile from "../Flatshare/FlatshareProfile";
import ChatBot from '../Chatbox'
import Ellipsis from "../../components/ellipsis";
import Header from "../../components/pageHeader/index"
import axios from 'axios';
import app from '../../utils/appConfig'
import Map from '../Map/FullMap';

export default function ParkingDetails() {
    const { id } = useParams();
    console.log("detail id", id, btoa(id))
    const [parkingDetail, setParkingDetail] = useState([])
    useEffect(() => {
        axios.get(`${app.baseUrl}/api/get-parking-detail/${id}`).then((res) => {
            console.log("parkingDetail API", res.data);
            setParkingDetail(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <>
            <Header
                arrowLink={"/parkings"}
                heading={"Parking Details"}
            />
            <div className="container mt-6">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <p>{parkingDetail.prk_title}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="small vm">
                                    <span className=" text-secondary">{parkingDetail.fld_building} </span>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon-size-12 vm" viewBox="0 0 24 24">
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path fill="#FFD500" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                    </svg> */}
                                    <span className=" text-secondary">| {parkingDetail.Country_name}</span>
                                </p>
                            </div>
                            {/* <div className="col-auto">
                                <p className="small text-secondary">$ 12.5 AED</p>
                            </div> */}
                        </div>
                    </div>

                    <div className="card-body border-top border-color">
                        <div className="map-box mb-4" style={{ position: "relative", height: "230px" }}>
                            <Map mapItems={[{ latitude: parkingDetail.search_latitude, longitude: parkingDetail.search_longitude }]} defaultCenter={{ lat: Number(parkingDetail.search_latitude), lng: Number(parkingDetail.search_longitude) }} />
                        </div>
                        <div className="text-secondary">
                            <p className="mb-1">Address:</p>
                            <div className="row">
                                <div className="col-12 text-secondary small mb-2">
                                    {/* {parkingDetail.prk_street_address} */}
                                    <ul class="text-secondary small lists">
                                        <li>
                                            <span class="listing-address-span-Cm "> UAE / </span>
                                            <span class="listing-address-span-m "> {parkingDetail.city} / </span>
                                            <span class="listing-address-span-m"> {parkingDetail.fld_area}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h6>Money Matters</h6>
                        <div className="text-secondary">
                            <ul class="text-secondary small lists">
                                <li>Monthly Rent : <span class="listing-address-span-m float-right ">{parkingDetail.monthly_price} </span></li>
                                <li>Deposit : <span class="listing-address-span-m float-right ">{parkingDetail.initial_deposit} </span></li>
                            </ul>
                        </div>
                        <h6>Property Detail</h6>
                        <div className="text-secondary">
                            <ul class="text-secondary small lists">
                                <li>Parking Type : <span class="listing-address-span-m float-right ">{parkingDetail.Parking_type}</span></li>
                                <li>Parking Floor Number : <span class="listing-address-span-m float-right ">{parkingDetail.Floor}</span></li>
                                <li>Availability : <span class="listing-address-span-m float-right">From {parkingDetail.prk_start_date}</span></li>
                            </ul>
                        </div>

                        <h6>Description</h6>
                        <div className="text-secondary">
                            {/* <p className="mb-1">Everest Buildcon</p> */}

                            <Ellipsis
                                classStyle={"small"}
                                paragraph={parkingDetail.property_description}
                                linesToShow={2}
                                showText={"more"}
                                hideText={"less"}
                            />
                        </div>
                        <ModalProfile
                            img={parkingDetail.fld_profile_pic}
                            modalCallButton={"Contact"}
                            title={"Seller Profile"}
                            subTitle={"Seller info"}
                            name={parkingDetail.name}
                            gender={""}
                            languages={parkingDetail.languages}
                            contactText={"show contact"}
                            contactNumber={parkingDetail.contact_number}
                            email={parkingDetail.email}
                            accordionTitle={"Want to send email"}
                            textareName={"message"}
                            textareRow={4}
                            textarePlaceholder={"Write your message"}
                            textareText={"I saw your ad on Roomdaddy. When is it available for viewing? Thanks."}
                            accordionButton={"Send message"}
                        />
                    </div>
                </div>
            </div>
            <ChatBot
                name={parkingDetail.fld_name}
                number={parkingDetail.contact_number}
                textareaRow={4}
                message={"I saw your ad on Roomdaddy. When is it available for viewing? Thanks."}
                button={"Send Message"}
            />
        </>
    )
}
