import React from "react";
import "./index.scss";

const RdLoader = () => {
  return (
    <div class="rd-custom-loader">
      <div class="loader-ring">&nbsp;</div>
    </div>
  );
};

export default RdLoader;
