import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import app from "../utils/appConfig";
import { getUser } from "../../utils/functions";
import pusher from "../../utils/pusher";
import PageHeader from "../_newComponents/pageHeader";
import ChatUser from "../_newComponents/ChatUser";
import MobileNav from "../_newComponents/MobileNav";
import NotificationImage from "../images/notification.svg";
import "./style/chatPage.scss";

const ChatPage = () => {
  const user = getUser();
  const history = useHistory();

  if (!user) history.push("/login");

  const [chats, setChats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllUser = async () => {
      await axios
        .get(`${app.baseUrl}/api/getAllChatUser/${user?.id}`)
        .then((res) => setChats(res.data))
        .catch((err) => console.log(err));

      setLoading(false);
    };

    const chatPageChannel = pusher.subscribe("rd-channel");
    chatPageChannel.bind("rd-event", (data) => {
      getAllUser();
    });

    getAllUser();

    return () => chatPageChannel.unbind_all();
  }, []);

  return (
    <div className="chat">
      <PageHeader homeLink="/" isCustom title="Inbox" />

      <div className="chat__chats">
        {chats?.allreceiver?.map((receiver, i) => (
          <ChatUser key={i} all_receiver={receiver} />
        ))}

        {!loading && !chats?.allreceiver?.length && (
          <div className="chat__no-chats">
            <div className="chat__no-chats-img">
              <img src={NotificationImage} alt="No Messages - RoomDaddy" />
            </div>

            <h3>You have no active conversations</h3>

            <p>
              Plan a new trip and explore places to stay close to all the places
              you love. <Link to="/co-living">Browse Places</Link>
            </p>
          </div>
        )}

        <div className="chat__void">&nbsp;</div>
      </div>

      <MobileNav />
    </div>
  );
};

export default ChatPage;
