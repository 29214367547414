import React from 'react'
import Footer from '../_newComponents/Footer'
import Tiles from '../_newComponents/Tiles'
import image1 from '../images/Rectangle 10.png'
import image2 from '../images/Rectangle 11.png'
import image3 from '../images/image 42.png'
import { About_tiles_item } from '../data/tilesData'
import PageHeader from '../_newComponents/pageHeader'

export default function AboutBolt() {
  return (
    <>
        <PageHeader homeLink="/" />
        <div className='blog_body'>
            <div className='nHeader'>
                <div className='nHeader_bg'>
                    <p className='montserratN900-45 text-white'>About BOLT</p>
                </div>
            </div>
            <div className='card-body'>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    BOLT is a Property management system (PMS), a consolidated software with a set of solutions that property manager with several properties can use, to MANAGE (organize, schedule and perform) their day-to-day operations such as bookings, payments and invoicing, maintenance, analytics (occupancy and revenue) and reporting.<br/>
                    BOLT eases and makes workflows efficient by streamlining operations to create a seamless guest experience. <br/>
                    BOLT is a custom built ERP which enables a seamless digital experience for all interactions between the tenants and Property managers/owners.<br/>
                </p>
                <div className='mt-5'>
                    <div className='section pb-5'>
                        <img className='w-100 borderRadius-10' src={`${image1}`} alt='RMD_img'/>
                    </div>
                    <div className='section pb-5'>
                        <img className='w-100 borderRadius-10' src={`${image2}`} alt='RMD_img'/>
                    </div>
                    {/* <div className='section pb-5'>
                        <img className='w-100 borderRadius-10' src={`${image3}`} alt='RMD_img'/>
                    </div> */}
                </div>
            </div>
            <div class="mb-4 futuraN700-16">
                <a href="https://crm.roomdaddy.com/pages-register.php">
                <button class="btn-primary w_kit_a_W text-lightBlack m-2">BOLT Sign-up</button>
                </a>
            </div>
            <div className='card-body'>
                <p class="futuraN700-20 pt-4">Features of BOLT</p>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    We have you covered and provide a hassle free solution to manage all your day to day operations. We bring to you:             
                </p>
            </div>
            <Tiles items={About_tiles_item}/>
            <Footer/>
        </div>
    </>
  )
}
