import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
// Amenities
import NaturalLight from "../../icons/naturalLights.svg";
import Bath from "../../icons/bath.svg";
import Window from "../../icons/window.svg";
import Fridge from "../../icons/fridge.svg";
import Tv from "../../icons/tv.svg";
import Gym from "../../icons/fitness.svg";
import FreeWifi from "../../icons/internet.svg";
import Parking from "../../icons/parkingAm.svg";
import Lake from "../../icons/lake.svg";
import Park from "../../icons/park.svg";
import Elevator from "../../icons/elevator.svg";
import Laundry from "../../icons/laundry.svg";
import Balcony from "../../icons/balcony.svg";
import Wheelchair from "../../icons/wheelchair.svg";
import Terrace from "../../icons/terrace.svg";
import DishWasher from "../../icons/dishwasher.svg";
import Pool from "../../icons/pool.svg";
import Landscape from "../../icons/landscape.svg";
import Squash from "../../icons/tennis.svg";
import TableTennis from "../../icons/table-tennis.svg";
import TennisCourt from "../../icons/tennis-court.svg";
import CommonRoom from "../../icons/relax.svg";
import Smoke from "../../icons/smoking.svg";
import Couple from "../../icons/couple.svg";
import Pets from "../../icons/pets.svg";
import Children from "../../icons/toys.svg";
import Male from "../../icons/mars.svg";
import Female from "../../icons/femenine.svg";
import Mix from "../../icons/mixG.svg";
import Shared from "../../icons/maleFemale.svg";

// Recent
import Plus from "../../icons/plus.svg";
import Minus from "../../icons/minus.svg";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Button } from "@material-ui/core";
import { message } from "antd";

export default function StepTwo({ nextStep }) {
  const { userInfo, setUserInfo, editRoomDetail } = useContext(AppContext);
  const [secondStepInfo, setSecondStepInfo] = useState({
    fld_type: "",
    bath: "",
    roommate_gender: "",
    Room_amenities: [],
    house_amenities: [],
    outDoorAmenties: [],
    house_rules: [],
    other_rules: "",
    occupancy: 1,
    room_mates_male: 0,
    room_mates_female: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function sendFormData() {
    for (let v in secondStepInfo) {
      if (v === "occupancy" && secondStepInfo[v] == 0) {
        message.error("Room capacity should be minimum of 1");
        console.log("empty -->", v, secondStepInfo[v]);
        return;
      }

      // if (Array.isArray(secondStepInfo[v]) && !secondStepInfo[v].length) {
      //   message.error("One or more fields are empty");
      //   console.log("empty -->", v, secondStepInfo[v]);
      //   return;
      // }

      if (v === "other_rules" || v === "fld_building_id") continue;

      if ((v === "fld_type" || v === "bath" || v === "roommate_gender") && secondStepInfo[v] === "") {
        message.error("One or more fields are empty");
        console.log("empty -->", v, secondStepInfo[v]);
        return;
      }
    }

    let data = new FormData();

    for (let i in secondStepInfo) data.append(i, secondStepInfo[i]);

    data.append("fld_building_id", userInfo.propertyId);
    data.append("fld_tanent", userInfo.id);
    data.append("room_id", editRoomDetail.room_id);
    data.append("bedspace_id", editRoomDetail.fld_id);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${app.baseUrl}/api/step-two`, data, config)
      .then((res) => {
        message.success("Step two completed");
        let { room_id, Bedspace_id } = res.data;
        // userInfo.roomId = res.data.room_id;
        // userInfo.bedspaceId = res.data.Bedspace_id;
        setUserInfo({ ...userInfo, roomId: room_id, bedspaceId: Bedspace_id });
        nextStep(2);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    console.log(secondStepInfo);
  }, [secondStepInfo]);

  function updateSecondForm(property, value) {
    if (
      property === "Room_amenities" ||
      property === "house_amenities" ||
      property === "outDoorAmenties" ||
      property === "house_rules"
    ) {
      if (secondStepInfo[property].includes(value)) {
        const index = secondStepInfo[property].indexOf(value);
        secondStepInfo[property].splice(index, 1);
        setSecondStepInfo({
          ...secondStepInfo,
          [property]: secondStepInfo[property],
        });
      } else {
        secondStepInfo[property].push(value);
        setSecondStepInfo({
          ...secondStepInfo,
          [property]: [...secondStepInfo[property]],
        });
      }
    } else if (
      [property] == "occupancy" ||
      [property] == "room_mates_male" ||
      [property] == "room_mates_female"
    ) {
      if (value == "add") {
        setSecondStepInfo({
          ...secondStepInfo,
          [property]: secondStepInfo[property] + 1,
        });
      } else if (value == "sub") {
        if (secondStepInfo[property] !== 0) {
          setSecondStepInfo({
            ...secondStepInfo,
            [property]: secondStepInfo[property] - 1,
          });
        }
      }
    } else {
      setSecondStepInfo({ ...secondStepInfo, [property]: value });
    }
  }

  useEffect(() => {
    if (editRoomDetail.room_id) {
      setSecondStepInfo({
        fld_building_id: userInfo.propertyId,
        fld_type: editRoomDetail.fld_type ? editRoomDetail.fld_type : "",
        bath: editRoomDetail.bath_type ? editRoomDetail.bath_type : "",
        roommate_gender: editRoomDetail.roommate_gender
          ? editRoomDetail.roommate_gender
          : "",
        occupancy: editRoomDetail.occupancy ? editRoomDetail.occupancy : 0,
        room_mates_male: editRoomDetail.room_mates_male
          ? editRoomDetail.room_mates_male
          : 0,
        room_mates_female: editRoomDetail.room_mates_female
          ? editRoomDetail.room_mates_female
          : 0,
        other_rules: editRoomDetail.other_rules
          ? editRoomDetail.other_rules
          : "",
        Room_amenities: editRoomDetail.RM,
        house_amenities: editRoomDetail.HM,
        outDoorAmenties: editRoomDetail.ODM,
        house_rules: editRoomDetail.HRM,
      });
    }
  }, [editRoomDetail, userInfo]);

  return (
    <div className="py-3 px-2">
      <div className="container pb-4 px-3">
        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            What do you want to list?*
          </label>
          <div className="mr-3"></div>

          <Checkbox
            checked={secondStepInfo.fld_type === "R" ? "checked" : false}
            name="roombedradio"
            onChange={() => updateSecondForm("fld_type", "R")}
          >
            Private Room
          </Checkbox>

          <Checkbox
            checked={secondStepInfo.fld_type === "B" ? "checked" : false}
            name="roombedradio"
            onChange={() => updateSecondForm("fld_type", "B")}
          >
            Shared Room
          </Checkbox>

          {/* <div className="custom-control custom-switch">
            <input
              type="radio"
              name="roombedradio"
              className="custom-control-input"
              id="room"
              checked={secondStepInfo.fld_type === "R" ? "checked" : false}
              onChange={() => updateSecondForm("fld_type", "R")}
            />
            <label
              className="custom-control-label futuraBkBtN-14 pl-5"
              for="room"
            >
              Room
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              type="radio"
              name="roombedradio"
              className="custom-control-input"
              id="bedspace"
              checked={secondStepInfo.fld_type === "B" ? "checked" : false}
              onChange={() => updateSecondForm("fld_type", "B")}
            />
            <label
              className="custom-control-label futuraBkBtN-14 pl-5"
              for="bedspace"
            >
              Bedspace
            </label>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col pb-4">
            <div className="room_type">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Bath*
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="radio"
                      id="s-option"
                      name="private_washroom_amenities"
                      checked={
                        secondStepInfo.bath === "private_washroom"
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("bath", "private_washroom")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Bath}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Private{" "}
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="s-option"
                      name="private_washroom_amenities"
                      checked={
                        secondStepInfo.bath === "shared_washroom"
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("bath", "shared_washroom")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Shared}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Shared{" "}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="room_mates">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Who are you Looking for?*{" "}
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="radio"
                      className="disable_type"
                      id="s-option"
                      name="roommate_gender"
                      value="M"
                      checked={
                        secondStepInfo.roommate_gender === "M"
                          ? "checked"
                          : false
                      }
                      onChange={() => updateSecondForm("roommate_gender", "M")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Male}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Male
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      className="disable_type"
                      id="s-option"
                      name="roommate_gender"
                      value="F"
                      checked={
                        secondStepInfo.roommate_gender === "F"
                          ? "checked"
                          : false
                      }
                      onChange={() => updateSecondForm("roommate_gender", "F")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Female}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Female
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      className="disable_type"
                      id="s-option"
                      name="roommate_gender"
                      value="M/F"
                      checked={
                        secondStepInfo.roommate_gender === "M/F"
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("roommate_gender", "M/F")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Mix}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Any{" "}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Room Capacity
              </h6>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="futuraBkBtN-14 m-auto">Capacity</p>
                </div>
                <div className="d-flex">
                  <img
                    src={Minus}
                    alt="minus_icon"
                    onClick={() => updateSecondForm("occupancy", "sub")}
                  />
                  <div className="count">{secondStepInfo.occupancy}</div>
                  <img
                    src={Plus}
                    alt="plus_icon"
                    onClick={() => updateSecondForm("occupancy", "add")}
                  />
                </div>
              </div>
            </div>
            <div>
              <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Do you have any Flatmates?
              </h6>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="futuraBkBtN-14 m-auto">Male</p>
                </div>
                <div className="d-flex">
                  <img
                    src={Minus}
                    alt="minus_icon"
                    onClick={() => updateSecondForm("room_mates_male", "sub")}
                  />
                  <div className="count">{secondStepInfo.room_mates_male}</div>
                  <img
                    src={Plus}
                    alt="plus_icon"
                    onClick={() => updateSecondForm("room_mates_male", "add")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="futuraBkBtN-14 m-auto">Female</p>
                </div>
                <div className="d-flex">
                  <img
                    src={Minus}
                    alt="minus_icon"
                    onClick={() => updateSecondForm("room_mates_female", "sub")}
                  />
                  <div className="count">
                    {secondStepInfo.room_mates_female}
                  </div>
                  <img
                    src={Plus}
                    alt="plus_icon"
                    onClick={() => updateSecondForm("room_mates_female", "add")}
                  />
                </div>
              </div>
            </div>

            <div className="amenities_check">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Room amenities{" "}
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      className="Room_amenities"
                      name="Room_amenities"
                      value=""
                      checked={
                        secondStepInfo.Room_amenities?.includes("TV")
                          ? "checked"
                          : false
                      }
                      onChange={() => updateSecondForm("Room_amenities", "TV")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Tv}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        TV
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="Room_amenities"
                      id="s-option"
                      name="Room_amenities"
                      value=""
                      checked={
                        secondStepInfo.Room_amenities?.includes(
                          "Private Balcony"
                        )
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("Room_amenities", "Private Balcony")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Balcony}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Private Balcony
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="Room_amenities"
                      id="s-option"
                      name="Room_amenities"
                      value=""
                      checked={
                        secondStepInfo.Room_amenities.includes("Window")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("Room_amenities", "Window")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Window}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Window
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="Room_amenities"
                      id="s-option"
                      name="Room_amenities"
                      value=""
                      checked={
                        secondStepInfo.Room_amenities.includes("Fridge")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("Room_amenities", "Fridge")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Fridge}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Fridge
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      className="Room_amenities"
                      id="s-option"
                      name="Room_amenities"
                      value=""
                      checked={
                        secondStepInfo.Room_amenities.includes("Natural Light")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("Room_amenities", "Natural Light")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={NaturalLight}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Natural Light
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="amenities_check">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                House amenities
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="wifi_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Wifi")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Wifi")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={FreeWifi}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Wifi
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="parking_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Parking")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Parking")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Parking}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Parking
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      name="elevator_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Elevator")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Elevator")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Elevator}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Elevator
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      name="washing_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Washing Machine")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Washing Machine")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Laundry}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Washing machine
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="balcony_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes(
                          "Common Balcony"
                        )
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Common Balcony")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Balcony}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Common Balcony
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="dishwasher_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Dish Washer")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Dish Washer")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={DishWasher}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Dish Washer
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="wheelcheer_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes(
                          "Wheelchair Friendly"
                        )
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm(
                          "house_amenities",
                          "Wheelchair Friendly"
                        )
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Wheelchair}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Wheelchair Friendly
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="terrace_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Terrace")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Terrace")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Terrace}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Terrace
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="living_room_amenities"
                      checked={
                        secondStepInfo.house_amenities.includes("Living Room")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_amenities", "Living Room")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={CommonRoom}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Living room
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="purpose-error1 555555"></div>
              </div>
            </div>
            <div className="amenities_check house_rules">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Outdoor Amenities
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="gym_amenities"
                      checked={
                        secondStepInfo.outDoorAmenties.includes("Gym")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("outDoorAmenties", "Gym")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Gym}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Gym
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="pool_amenities"
                      checked={
                        secondStepInfo.outDoorAmenties.includes("Pool")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("outDoorAmenties", "Pool")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Pool}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Pool
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="garden_amenities"
                      checked={
                        secondStepInfo.outDoorAmenties.includes("Garden")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("outDoorAmenties", "Garden")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Park}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Garden
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="table_tennis"
                      checked={
                        secondStepInfo.outDoorAmenties.includes("Table Tennis")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("outDoorAmenties", "Table Tennis")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={TableTennis}
                        alt="table_tennis"
                      />
                      <label for="s-option" className="label_active">
                        Table Tennis
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="Sqaush"
                      checked={
                        secondStepInfo.outDoorAmenties.includes("Squash")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("outDoorAmenties", "Squash")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Squash}
                        alt="table_tennis"
                      />
                      <label for="s-option" className="label_active">
                        Squash
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="lone_tennis"
                      checked={
                        secondStepInfo.outDoorAmenties.includes("Lawn tennis")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("outDoorAmenties", "Lawn tennis")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={TennisCourt}
                        alt="table_tennis"
                      />
                      <label for="s-option" className="label_active">
                        Lawn Tennis
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="purpose-error1 555555"></div>
              </div>
            </div>
            <div className="amenities_check house_rules">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Are there any house rules?
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      className="disable_type"
                      name="house_rules_smoker"
                      checked={
                        secondStepInfo.house_rules.includes("Smoker friendly")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_rules", "Smoker friendly")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Smoke}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Smoker friendly
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      className="disable_type"
                      name="house_rules_pet"
                      checked={
                        secondStepInfo.house_rules.includes("Pet friendly")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_rules", "Pet friendly")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Pets}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Pet friendly
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      className="disable_type"
                      name="house_rules_couple"
                      checked={
                        secondStepInfo.house_rules.includes("Couples accepted")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_rules", "Couples accepted")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Couple}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Couples accepted
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      className="disable_type"
                      name="house_rules_children"
                      checked={
                        secondStepInfo.house_rules.includes("Children accepted")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("house_rules", "Children accepted")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Children}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Children accepted
                      </label>
                    </div>
                  </li>
                </ul>
                <div class="purpose-error1 555555"></div>
              </div>
            </div>
            <div className="amenities_check house_rules">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Other house rules?
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      value="strictly_male"
                      className="disable_type"
                      type="radio"
                      id="f-option"
                      name="other_house_rules"
                      checked={
                        secondStepInfo.other_rules === "strictly_male"
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("other_rules", "strictly_male")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Male}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Strictly male
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      value="strictly_female"
                      className="disable_type"
                      type="radio"
                      id="f-option"
                      name="other_house_rules"
                      checked={
                        secondStepInfo.other_rules === "strictly_female"
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("other_rules", "strictly_female")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Female}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Strictly female
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      value="mix"
                      type="radio"
                      className="disable_type"
                      id="f-option"
                      name="other_house_rules"
                      checked={
                        secondStepInfo.other_rules === "mix" ? "checked" : false
                      }
                      onChange={() => updateSecondForm("other_rules", "mix")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Mix}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Mix
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sendFormData}
      >
        Continue
      </Button>
      {/* </form> */}
    </div>
  );
}
