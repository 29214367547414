import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { message } from "antd";
import { Avatar, Button } from "@material-ui/core";
import { AppContext } from "../AppContext";
import app from "../utils/appConfig";
import PageHeader from "../_newComponents/pageHeader";
import ConfirmPopup from "../_newComponents/ConfirmPopup";
import MobileNav from "../_newComponents/MobileNav";
import "./style/profile.scss";

const ProfilePage = () => {
  const [isVerifyContact, setIsVerifyContact] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const { userInfo } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [verify, setVerify] = useState({
    otpOne: "",
    otpTwo: "",
    otpThree: "",
    otpFour: "",
  });
  const [verifyEmail, setVerifyEmail] = useState({
    otpOne: "",
    otpTwo: "",
    otpThree: "",
    otpFour: "",
  });

  useEffect(() => {
    if (userInfo.id)
      axios
        .get(`${app.baseUrl}/api/get-profile?user_id=${userInfo.id}`)
        .then((res) => {
          let data = res.data.data;

          setProfile({
            is_email_verified: data.is_email_verified,
            is_phone_verified: data.is_phone_verified,
            contryCode: data.country_code,
            profile: data.fld_profile_pic,
            name: data.fld_name,
            email: data.email,
            phNumber: data.fld_number,
            dateOfBirth: `${
              data.dob_day
                ? data.dob_day + "/" + data.dob_month + "/" + data.dob_year
                : ""
            }`,
            nationality: data.nationality,
            languages: data.languages,
            sex: data.fld_sex,
          });

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong! Please try again.");
          setLoading(false);
        });
  }, [userInfo]);

  const showEmailModal = () => {
    axios
      .get(`${app.baseUrl}/api/verifyemail`)
      .then((res) => console.log(">>> email verify ->", res))
      .catch((err) => console.log(err));

    setIsVerifyEmail(true);
  };

  const handleVerifyEmail = () => {
    let data = new FormData();
    data.append(
      "code",
      `${verifyEmail.otpOne}${verifyEmail.otpTwo}${verifyEmail.otpThree}${verifyEmail.otpFour}`
    );
    data.append("user_id", userInfo.id);
    axios
      .post(`${app.baseUrl}/api/verifyemail`, data)
      .then((res) => console.log("--> email verify ->", res))
      .catch((err) => console.log(err));
    setIsVerifyEmail(false);
  };

  const showModal = () => {
    axios
      .get(
        `${app.baseUrl}/api/verifymobile?country_code=${profile?.contryCode}&phone_number=${profile?.phNumber}&user_id=${userInfo.id}`
      )
      .then((res) => console.log(">>> phone verify ->", res))
      .catch((err) => console.log(err));

    setIsVerifyContact(true);
  };

  const handleVerifyContact = () => {
    let data = new FormData();

    data.append(
      "code",
      `${verify.otpOne}${verify.otpTwo}${verify.otpThree}${verify.otpFour}`
    );
    data.append("user_id", userInfo.id);

    axios
      .post(`${app.baseUrl}/api/code_verify`, data)
      .then((res) => console.log("--> phone verify ->", res))
      .catch((err) => console.log(err));

    setIsVerifyContact(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setIsVerifyContact(false);
    setIsVerifyEmail(false);
  };

  const handleInputs = (index) => {
    const elems = document.getElementsByClassName("verify__input");

    if (index < elems.length)
      document.getElementById(`otp-${index + 1}`).focus();
    else document.getElementById("popup-confirm-btn").focus();

    return;
  };

  return (
    <div className="profile">
      <PageHeader homeLink="/" isCustom title="My Profile" />

      {loading ? (
        <></>
      ) : (
        <>
          <div className="profile__top">
            <Avatar src={profile?.profile} alt={profile?.name} />

            <div className="profile__top--info">
              <h2>{profile?.name}</h2>
              <p className="mb-0">{profile?.nationality}</p>
            </div>
          </div>

          <div className="profile__body">
            <div className="profile__body-info">
              <h3>Email</h3>

              <p>
                {profile?.email}&nbsp;
                {profile?.is_email_verified ? (
                  <span className="info-verified">Verified</span>
                ) : (
                  <span onClick={showEmailModal} className="info-unverified">
                    Verify email
                  </span>
                )}
              </p>
            </div>

            <div className="profile__body-info">
              <h3>Phone Number</h3>

              <p>
                {profile?.phNumber ? (
                  <>
                    +{profile?.contryCode} {profile?.phNumber}&nbsp;
                    {profile?.is_phone_verified ? (
                      <span className="info-verified">Verified</span>
                    ) : (
                      <span onClick={showModal} className="info-unverified">
                        Verify contact
                      </span>
                    )}
                  </>
                ) : (
                  <Link to="/profile/edit">
                    <span className="info-nothing">
                      Please add your phone number
                    </span>
                  </Link>
                )}
              </p>
            </div>

            <div className="profile__body-info">
              <h3>Languages</h3>
              <p>
                {profile?.languages ? (
                  profile?.languages?.split(",").join(", ")
                ) : (
                  <Link to="/profile/edit">
                    <span className="info-nothing">
                      Please add your spoken languages
                    </span>
                  </Link>
                )}
              </p>
            </div>

            <div className="profile__body-info">
              <h3>Gender</h3>
              <p>
                {profile?.sex === "M" ? (
                  "Male"
                ) : profile?.sex === "F" ? (
                  "Female"
                ) : (
                  <span className="info-nothing">Unspecified</span>
                )}
              </p>
            </div>

            <div className="profile__body-info">
              <h3>Birth date</h3>

              <p>
                {profile?.dateOfBirth ? (
                  moment(profile?.dateOfBirth, "DD/MM/YYYY").format(
                    "DD MMM, YYYY"
                  )
                ) : (
                  <span className="info-nothing">Unspecified</span>
                )}
              </p>
            </div>
          </div>

          <div className="profile__edit text-center">
            <Link to="/profile/edit">
              <Button className="bookN w-100">Edit profile</Button>
            </Link>

            <p className="py-4">
              <Link
                to="/change-password"
                className="text-darkBlue"
                style={{ fontFamily: "futuraMdBt" }}
              >
                Change Password
              </Link>
            </p>
          </div>

          {isVerifyEmail && (
            <ConfirmPopup
              title="Verify email"
              isModal
              onConfirm={handleVerifyEmail}
              onCancel={handleCancel}
            >
              <div className="verify__inputs">
                <input
                  id="otp-1"
                  value={verifyEmail.otpOne}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(1);
                    setVerifyEmail({ ...verifyEmail, otpOne: e.target.value });
                  }}
                  className="verify__input"
                />

                <input
                  id="otp-2"
                  value={verifyEmail.otpTwo}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(2);
                    setVerifyEmail({ ...verifyEmail, otpTwo: e.target.value });
                  }}
                  className="verify__input"
                />

                <input
                  id="otp-3"
                  value={verifyEmail.otpThree}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(3);
                    setVerifyEmail({
                      ...verifyEmail,
                      otpThree: e.target.value,
                    });
                  }}
                  className="verify__input"
                />

                <input
                  id="otp-4"
                  value={verifyEmail.otpFour}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(4);
                    setVerifyEmail({ ...verifyEmail, otpFour: e.target.value });
                  }}
                  className="verify__input"
                />
              </div>
            </ConfirmPopup>
          )}

          {isVerifyContact && (
            <ConfirmPopup
              title="Verify contact"
              isModal
              onConfirm={handleVerifyContact}
              onCancel={handleCancel}
            >
              <div className="verify__inputs">
                <input
                  id="otp-1"
                  value={verify.otpOne}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(1);
                    setVerify({ ...verify, otpOne: e.target.value });
                  }}
                  className="verify__input"
                />

                <input
                  id="otp-2"
                  value={verify.otpTwo}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(2);
                    setVerify({ ...verify, otpTwo: e.target.value });
                  }}
                  className="verify__input"
                />

                <input
                  id="otp-3"
                  value={verify.otpThree}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(3);
                    setVerify({ ...verify, otpThree: e.target.value });
                  }}
                  className="verify__input"
                />

                <input
                  id="otp-4"
                  value={verify.otpFour}
                  maxLength="1"
                  onChange={(e) => {
                    handleInputs(4);
                    setVerify({ ...verify, otpFour: e.target.value });
                  }}
                  className="verify__input"
                />
              </div>
            </ConfirmPopup>
          )}
        </>
      )}

      <MobileNav />
    </div>
  );
};

export default ProfilePage;
