import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { getUser } from "../../utils/functions";
import MobileNav from "../_newComponents/MobileNav";
import BackIcon from "../icons/back.svg";
import "./style/changePassword.scss";

const ChangePassword = () => {
  const user = getUser();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const changePassword = () => {
    if (!oldPass || !newPass || !confirmPass || newPass !== confirmPass) {
      message.error("New password and confirm password do not match");
      return;
    }

    setLoading(true);

    const data = new FormData();

    data.append("user_id", user?.id);
    data.append("old_pass", oldPass);
    data.append("new_pass", newPass);
    data.append("conf_pass", confirmPass);

    axios
      .post(`${app.baseUrl}/api/change-password`, data)
      .then((res) => {
        if (res.data.status) {
          message.success(res.data.message);
          history.push("/profile");
        } else {
          message.error(res.data.message);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
        message.error("An error occurred");
      });
  };

  return (
    <div className="change-pass">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={BackIcon}
              alt="back_Arrow"
              onClick={() => history.push("/profile")}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Change Password</div>
          </div>
        </div>
      </div>

      <div className="change-pass__body">
        <label htmlFor="old_pass">Old Password</label>
        <input
          id="old_pass"
          type="password"
          className="rd-custom-input"
          value={oldPass}
          onChange={(e) => setOldPass(e.target.value)}
        />

        <label htmlFor="new_pass">New Password</label>
        <input
          id="new_pass"
          type="password"
          className="rd-custom-input"
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
        />

        <label htmlFor="conf_pass">Confirm Password</label>
        <input
          id="conf_pass"
          type="password"
          className="rd-custom-input"
          value={confirmPass}
          onChange={(e) => setConfirmPass(e.target.value)}
        />

        <Button
          disabled={loading}
          className="bookN w-100"
          onClick={changePassword}
        >
          {loading ? "Changing" : "Change"}
        </Button>
      </div>

      <MobileNav />
    </div>
  );
};

export default ChangePassword;
