import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { AutoComplete } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import PageHeader from "../_newComponents/pageHeader";
import FilterButtons from "../_newComponents/FilterButtons";
import MobileNav from "../_newComponents/MobileNav";
import NoParkingImage from "../images/no-parking-img.svg";
import SearchIcon from "../icons/search.svg";
import StarIcon from "../icons/star_active.svg";
import GarageIcon from "../icons/garage.svg";
import StairIcon from "../icons/stairs.svg";
import "./style/parking.scss";
import { Link } from "react-router-dom";

const ParkingPage = () => {
  const [parkings, setParkings] = useState([]);
  const [options, setOptions] = useState([]);
  const [val, setVal] = useState("");
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/get-parking`)
      .then((res) => {
        const data = res.data.data;

        data.forEach((item) => {
          const isAfter = moment(item.avalability_date).isAfter(
            moment().format("YYYY-MM-DD"),
            "day"
          );

          item.date = isAfter && moment(item.avalability_date).format("MMMM D");
        });

        setParkings(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSelect = () => {};
  const onSearch = () => {};

  const reset = () => {};
  const applyFilters = () => {};
  const change = () => {};

  const separateNumbers = (num) =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;

  console.log(parkings);

  return (
    <div className="parking">
      <PageHeader homeLink="/" isCustom title="Parking" />

      <div className="search-container">
        <div className="search-listing d-flex align-items-center justify-content-between">
          <form
            className="search-listing__container mr-2"
            style={{ flex: 1 }}
            onSubmit={(e) => {
              e.preventDefault();
              applyFilters();
            }}
          >
            <img src={SearchIcon} alt="Search" />

            <AutoComplete
              style={{
                background: "inherit",
                borderRadius: "999px",
                boxShadow: "none",
              }}
              id="search-autocomplete"
              className="form-control search"
              placeholder="Find a place to stay"
              allowClear={true}
              options={options}
              onSelect={onSelect}
              onSearch={(value) => {
                onSearch("data", value);
                setVal(value);
              }}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </form>

          <div>
            <Button
              onClick={applyFilters}
              className="bookN-s"
              style={{ fontWeight: "bold" }}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <div className="parking__filters">
        <FilterButtons
          filters={filters}
          onReset={reset}
          onSearch={applyFilters}
          change={change}
        />
      </div>

      <div className="parking__list">
        {parkings.map((prk, i) => (
          <div className="prk-list" key={i}>
            <div className="row ml-0 mr-0">
              <div className="prk-list__info col-7 pl-0 pr-0">
                <Link to={`/parking/${btoa(prk.prk_id)}/${prk.prk_title}`}>
                  <h2>{prk.prk_title}</h2>
                </Link>

                {/* <p>
                  <img src={StarIcon} alt="Parking Rating - RoomDaddy" />

                  <span style={{ color: "#1c1c1c" }}>
                    <b>4.6</b> <u>All Reviews</u>
                  </span>
                </p> */}

                <h6 className="prk-loc">{prk.prk_street_address}</h6>

                <div className="prk-list__features">
                  <p>
                    <img
                      src={GarageIcon}
                      alt="Parking Garage Icon - RoomDaddy"
                    />
                    <span>Covered</span>
                  </p>

                  <p>
                    <img src={StairIcon} alt="Parking Stair Icon - RoomDaddy" />
                    <span>Ground</span>
                  </p>
                </div>

                <p>Available {prk.date ? `from ${prk.date}` : "Now"}</p>
              </div>

              <div className="prk-list__img col-5 pl-0 pr-0 text-right">
                <img src={NoParkingImage} alt="No Parking Img - RoomDaddy" />
              </div>
            </div>

            <div className="row ml-0 mr-0 pt-3 align-items-center">
              <div className="col-6 pl-0 pr-0">
                <a href={`tel:${prk.contact_number}`}>
                  <Button className="prk-button bookN-s">Contact</Button>
                </a>
              </div>

              <div className="prk-price col-6 pl-0 pr-0 text-right">
                <p className="mb-0">AED {separateNumbers(prk.monthly_price)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <MobileNav />
    </div>
  );
};

export default ParkingPage;
