import React from 'react'
import Footer from '../_newComponents/Footer'
import Maintenance from '../images/Maintenance.png'
import '../../app/_newPages/style/ourPartner.scss'
import { Our_partner_card, Our_partner } from '../data/tilesData'
import PageHeader from '../_newComponents/pageHeader'

export default function OurPartner() {
  return (
    <>
        <PageHeader homeLink="/" />
        <div className='blog_body'>
            <div className='nHeader'>
                <div className='nHeader_bg'>
                    <p className='montserratN900-45 text-white'>Become Our Partner</p>
                </div>
            </div>
            <div className=''>
                <div className='card-body'>
                    <p className='futuraBkBtN-14 text-lightBlack'>
                        We Collaborate and Partner with the best service providers in the city for each and every service that is or can be required by you. Our partners help and support us with making your living and hosting experience effortless and convenient. 
                    </p>
                </div>
                {Our_partner_card.map((item, index)=>{
                    return(
                        <>
                            <div className='custom_card' key={index}>
                                <div className='card-body'>
                                    <div className='custom_card_img'>
                                        <img className='card_img' src={`${item.image}`} alt={'RMD_img'}/>
                                    </div>
                                    <div className='background_colour_primary'>
                                        <p className='futuraN700-20'>{item.name}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
                
                <div class="mb-4 futuraN700-16 text-center">
                    <button class="btn-primary text-lightBlack m-2">Become Our Partner </button>
                </div>

                {/* <div className='background_colour_primary'>
                    <div className='card-body'>
                        <p class="futuraN700-20 text-center pt-4">Our Partners</p>
                        {Our_partner.map((item, index)=>{
                            return(
                                <>
                                    <div className='text-center pb-4 pt-4' key={index}>
                                        <img className='' src={`${item.image}`}/>
                                        <p className='futuraBkBtN-14 text-lightBlack pt-3 pb-3'>{item.content}</p>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div> */}
            </div>
            <Footer/>
        </div>
    </>
  )
}
