import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { msg } from "../../firebase";
import { getUser, setLsValue } from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import VerifyImage from "../images/verifyEmail.svg";
import DotsTop from "../images/dots_login-1.svg";
import DotsBottom from "../images/dots_login-2.svg";
import "./style/verifyEmail.scss";
import { CACHE__AUTH_USER } from "../data/cacheKeys";

const VerifyEmail = () => {
  // POST: get_verify_email DATA: email

  const user = getUser();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState({
    otpOne: "",
    otpTwo: "",
    otpThree: "",
    otpFour: "",
  });

  useEffect(() => {
    if (!user) history.push();
  }, [user]);

  const verifyThisEmail = async (e) => {
    e.preventDefault();

    if (
      !verifyEmail.otpOne ||
      !verifyEmail.otpTwo ||
      !verifyEmail.otpThree ||
      !verifyEmail.otpFour
    ) {
      message.error("One or more fields are empty");
      return;
    }

    setIsLoading(true);

    let data = new FormData();

    data.append("user_id", user.id);
    data.append(
      "code",
      `${verifyEmail.otpOne}${verifyEmail.otpTwo}${verifyEmail.otpThree}${verifyEmail.otpFour}`
    );

    await axios
      .post(`${app.baseUrl}/api/verifyemail`, data)
      .then((res) => {
        console.log("verifyCode API", res);

        if (!res.data.status) message.error(res.data.message);
        else if (res.data.message === "Verification Code Error")
          message.error("Incorrect OTP");
        else message.success("Email verified! Please complete your profile.");

        if (msg)
          msg
            .requestPermission()
            .then(() => {
              return msg.getToken();
            })
            .then(async (token) => {
              console.log("Updated Token : ", token);
              let url = `${app.baseUrl}/api/update_device_id?user_id=${data.id}&device_type=A&device_id=${token}`;

              try {
                let res = await axios.get(url);
                console.log("URL res", res);
              } catch (err) {
                console.log(err);
              }
            })
            .catch((err) => console.log(err));

        let data = res.data.data;
        let userData = {
          id: data.id,
          name: data.fld_name,
          email: data.email,
          role: data.fld_user_type,
          phone:
              data.fld_number && data.country_code
                ? `+${data.country_code}${data.fld_number}`
                : null,
        };
        let appState = {
          isLoggedIn: true,
          user: userData,
        };

        setLsValue(CACHE__AUTH_USER, appState);

        setIsLoggedIn(true);
        setIsLoading(false);

        window.location = "/profile";
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleInputs = (index) => {
    const elems = document.getElementsByClassName("verify__input");

    if (index < elems.length)
      document.getElementById(`otp-${index + 1}`).focus();
    else document.getElementById("otp-submit").focus();

    return;
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <div className="verify">
      <PageHeader homeLink="/" />

      <img src={DotsTop} alt="RoomDaddy" className="verify__dots--top" />

      <div className="verify__body">
        <img src={VerifyImage} alt="RoomDaddy" className="verify__image mt-4" />

        <h1 className="py-3">Verify Email</h1>

        <div className="reset-pass__caption px-2">
          Please enter OTP sent to your email <br />
          <strong>{user?.email}</strong>
        </div>

        <form className="verify__form mt-4" onSubmit={verifyThisEmail}>
          <div className="verify__inputs">
            <input
              id="otp-1"
              value={verifyEmail.otpOne}
              maxLength="1"
              onChange={(e) => {
                handleInputs(1);
                setVerifyEmail({ ...verifyEmail, otpOne: e.target.value });
              }}
              className="verify__input"
            />

            <input
              id="otp-2"
              value={verifyEmail.otpTwo}
              maxLength="1"
              onChange={(e) => {
                handleInputs(2);
                setVerifyEmail({ ...verifyEmail, otpTwo: e.target.value });
              }}
              className="verify__input"
            />

            <input
              id="otp-3"
              value={verifyEmail.otpThree}
              maxLength="1"
              onChange={(e) => {
                handleInputs(3);
                setVerifyEmail({ ...verifyEmail, otpThree: e.target.value });
              }}
              className="verify__input"
            />

            <input
              id="otp-4"
              value={verifyEmail.otpFour}
              maxLength="1"
              onChange={(e) => {
                handleInputs(4);
                setVerifyEmail({ ...verifyEmail, otpFour: e.target.value });
              }}
              className="verify__input"
            />
          </div>

          <Button
            id="otp-submit"
            type="submit"
            variant="contained"
            className="verify__button"
            disabled={isLoading}
          >
            {isLoading ? "Verifying..." : "Verify"}
          </Button>
        </form>
      </div>

      <img src={DotsBottom} alt="RoomDaddy" className="verify__dots--bottom" />
    </div>
  );
};

export default VerifyEmail;
