import React from 'react'
import './tiles.scss'

export default function Tiles(props) {
    let arry = props.items;
    return (
        <>
            
            <div className='row w-100 pl-15 mb-4'>
                {arry.map((item, index) => {
                    return(
                        <>
                            <div className='col-6 p-2'>
                                <div className='tiles_card'>
                                    <div className='tiles'>
                                        <p className='futuraN700-14'>{item.name}</p>
                                        <div className='tiles_item'>
                                            <div>
                                                <img src={item.icon} alt='RMD_icon'/>
                                            </div>
                                            <div>
                                                {item.badgeIcon == true && <span className='futuraBkBtN-9 badge_colour_primary border-radius-2 float-right'>{item.badge}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
            {/* <ul className='hotel-feature-list p-0'>
                {arry.map((item, index) => {
                    return(
                        <>
                            <li className='m-2' key={index}>
                                <div className='tiles_card'>
                                    <div className='tiles'>
                                        <p className='futuraN700-14'>{item.name}</p>
                                        <div className='tiles_item'>
                                            <div>
                                                <img src={item.icon} alt='RMD_icon'/>
                                            </div>
                                            <div>
                                                <span className='futuraBkBtN-9 badge_colour_primary border-radius-2 float-right'>{item.badge}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </>
                    );
                })}
            </ul> */}
        </>
    )
}
