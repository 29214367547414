import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { removeLsValue } from "../../utils/functions";
import { CACHE__PWD_RESET_MAIL } from "../data/cacheKeys";
import PageHeader from "../_newComponents/pageHeader";
import DotsTop from "../images/dots_login-1.svg";
import DotsBottom from "../images/dots_login-2.svg";
import "./style/resetPass.scss";

const ResetPassPage = () => {
  const history = useHistory();
  const { token } = useParams();

  const email = localStorage.getItem("_RD_PwdResetEmail");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const resetPass = (e) => {
    e.preventDefault();

    if (!password.trim() || !passwordConfirmation.trim()) {
      message.error("One or more field is empty");
      return;
    }

    if (password !== passwordConfirmation) {
      message.error("Password doesn't match the confirmation password");
      return;
    }

    const data = new FormData();

    data.append("email", email);
    data.append("token", token);
    data.append("password", password);
    data.append("password_confirmation", passwordConfirmation);

    axios
      .post(`${app.baseUrl}/api/password/reset`, data)
      .then((res) => {
        message.success("Password is updated.");
        removeLsValue(CACHE__PWD_RESET_MAIL);

        history.push("/login");
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });
  };

  return (
    <div className="reset-pass">
      <PageHeader isHome homeLink="/" />

      <img src={DotsTop} alt="RoomDaddy" className="reset-pass__dots--top" />

      <div className="reset-pass__body">
        <h1>Reset password</h1>

        <div className="reset-pass__caption">
          Enter new password to confirm for <strong>{email}</strong> or you can{" "}
          <Link to="/login">Sign In</Link> with your old password
        </div>

        <form className="reset-pass__form" onSubmit={resetPass}>
          <div className="reset-pass__labels">
            <label htmlFor="new_pass">New password</label>
          </div>

          <input
            id="new_pass"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="reset-pass__input"
          />

          <div className="reset-pass__labels">
            <label htmlFor="re_pass">Retype password</label>
          </div>

          <input
            id="re_pass"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            className="reset-pass__input"
          />

          <Button
            type="submit"
            variant="contained"
            className="reset-pass__button"
          >
            Reset Password
          </Button>
        </form>
      </div>

      <img
        src={DotsBottom}
        alt="RoomDaddy"
        className="reset-pass__dots--bottom"
      />
    </div>
  );
};

export default ResetPassPage;
