import React, { useState, useEffect, useContext } from "react";
// import AntAutoComple from '../../components/AntAutoComp'
import axios from "axios";
import { Select, message } from "antd";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import SearchListing from "../../_newComponents/SearchListing";
import { Button } from "@material-ui/core";

const { Option } = Select;
let timer;
export default function StepOne({ nextStep, to }) {
  const uaeCitiesLatLng = [
    {
      id: 48973,
      name: "Abu Dhabi",
      latlong: {
        lat: 24.466667,
        lng: 54.366669,
      },
    },
    {
      id: 48974,
      name: "Ajman",
      latlong: {
        lat: 25.4052,
        lng: 55.5136,
      },
    },
    {
      id: 48974,
      name: "Al Khaimah",
      latlong: {
        lat: 25.6741,
        lng: 55.9804,
      },
    },
    {
      id: 48981,
      name: "ash-Shariqah",
      latlong: {
        lat: 25.3462,
        lng: 55.4211,
      },
    },
    {
      id: 48975,
      name: "Dubai",
      latlong: {
        lat: 25.2048,
        lng: 55.2708,
      },
    },
    {
      id: 48980,
      name: "Fujeirah ",
      latlong: {
        lat: 25.1288,
        lng: 56.3265,
      },
    },
    {
      id: 48976,
      name: "Ras Al-Khaimah",
      latlong: {
        lat: 25.800694,
        lng: 55.9762,
      },
    },
    {
      id: 48977,
      name: "Sharjah",
      latlong: {
        lat: 25.348766,
        lng: 55.405403,
      },
    },
    {
      id: 48979,
      name: "Umm al Qaywayn",
      latlong: {
        lat: 25.5325,
        lng: 55.5492,
      },
    },
  ];
  const { userInfo, setUserInfo, editRoomDetail } = useContext(AppContext);
  const [stepOneInfo, setStepOneInfo] = useState({
    property_type: "",
    city: "",
    city_id: "",
    country_id: 229,
    build_id: "",
    location: "",
    search_park_street: "",
    search_longitude: "",
    search_latitude: "",
  });
  const [citiesList, setCitiesList] = useState([]);
  const [buildingsList, setBuildingsList] = useState({});
  const [locationsList, setLocationsList] = useState([]);
  const [locationFilter, setLocationFilter] = useState("");
  const [buildingFilter, setBuildingFilter] = useState("");
  useEffect(() => {
    fetchCities();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchBuildings();
  }, [buildingFilter]);
  useEffect(() => {
    fetchLocations();
  }, [locationFilter]);
  useEffect(() => {
    console.log("target", stepOneInfo);
  }, [stepOneInfo]);
  function fetchCities() {
    axios
      .get(`${app.baseUrl}/api/cities_list/${229}`)
      .then((res) => {
        console.log("cities API", res);
        setCitiesList(res.data.data);
      })
      .catch((err) => console.log(err));
  }
  function fetchBuildings() {
    if (buildingFilter !== "") {
      axios
        .get(`${app.baseUrl}/api/building_filter/${buildingFilter}`)
        .then((res) => {
          console.log(res);
          setBuildingsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }
  function fetchLocations() {
    if (locationFilter !== "") {
      axios
        .get(`${app.baseUrl}/api/location_filter/${locationFilter}`)
        .then((res) => {
          console.log(res);
          setLocationsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }
  function onSearch(key, value) {
    console.log(`${key} ${value}`);
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (key === "building") {
        setBuildingFilter(value);
      } else if (key === "location") {
        setLocationFilter(value);
      }
    }, 400);
  }
  function updateFirstForm(property, value) {
    if (property == "city_id") {
      uaeCitiesLatLng.map((item, index) => {
        if (item.id == value) {
          setUserInfo({
            ...userInfo,
            lat: item.latlong.lat,
            lng: item.latlong.lng,
          });
        }
      });
    }
    console.log(`selected form ${value}`);
    setStepOneInfo({ ...stepOneInfo, [property]: value });
  }
  function sentFormData() {
    let split_City = stepOneInfo.city_id.split("_");
    let parkingAddData = {};
    parkingAddData.user_id = userInfo?.id;
    parkingAddData.country_id = 229;
    parkingAddData.city = split_City[1];
    parkingAddData.city_id = split_City[0];
    parkingAddData.build_id = stepOneInfo.build_id;
    parkingAddData.location = stepOneInfo.location;
    parkingAddData.search_park_street = "Dubai - United Arab Emirates";
    parkingAddData.search_longitude = 25.2048493;
    parkingAddData.search_latitude = 55.2707828;
    if (
      parkingAddData.country_id &&
      parkingAddData.city &&
      parkingAddData.city_id &&
      parkingAddData.build_id &&
      parkingAddData.location !== ""
    ) {
      message.success("Step one complete!");
      localStorage.setItem("_RMDParkingAd", JSON.stringify(parkingAddData));
      nextStep(1);
    } else {
      message.error("Please fill the values");
    }
  }

  return (
    <div className="px-2 py-3">
      <div className="container pb-4 px-3">
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose your Country
          </label>
          <div className="form-control inputBorder">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"United Arab Emirates"}
              value={"United Arab Emirates"}
            >
              <Option key="" value="229">
                United Arab Emirates
              </Option>
            </Select>
          </div>
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose your City
          </label>
          <div className="form-control inputBorder">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Select city"}
              value={stepOneInfo.city_id}
              onChange={(value) => updateFirstForm("city_id", value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {citiesList.length !== 0 &&
                citiesList.map((city) => (
                  <Option key={city.id} value={`${city.id}_${city.name}`}>
                    {city.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Select Building
          </label>
          <div className="form-control inputBorder" style={{ marginBottom: 8 }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Select building"}
              onChange={(value) => updateFirstForm("build_id", value)}
              onSearch={(val) => onSearch("building", val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={null}
            >
              {Object.keys(buildingsList).length !== 0 &&
                Object.keys(buildingsList).map((building) => (
                  <Option value={building}>{buildingsList[building]}</Option>
                ))}
            </Select>
          </div>
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Select Location
          </label>
          <div className="form-control inputBorder">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Select location"}
              onChange={(value) => updateFirstForm("location", value)}
              onSearch={(val) => onSearch("location", val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={null}
            >
              {Object.keys(locationsList).length !== 0 &&
                Object.keys(locationsList).map((building) => (
                  <Option value={building}>{locationsList[building]}</Option>
                ))}
            </Select>
          </div>
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Pin your location*
          </label>

          <SearchListing noPadding />
        </div>
      </div>
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Continue
      </Button>
    </div>
  );
}
