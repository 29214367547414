// Old Icons
// import HomeIcon from "../../img/home.svg";
// import InboxIcon from "../../img/message.png";
// import PostIcon from "../../img/add.png";
// import NotificationIcon from "../../img/notification.svg";
// import ListIcon from "../../img/booking.svg";

// New Icons
import HomeIcon from "../../icons/home.svg";
// import HomeFillIcon from "../../icons/homeFill.svg";
import InboxIcon from "../../icons/inbox.svg";
// import InboxFillIcon from "../../icons/inbox_fill.svg";
import PostIcon from "../../icons/postAdd.svg";
// import PostFillIcon from "../../icons/post_fill.svg";
import NotificationIcon from "../../icons/notificationIcon.svg";
import ListIcon from "../../icons/list.svg";
// import ListFillIcon from "../../icons/list_fill.svg";

// Ion Icons
// import HomeIcon from "../../icons/ion-home.svg";
// import InboxIcon from "../../icons/ion-chat.svg";
// import PostIcon from "../../icons/ion-addcircle.svg";
// import NotificationIcon from "../../icons/ion-notification.svg";
// import ListIcon from "../../icons/ion-list.svg";

export const MobileNavData = [
  {
    path: "/",
    img: HomeIcon,
    title: "Home",
  },
  {
    path: "/message",
    img: InboxIcon,
    title: "Chat",
  },
  {
    path: "/place-an-ad",
    img: PostIcon,
    title: "host",
  },
  {
    path: "/notification",
    img: NotificationIcon,
    title: "notification",
  },
  {
    path: "/my_booking",
    img: ListIcon,
    title: "bookings",
  },
];
