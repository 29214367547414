const data = [
    {
        title: "How can I list a Property?",
        content:   [
            {
                title: `To post an online Ad, you need to verify your email address and your phone number. This makes our community safe and avoids spam listings. Once your profile is verified with contact details, your account gets the access to post a listing. If you are still unable to make a listing you can write to us directly so that our help desk can assist you sort out the issues.`,
            },
        ]
    },
    {
        title: "Can I list any type of property?",
        content:   [
            {
                title: `We currently welcome residential properties only. Whether be it a Shared Accommodation, an Entire Home, Holiday Home,,Hotel, Hotel Apartment,, or a Parking spot, you can list on our portal. And not just that we provide you post booking solution, where we help you manage your property with your tenant with ease.`,
            },
        ]
    },
    {
        title: "How much does it cost you list a property?",
        content:   [
            {
                title: `We are free to list platform. We don’t charge you for having your property listed with us. However we will soon have the premium features, so keep your fingers crossed on what is next to come.`,
            },
        ]
    },
    {
        title: "What is your listing criteria?",
        content:   [
            {
                title: `1- Property should be fully furnished, ready to move in.
                2- RoomDaddy has a Minimum contractual obligation of one month to rent your property.
                3- All utility services are available and are provided for by the owner. However the usage can be either upon the tenant or shall be inclusive in the rental price.`,
            },
        ]
    },
    {
        title: "For how long can I list my property?",
        content:   [
            {
                title: `We do not have an expiry of the property listing so as long as you do not take it off from our portal it will stay live and visible. To remove, you can either make use of Booking, Un-publish, or delete options to delist. Once it is deleted the property will not be returned back.`,
            },
        ]
    },
    {
        title: "How Can I Edit my Listing?",
        content:   [
            {
                title: `On the profile Button click on “My Listing” Tab in your account, you can edit any of your listing and keep it updated with the changes.`,
            },
        ]
    },
    {
        title: "How to make use of Booking Button?",
        content:   [
            {
                title: `In “My Listing” Tab you can make use of post booking functions. Your payments, communication and even help desk and support can work between your tenant while making use of Booking Button. You can receive monthly payments online, they would receive invoice for next month payment. There is so much more to enjoy your property with this feature. We highly recommend you to book your tenant on our platform whether or not its rented for our lead or from another source.`,
            },
        ]
    },
    {
        title: "I have a large portfolio of properties, Booking on your platform is still the best option for me?",
        content:   [
            {
                title: `It is possible to make use of our booking with any number of clients but it is still not recommended. So if you have many clients to look after we suggest you to get in touch with us. We will provide you our Property Management system that does everything for you to help you scale. For more information you can look at our cloud based PMS`,
            },
        ]
    },
    {
        title: "What is Re-list option for?",
        content:   [
            {
                title: `This is for your convenience. With us you “List only Once”. You do not have to waste time in Re-listing the same ad over and over again.
                After all we understand what you need and what makes you go nuts looking for the picture folder and writing the same description again. All you need to do while relist is to review changes and feed new availability date and you get started again.`,
            },
        ]
    },
    {
        title: "Is it mandatory to View the room before booking?",
        content:   [
            {
                title: `It is not mandatory, however it is highly advisable to remove any ambiguity between the tenant and owner before booking. Before making a booking confirmation make sure your move in dates and other information is accurate. We will send notification to the Tenant about the exact terms and conditions at the time of booking. Any dispute arising will be settled between both parties themselves, Roomdaddy will only lock these details as a reference between the two parties and would never interfere between your dealings directly.`,
            },
        ]
    },
    
]
export default data;