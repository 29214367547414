import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, Redirect } from "react-router-dom";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { msg } from "../../firebase";
import PageHeader from "../_newComponents/pageHeader";
import MobileNav from "../_newComponents/MobileNav";
import FbIcon from "../icons/fb.svg";
import GoogleIcon from "../icons/google.svg";
import LoginImage from "../images/signin.svg";
import DotsTop from "../images/dots_login-1.svg";
import DotsBottom from "../images/dots_login-2.svg";
import "./style/login.scss";
import { CACHE__AUTH_USER } from "../data/cacheKeys";
import { setLsValue } from "../../utils/functions";
// import OneSignal from "react-onesignal";

const LoginPage = () => {
  const [isStatusFalse, setIsStatusFalse] = useState(false);
  const [sacebookAutoLoad, setFacebookAutoLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    if (!email.trim() && !password.trim()) return;

    setIsLoading(true);

    let api = `${app.baseUrl}/api/login/${email}/${password}`;

    await axios
      .post(api, { email, password })
      .then((res) => {
        let isEmailVerified = res.data.message;
        let status = res.data.status;

        if (status === false) {
          message.error(isEmailVerified);
          setIsStatusFalse(true);
        }

        if (status === true) {
          let data = res.data.data;

          if (msg)
            msg
              .requestPermission()
              .then(() => {
                return msg.getToken();
              })
              .then(async (token) => {
                console.log("Updated Token : ", token);
                let url = `${app.baseUrl}/api/update_device_id?user_id=${data.id}&device_type=A&device_id=${token}`;

                try {
                  let res = await axios.get(url);
                  console.log("URL res", res);
                } catch (err) {
                  console.log(err);
                }
              })
              .catch((err) => console.log(err));

          let userData = {
            id: data.id,
            name: data.fld_name,
            email: data.email,
            phone:
              data.fld_number && data.country_code
                ? `+${data.country_code}${data.fld_number}`
                : null,
            role: data.fld_user_type,
            profile: data.fld_profile_pic,
            device_id: data.device_id,
            verification_code: data.verification_code,
            remember_token: data.remember_token,
            sex: data.fld_sex,
          };

          let appState = {
            isLoggedIn: true,
            user: userData,
          };

          setLsValue(CACHE__AUTH_USER, appState);
          setIsLoggedIn(true);

          NotificationManager.success(
            "You have added a new book!",
            "Successful!",
            2000
          );

          window.location.reload();
        } else {
          NotificationManager.error("Error while Creating new book!", "Error!");
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const facebookCallBack = (res) => {
    let userID = res.userID;

    if (userID) {
      let data = new FormData();

      data.append("name", res.name);
      data.append("password", res.id);
      data.append("email", res.email);
      data.append("profile", res.picture.data.url);
      data.append("login_type", "facebook");

      axios
        .post(`${app.baseUrl}/api/social_login`, data)
        .then((resp) => {
          let socialLoginStatus = resp.data.status;

          if (socialLoginStatus === true) {
            let data = resp.data;
            let userData = {
              id: data.data.id,
              name: data.data.fld_name,
              email: data.data.email,
              profile: data.data.fld_profile_pic,
              login_type: data.data.login_type,
            };

            let appState = {
              isLoggedIn: true,
              user: userData,
            };

            setLsValue(CACHE__AUTH_USER, appState);

            setIsLoggedIn(true);
            setFacebookAutoLoad(false);

            window.location.reload();
          } else {
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const responseGoogle = (res) => {
    let userID = res.googleId;

    if (userID) {
      let data = new FormData();

      data.append("name", res.profileObj.name);
      data.append("password", res.googleId);
      data.append("email", res.profileObj.email);
      data.append("profile", res.profileObj.imageUrl);
      data.append("login_type", res.tokenObj.idpId);

      axios
        .post(`${app.baseUrl}/api/social_login`, data)
        .then((resp) => {
          let socialLoginStatus = resp.data.status;

          if (socialLoginStatus === true) {
            let data = resp.data;
            let userData = {
              id: data.data.id,
              name: data.data.fld_name,
              email: data.data.email,
              profile: data.data.fld_profile_pic,
              login_type: data.data.login_type,
            };

            let appState = {
              isLoggedIn: true,
              user: userData,
            };

            setLsValue(CACHE__AUTH_USER, appState);
            setIsLoggedIn(true);

            window.location.reload();
          } else {
            let isEmail = resp.data.message;

            message.error(isEmail);
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const fbClick = () => {
    const elem = document.getElementsByClassName("facebook-button")[0];
    elem.click();
  };

  const googleClick = () => {
    const elem = document.getElementsByClassName(
      "google-button-googleLogin"
    )[0];
    elem.click();
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <div className="login">
      <PageHeader homeLink="/" />

      <img src={DotsTop} alt="RoomDaddy" className="login__dots--top" />

      <div className="login__body">
        <img src={LoginImage} alt="RoomDaddy" className="login__image" />

        <h1>Sign In</h1>

        <form className="login__form" onSubmit={login}>
          <div className="login__labels">
            <label htmlFor="email">Email</label>
            <Link to="/send_email">Verify Email</Link>
          </div>

          <input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login__input"
            type="email"
          />

          <div className="login__labels">
            <label htmlFor="password">Password</label>
            <Link to="/password/reset">Forgot password?</Link>
          </div>

          <input
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login__input"
            type="password"
          />

          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            className="login__button"
          >
            {isLoading ? "Signing In..." : "Sign In"}
          </Button>
        </form>

        <div className="login__socials">
          <div className="login__caption">or sign in with</div>

          <div className="login__social-container">
            {/* <Button
              variant="outlined"
              className="login__social-btn"
              onClick={fbClick}
            >
              <img src={FbIcon} alt="RoomDaddy Facebook" />
              Facebook
            </Button> */}

            <Button
              variant="outlined"
              className="login__social-btn"
              disabled={isLoading}
              onClick={googleClick}
            >
              <img src={GoogleIcon} alt="RoomDaddy Google" />
              Google
            </Button>

            <div className="login__hidden">
              {/* <FacebookLogin
                className="facebook-button-fbLogin"
                appId="4238188569560646"
                autoLoad={sacebookAutoLoad}
                fields="name,email,picture"
                onClick={() => setFacebookAutoLoad(true)}
                callback={(res) => facebookCallBack(res)}
                icon={"fa-facebook"}
                cssClass="facebook-button"
              /> */}

              <GoogleLogin
                className="google-button-googleLogin"
                clientId="284848848844-1cqi3688jqoo0g21a31f7rmt88o7i18e.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>
        </div>

        <div className="login__signup-caption">
          <span>Not registered yet?</span>
          <Link to="/signup">Create an Account</Link>
        </div>
      </div>

      <img src={DotsBottom} alt="RoomDaddy" className="login__dots--bottom" />

      <MobileNav />
    </div>
  );
};

export default LoginPage;
