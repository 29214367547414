import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { decode } from "html-entities";
import { Avatar } from "@material-ui/core";
import "./index.scss";

const ChatUser = ({
  all_receiver: {
    bedspace_id,
    created_at,
    fld_name,
    id,
    chatroom_id,
    image,
    is_from_reciever,
    is_from_sender,
    is_read,
    lastMsg,
    message,
    receiver,
    receiver_id,
    receiver_img,
    sender_id,
    total_unread,
    updated_at,
  },
}) => (
  <Link
    to={`/message/${bedspace_id}-${receiver_id}-${sender_id}-${chatroom_id}`}
  >
    <div className="chat-user">
      <Avatar
        className={`chat-user__avatar ${
          total_unread > 0 && "chat-user__avatar--unread"
        }`}
        src={image}
        alt={fld_name}
      />

      <div className="chat-user__info">
        <h4>{receiver?.fld_name}</h4>
        <p className="mb-0">{fld_name}</p>
        <div>{decode(lastMsg?.message)}</div>
      </div>

      <div className="chat-user__things">
        {total_unread > 0 ? (
          <div className="chat-user__unread-count">
            <p className="mb-0">{total_unread}</p>
          </div>
        ) : (
          <p>{moment(lastMsg?.created_at).fromNow(true)}</p>
        )}
      </div>
    </div>
  </Link>
);

export default ChatUser;
