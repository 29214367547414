import React from "react";
import { Button } from "@material-ui/core";

const PaymentHistoryCard = ({ data, type }) => {
  return (
    <div className="rd-custom-card py-4">
      <div className="c-head">
        <h3>
          {type === "rent_pay" && "Rent Payment"}
          {type === "rent_invoice" && "Rent Invoice"}
          {type === "deposit_pay" && "Deposit Payment"}
          {type === "deposit_invoice" && "Deposit Invoice"}{" "}
          <span>
            - {data.fld_paid_status === 0 && "Not Paid"}
            {data.fld_paid_status === 1 && "Partially Paid"}
            {data.fld_paid_status === 2 && "Full Paid"}
          </span>
        </h3>
      </div>

      <div className="c-body">
        <div className="c-body__info">
          <p>
            <b>Invoice ID:</b>

            <span>
              {(type === "rent_invoice" || type === "deposit_invoice") &&
                data.fld_id}

              {(type === "rent_pay" || type === "deposit_pay") &&
                (data.fld_invoice_id || data.invoice_id)}
            </span>
          </p>

          {(type === "rent_pay" || type === "deposit_pay") && (
            <p>
              <b>Transaction ID:</b>
              <span>{data.transaction_id}</span>
            </p>
          )}
        </div>

        <div className="c-body__info">
          <p>
            <b>Invoice Date:</b>
            <span>{data.fld_Invoice_date}</span>
          </p>

          <p>
            <b>Due Date:</b>
            <span>{data.fld_invoice_due_date}</span>
          </p>
        </div>

        {(type === "rent_pay" || type === "deposit_pay") && (
          <div className="c-body__info">
            <p>
              <b>Payment Date:</b>
              <span>{data.fld_payment_date}</span>
            </p>

            <p>
              <b>Payment Type:</b>

              <span>
                {data.fld_payment_type === 1 && "Bank Transfer"}
                {data.fld_payment_type === 2 && "Cash Payment"}
                {data.fld_payment_type === 3 && "Online Payment"}
              </span>
            </p>
          </div>
        )}
      </div>

      <div
        className={`c-info ${
          (type === "rent_pay" || type === "deposit_pay") && "mb-n3"
        }`}
      >
        {(type === "rent_invoice" || type === "deposit_invoice") && (
          <p>
            <b>Credit</b>
            <span>AED {data.fld_invoice_amount}</span>
          </p>
        )}

        {(type === "rent_pay" || type === "deposit_pay") && (
          <p>
            <b>Debit</b>
            <span>AED {data.fld_paid_amount}</span>
          </p>
        )}
      </div>

      {(type === "rent_invoice" || type === "deposit_invoice") && (
        <div className="c-cta">
          <Button className="bookN w-100">Pay Now</Button>
        </div>
      )}
    </div>
  );
};

export default PaymentHistoryCard;
