import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import axios from "axios";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import { getUser } from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import Calendar from "../icons/calendar.svg";
import BackIcon from "../icons/back.svg";
import CurrencyIcon from "../icons/currency.svg";
import RepairIcon from "../icons/repair-tool.svg";
import "./style/hotelBookingPage.scss";
import moment from "moment";

const HotelBookingPage = () => {
  const user = getUser();

  const history = useHistory();
  const { booking_id } = useParams();
  const { userInfo } = useContext(AppContext);

  const [booking, setBooking] = useState({});
  const [hotel, setHotel] = useState({});
  const [room, setRoom] = useState({});

  if (!user) history.push("/login");

  useEffect(() => {
    axios
      .post(`${app.hotelBaseUrl}/api/hotel-booking/${user.id}/${booking_id}`)
      .then((res) => {
        if (res.data.user_id != user.id) history.push("/my_booking");

        axios
          .get(
            `${app.hotelBaseUrl}/api/get_client_hotel_data/${res.data.hotel_id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
          )
          .then((response) => {
            setHotel(response.data[0]);

            for (let room of response.data[0].hotel_active_rooms)
              if (res.data.room_type === room.room_name) setRoom(room);
          })
          .catch((err) => console.log(err));

        setBooking(res.data);
      })
      .catch((err) => console.log(err));
  }, [booking_id]);

  const getDate = (d) => {
    const date = moment(d, "YYYY-MM-DD").format("ddd, D MMM YYYY");
    return date;
  };

  const getCancellationDate = (checkIn, advance) => {
    const date = moment(checkIn, "YYYY-MM-DD").subtract("days", advance);
    const hasPassed = moment().isAfter(date);

    return { date: date.format("D MMM, YYYY"), hasPassed };
  };

  return (
    <div className="hotel-booking">
      <PageHeader homeLink="/" isCustom title="Overview" />

      <div className="hotel-booking__body">
        <div className="hotel-booking__top">
          <div className="top--back-arrow">
            <img
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push("/hotel-rooms");
                else history.goBack();
              }}
              src={BackIcon}
              alt="Go Back"
            />
          </div>

          <div className="top--title">{hotel?.hotel_name}</div>
          <div className="top--void">{hotel?.address_line_two}</div>
        </div>

        <div className="card mb-3">
          <div style={{ height: "225px", overflow: "hidden" }}>
            <img
              className="card-img"
              src={room?.room_images?.[0]?.image}
              alt={room?.room_name}
            />
          </div>

          <div className="card-body info-card">
            <div className="top-flex">
              <div>
                <h4 style={{ marginBottom: "12px" }}>{booking?.room_type}</h4>

                <p>
                  <span>Check - in</span>
                  <span className="date">{getDate(booking?.check_in)}</span>
                </p>
              </div>

              <div>
                <p style={{ marginBottom: "9px" }} className="booking-room">
                  <span>
                    room size {room?.room_size} ft<sup>2</sup>
                  </span>
                </p>

                <p>
                  <span>Check - Out</span>
                  <span className="date">{getDate(booking?.check_out)}</span>
                </p>
              </div>
            </div>

            <div className="card-btns">
              <Button className="bookN-s custom-button">Add meal</Button>
              <Button className="bookN-s custom-button">Extend stay</Button>
            </div>

            <div className="card--footer">
              <p>AED {booking?.total_price}</p>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body summary-card">
            <h4>Summary</h4>

            <div className="summaries">
              <p className="summary">
                <span>All balance</span>
                <span className="summary-value">AED 10000</span>
              </p>

              <p className="summary">
                <span>Agreed deposit</span>
                <span className="summary-value">AED 100</span>
              </p>

              {/* <p className="summary">
                <span>Minimum stay</span>
                <span className="summary-value">1 month</span>
              </p>

              <p className="summary">
                <span>Agreed rent</span>
                <span className="summary-value">AED 4000</span>
              </p> */}

              {/* <p className="summary">
                <span>Last payment</span>
                <span className="summary-date">3 Jun, 2021</span>
                <span className="summary-value">AED 100</span>
              </p> */}
            </div>

            {/* <Button className="filter-Reset custom-btn">Payment history</Button> */}
          </div>
        </div>

        <div className="card">
          <div className="card-body cancellation-card">
            <h4>Cancellation</h4>

            <p>
              FREE cancellation before 11:59 PM on{" "}
              {
                getCancellationDate(
                  booking?.check_in,
                  hotel?.policies?.advance_cancel || 0
                ).date
              }
            </p>

            <Button
              variant="contained"
              className="book-danger custom-btn"
              disabled={
                getCancellationDate(
                  booking?.check_in,
                  hotel?.policies?.advance_cancel || 0
                ).hasPassed
              }
            >
              Cancel Booking
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBookingPage;
