import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { message, Select } from "antd";
import { Button, Tooltip } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import app from "../utils/appConfig";
import { removeLsValue, setLsValue } from "../../utils/functions";
import { CACHE__HOMES_CART } from "../data/cacheKeys";
import { AppContext } from "../AppContext";
import RdLoader from "../_newComponents/RdLoader";
import Modal from "../_newComponents/Modal";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import BannerImage from "../images/mobileBannerImage.png";
import Back from "../icons/back.svg";
import DoorIcon from "../icons/door.svg";
import BathIcon from "../icons/bathAm.svg";
import "./style/hotelApartmentDetailRoom.scss";

const { Option } = Select;

const EntireHomeDetailRooms = () => {
  const history = useHistory();
  const { id } = useParams();
  const {
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [home, setHome] = useState({});
  const [rooms, setRooms] = useState([]);
  const [guests, setGuests] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [phonePopUp, setPhonePopUp] = useState(false);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [state, setState] = useState({
    numberOfRooms: numOfRooms,
    breakfast: false,
    numberOfBreakfast: 0,
    internet: false,
    parking: false,
    pets: false,
  });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  function updateState(name, value) {
    setState({ ...state, [name]: value });
  }

  function getRoomPrice(index) {
    if (rooms) {
      let tax = 0;

      if (
        rooms[index].apartment_rent_type &&
        rooms[index].apartment_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(home.hotel_tax) / 100) * rooms[index].apartment_base_price;
        tax = tax + rooms[index].apartment_base_price + home.hotel_tax_amount;
      } else {
        tax = rooms[index].apartment_base_price;
      }

      return Math.ceil(tax);
    }
  }

  function updatePrice() {
    if (rooms.hotel_active_rooms) {
      let total = getRoomPrice(activeModalIndex);
      total = total * state.numberOfRooms;

      if (state.breakfast) {
        let bfastPrice = Number(rooms.services.optional_breakfast_price);
        total = total + bfastPrice * state.numberOfBreakfast;
      }

      setTotalPrice(total);
      return total;
    }
  }

  function getOptions(e) {
    let elem = [];

    for (let i = 1; i <= parseInt(e); i++)
      elem.push(
        <Option value={i} key={i}>
          {i} {i === 1 ? "room" : "rooms"}
        </Option>
      );

    return elem;
  }

  function clearRoom(id) {
    let temp = selectedRooms;
    delete temp[id];

    setSelectedRooms(temp);
    setState({
      numberOfRooms: 1,
      breakfast: false,
      numberOfBreakfast: 0,
      internet: false,
      parking: false,
      pets: false,
    });

    // if (Object.keys(temp).length) {
    //   const cart = {
    //     selectedRooms: temp,
    //     userInfo,
    //     state,
    //   };

    //   setLsValue(CACHE__HOMES_CART, cart);
    // } else {
    //   removeLsValue(CACHE__HOMES_CART);
    // }
  }

  function reserveRoom(index) {
    let sA = rooms[index];

    setActiveModalIndex(index);
    setSelectedRooms({
      ...selectedRooms,
      [sA.id]: {
        ...state,
        hotelName: home.hotel_name,
        hotelId: sA.hotel_id,
        roomId: sA.id,
        roomName: sA.apartment_room_name,
        roomType: sA.apartment_type,
        roomSize: sA.apartment_size,
        checkIn: userInfo.checkIn,
        checkOut: userInfo.checkOut,
        currency: home.hotel_currency,
        // totalAmmount: updatePrice(),
        totalAmmount: getRoomPrice(index) * state.numberOfRooms,
      },
    });

    getTotalPrice();
  }

  function getTotalPrice() {
    let price = 0;

    Object.keys(selectedRooms).map((elem) => {
      price += selectedRooms[elem].totalAmmount;
    });

    return price;
  }

  useEffect(() => {
    axios
      .get(
        `${app.homeBaseUrl}/api/get_client_apart_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        setHome(res.data[0]);
        setRooms(res.data[0].apartments);

        let n = 0;

        rooms.forEach((room) => {
          room.apartment_rooms.forEach((apt) => {
            n += apt.no_of_guests;
          });
        });

        setGuests(n);
        setLoading(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push(`/short-term-rental/detail/${id}`);
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Available rooms</div>
          </div>
        </div>
      </div>

      {loading ? (
        <RdLoader />
      ) : (
        rooms.map((room, i) => (
          <div key={i} className="layoutBody" style={{ paddingTop: "65px" }}>
            <div className="container-fluid">
              <div className="pt-2 pb-2">
                <div className="card" style={{ overflow: "hidden" }}>
                  <div className="card-body">
                    <Link to={`/short-term-rental/detail/${id}/rooms/${room.id}`}>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p className="futuraN700-17 m-0 mb-2">
                              {room.apartment_custom_name &&
                              room.apartment_custom_name !== "null"
                                ? room.apartment_custom_name
                                : room.apartment_room_name}
                            </p>

                            <p className="mb-2">
                              Size: {room.apartment_size} ft<sup>2</sup>
                            </p>

                            <ul className="futuraBkBtN-14 p-0">
                              <li className="d-flex align-items-center">
                                <img src={BathIcon} alt="" className="mr-2" />
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {room.number_bathrooms_apartments} Bathroom(s)
                                </span>
                              </li>

                              <li className="d-flex align-items-center">
                                <img src={DoorIcon} alt="" className="mr-2" />
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {room.apartment_rooms.length} room(s)
                                  remaining
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="side-image">
                            <img
                              src={room.room_images[0].image}
                              alt="BannerImage"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="card-footer">
                    <div className="row d-flex align-items-end justify-content-between">
                      <div className="col-auto pr-1">
                        <div>
                          {selectedRooms[room.id] ? (
                            <>
                              <Select
                                defaultValue={
                                  selectedRooms[room.id].numberOfRooms
                                }
                                onChange={(value) => {
                                  updateState("numberOfRooms", value);
                                  setSelectedRooms({
                                    ...selectedRooms,
                                    [room.id]: {
                                      ...selectedRooms[room.id],
                                      numberOfRooms: value,
                                    },
                                  });
                                }}
                                disabled
                              >
                                {getOptions(room.apartment_rooms.length)}
                              </Select>

                              <button
                                className="close-btn ml-2"
                                onClick={() => clearRoom(room.id)}
                              >
                                <CloseRounded />
                              </button>
                            </>
                          ) : getRoomPrice(i) ? (
                            <>
                              <Select
                                className="selector-ant"
                                style={{ width: 100 }}
                                placeholder="Select room"
                                optionFilterProp="children"
                                defaultValue={
                                  selectedRooms[room.id]?.numberOfRooms ||
                                  state.numberOfRooms
                                }
                                onChange={(value) =>
                                  updateState("numberOfRooms", value)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {getOptions(room.apartment_rooms.length)}
                              </Select>

                              <Button
                                className="bookN-s futuraN700-14"
                                onClick={() => {
                                  reserveRoom(i);

                                  setNumOfRooms(1);
                                  setState({
                                    numberOfRooms: 1,
                                    breakfast: false,
                                    numberOfBreakfast: 0,
                                    internet: false,
                                    parking: false,
                                    pets: false,
                                  });
                                }}
                              >
                                Select
                              </Button>
                            </>
                          ) : (
                            <p onClick={() => setPhonePopUp(true)}>
                              <b>Inquire Now</b>
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-auto">
                        <div class="text-end text-right">
                          <p class="futuraBkBtN-14 m-0">
                            Price for {userInfo.diffInDays} night
                          </p>

                          <p class="montserratNB-16 m-0">
                            AED {getRoomPrice(i)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ResponsiveAd />

            <div
              className="bottom-navigation d-flex align-items-center justify-content-between"
              style={{
                background: "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(10px) saturate(180%)",
              }}
            >
              <div className="futuraBkBtN-14 w-50">
                {getTotalPrice() ? (
                  <>
                    <span className="text-muted">Total price</span>

                    <h5 style={{ fontFamily: "futuraMdBt" }}>
                      AED {getTotalPrice()}
                    </h5>
                  </>
                ) : (
                  <span className="text-muted">Select a room first</span>
                )}
              </div>

              <div className="futuraN700-14">
                <Tooltip
                  onClose={handleTooltipClose}
                  open={open}
                  title={
                    selectedRooms &&
                    Object.keys(selectedRooms).length === 0 &&
                    selectedRooms.constructor === Object
                      ? "You need to select a room first"
                      : "Go to checkout"
                  }
                >
                  <Button
                    size="large"
                    type="primary"
                    className="bookN HomeReserve"
                    onClick={() => {
                      handleTooltipOpen();
                      history.push(
                        selectedRooms &&
                          Object.keys(selectedRooms).length === 0 &&
                          selectedRooms.constructor === Object
                          ? "#"
                          : "/checkout/home"
                      );
                    }}
                  >
                    Reserve
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        ))
      )}

      <Modal visible={phonePopUp}>
        <div className="p-4 text-center">
          <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
            Would you like to call the host?
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="bookN-s mr-3"
              onClick={() => setPhonePopUp(false)}
            >
              <a href={`tel:${home?.alternate_phone_number}`}>Yes</a>
            </Button>

            <Button className="bookN-s" onClick={() => setPhonePopUp(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EntireHomeDetailRooms;
