import Internet from "../icons/internet.svg";
import Kitchen from "../icons/kitchen.svg";
import Smoking from "../icons/smoking.svg";
import MixGender from "../icons/mixG.svg";
import ParkingIcon from "../icons/parkingAm.svg";

const PopularAmen = [
  {
    name: "Free wi-fi",
    icon: Internet,
    // linkTo: "/",
  },
  {
    name: "Parking",
    icon: ParkingIcon,
    // linkTo: "/",
  },
  {
    name: "Living room",
    icon: Kitchen,
    // linkTo: "/",
  },
  {
    name: "Mix gender",
    icon: MixGender,
    // linkTo: "/",
  },
  {
    name: "Smoker friendly",
    icon: Smoking,
    // linkTo: "/",
  },
];

export default PopularAmen;
