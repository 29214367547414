import React from 'react'
import Footer from '../_newComponents/Footer'
import PageHeader from '../_newComponents/pageHeader'
import Tiles from '../_newComponents/Tiles'
import { Tenants_tile_items } from '../data/tilesData'
import image1 from '../images/OurTenants.png'
import Icon from '../images/professional.svg'
import Corporate from '../images/corporate.png'
import Student from '../images/student.png'
import Residents from '../images/resident.png'
import Digital from '../images/digital.png'
import Settlers from '../images/settlers.png'
import Tourists from '../images/tourist.png'

export default function OurTenants() {
  return (
    <>
        <PageHeader homeLink="/" />
        <div className='blog_body'>
            <div className='nHeader'>
                <div className='nHeader_bg'>
                    <p className='montserratN900-45 text-white'>Our Guests</p>
                </div>
            </div>
            <div className='card-body'>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    WE at RoomDaddy, believe that being a part of COMMUNITY is an essential human need, which YOU feel deprived of in today’s fast-paced life. People are lonely, not out of choice but because they have no choice. So, WE are building a Healthy Human Habitat for yo
                </p>
                <div className='mt-5'>
                    <div className='section pb-2'>
                        <img className='w-100 borderRadius-10' src={`${image1}`} alt='RMD_img'/>
                    </div>
                </div>
                <div className='circleBackGround'>
                    <p class="futuraN700-20 pt-4">We include EVERYONE and YOU!</p>
                    <div className='icon_gallery'>
                        <div className='icon_gallery_body'>
                            <div>
                                <img className='icon_gallery_img' src={`${Icon}`}/>
                                <p className='futuraN5-14 mt-2'>Young Professionals</p>
                            </div>
                            <div>
                                <img className='icon_gallery_img' src={`${Corporate}`}/>
                                <p className='futuraN5-14 mt-2'>Corporate Travelers</p>
                            </div>
                        </div>
                        <div className='icon_gallery_body'>
                            <div>
                                <img className='icon_gallery_img' src={`${Student}`}/>
                                <p className='futuraN5-14 mt-2'>Students</p>
                            </div>
                            <div>
                                <img className='icon_gallery_img' src={`${Residents}`}/>
                                <p className='futuraN5-14 mt-2'>Residents</p>
                            </div>
                            <div>
                                <img className='icon_gallery_img' src={`${Digital}`}/>
                                <p className='futuraN5-14 mt-2'>Digital Nomads</p>
                            </div>
                        </div>
                        <div className='icon_gallery_body'>
                            <div>
                                <img className='icon_gallery_img' src={`${Settlers}`}/>
                                <p className='futuraN5-14 mt-2'>New Settlers</p>
                            </div>
                            <div>
                                <img className='icon_gallery_img' src={`${Tourists}`}/>
                                <p className='futuraN5-14 mt-2'>Long Stay Tourists</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <p class="futuraN700-20 pt-4">How we help </p>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    RoomDaddy helps and takes the load off your shoulders and makes your Co-Living journey a smooth ride. We offer: 
                </p>
            </div>
            <Tiles items={Tenants_tile_items}/>
            <Footer/>
        </div>
    </>
  )
}
