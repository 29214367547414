import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { getUser } from "../../utils/functions";
import { imgArr } from "../data/serviceRequestData";
import MobileNav from "../_newComponents/MobileNav";
import BackIcon from "../icons/back.svg";
import "./style/serviceRequests.scss";

const ServiceRequests = () => {
  const user = getUser();

  const history = useHistory();
  const { booking_id } = useParams();

  const [booking, setBooking] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/my-bookings-details/${booking_id}`)
      .then((res) => {
        if (res.data.data[0].id != user.id) history.push("/my_booking");
        setBooking(res.data.data[0]);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${app.baseUrl}/api/complaint_list/${booking_id}`)
      .then((res) => setRequests(res.data.data))
      .catch((err) => console.log(err));
  }, [booking_id]);

  return (
    <div className="service-requests">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={BackIcon}
              alt="Back Arrow - RoomDaddy"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push(`/my_booking/co-living/${booking_id}`);
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Service reqeusts</div>
          </div>
        </div>
      </div>

      <div className="service-requests__card">
        <div className="rd-custom-card">
          <h3>{booking.fld_building}</h3>

          <table>
            <thead>
              <tr>
                <td>No</td>
                <td>Type</td>
                <td>Status</td>
                <td className="last">More info</td>
              </tr>
            </thead>

            <tbody>
              {requests.map((item, i) => (
                <tr key={i}>
                  <td>{item.fld_id}</td>

                  <td>
                    <img
                      src={imgArr[item.fld_complaint_type]}
                      alt="Service Type - RoomDaddy"
                    />
                  </td>

                  <td>
                    {item.fld_is_resolved_by
                      ? "Resolved"
                      : item.is_accepted
                      ? "In progress"
                      : item.assigned_to
                      ? "Assigned"
                      : item.fld_is_read
                      ? "Reviewed"
                      : "Pending"}
                  </td>

                  <td className="last">
                    <Button
                      onClick={() =>
                        history.push(
                          // `/my_booking/co-living/${booking_id}/service-requests/${item.fld_id}`
                          `/my_booking/co-living/service-requests/${item.fld_id}`
                        )
                      }
                      className="bookN-s w-100"
                    >
                      Detail
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <MobileNav />
    </div>
  );
};

export default ServiceRequests;
