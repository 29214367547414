import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import moment from "moment";
import { DatePicker, message } from "antd";
import { Button, IconButton } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { getUser } from "../../../utils/functions";
import { AppContext } from "../../AppContext";
import Modal from "../Modal";
import ConfirmPopup from "../ConfirmPopup";
import CrossIcon from "../../icons/cross_dark.svg";
import app from "../../utils/appConfig";
import BookedIcon from "../../icons/booked.svg";
import ViewIcon from "../../icons/view.svg";
import RelistIcon from "../../icons/relist.svg";
import EditIcon from "../../icons/edit.svg";
import ChartIcon from "../../icons/bar-chart.svg";
import ShareIcon from "../../icons/share.svg";
import DeleteIcon from "../../icons/delete.svg";
import ClipboardIcon from "../../icons/clipboard.svg";

const CoLivingListing = ({ item, isDraft = false }) => {
  const user = getUser();

  const history = useHistory();
  const { userInfo, setEditRoomDetail } = useContext(AppContext);

  const [showActionsModal, setShowActionsModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [statsPopup, setStatsPopup] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [deposit, setDeposit] = useState();
  const [dateVal, setDateVal] = useState(moment().format("YYYY-MM-DD"));
  const [rent, setRent] = useState();
  const [vals, setVals] = useState(item);
  const [link, setLink] = useState(
    `${window.location.origin}/flatshare/list/${vals?.bedspace_id}`
  );

  useEffect(() => {
    if (showActionsModal || showBookingModal || deletePopup || sharePopup)
      document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [showActionsModal, showBookingModal, deletePopup, sharePopup]);

  const disabledDate = (current) => {
    let d = new Date();
    return current && current < d.setDate(d.getDate() - 1);
  };

  const onRecordDel = (itemType, itemToDelete) => {
    axios
      .get(`${app.baseUrl}/api/${itemType}/${itemToDelete}`)
      .then((res) => {
        if (res.data != null) {
          console.log("api call " + itemType + itemToDelete);
          setVals(null);
        }
      })
      .catch((err) => console.log(err));
  };

  const onRecordPublish = (itemToPublish, itemStatus, itemDate) => {
    axios
      .get(
        `${app.baseUrl}/api/mylisting_approval?room_id=` +
          itemToPublish +
          "&booking=" +
          itemStatus +
          "&avalability_date=" +
          itemDate
      )
      .then((res) => {
        if (res.data != null) {
          message.success("Successfully published");
          setTimeout(() => window.location.reload(false), 500);
        }
      })
      .catch((err) => console.log(err));
  };

  const onRecordUnpublish = (itemToUnpublish, itemStatus, itemDate) => {
    axios
      .get(
        `${app.baseUrl}/api/mylisting_approval?room_id=` +
          itemToUnpublish +
          "&booking=" +
          itemStatus +
          "&avalability_date=" +
          itemDate
      )
      .then((res) => {
        if (res.data != null) {
          message.success("Successfully unpublished");
          setTimeout(() => window.location.reload(false), 500);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (action) => {
    setDeletePopup(false);
    if (action === "CANCEL") return;

    onRecordDel("delete-property", vals.room_id);
  };

  const copyLink = () => {
    const copyText = document.getElementById("rd-share-link");

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);
    message.success("Link copied");
  };

  const handleBooking = () => {
    if (!name || !email || !number || !deposit || !rent) {
      message.error("One or more fields are empty");
      return;
    }

    let data = new FormData();

    data.append("user_id", user.id);
    data.append("room_id", vals.room_id);
    data.append("bedspace_id", vals.bedspace_id);
    data.append("name", name);
    data.append("email", email);
    data.append("deposit", deposit);
    data.append("rent", rent);
    data.append("date", dateVal);
    data.append("number", number);

    const config = {
      headers: {
        "content-type": "book_form",
      },
    };

    axios
      .post(`${app.baseUrl}/api/book-listings`, data, config)
      .then((res) => {
        if (res.data["alert-type"] === "success") {
          message.success("Successfully booked listing");
          setShowBookingModal(false);
          setTimeout(() => window.location.reload(false), 500);
        } else message.error("An error occurred!");
      })
      .catch((err) => console.log(err));
  };

  return (
    vals && (
      <div className="pt-2 pb-3 mx-3">
        <div className="row">
          <div className="col">
            <div className="card" style={{ height: "150px" }}>
              <div className="card-body p-0 h-100">
                <div className="row h-100">
                  <div
                    className="col-5 pl-0 d-flex align-items-center justify-content-center"
                    style={{
                      height: "100%",
                    }}
                  >
                    <img
                      className="d-block bookingCard-img"
                      style={{ objectFit: "cover", height: "100%" }}
                      src={vals.room_image}
                      alt={vals.photos_listing_title}
                    />
                  </div>

                  <div className="col-7 p-0 pt-2 pb-2 pl-3">
                    <div className="row">
                      <div className="futuraBold14">
                        <p
                          className="mb-0"
                          style={{
                            color: "#1C2C4A",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "175px",
                            textTransform: "initial",
                          }}
                        >
                          {vals.room_name || vals.photos_listing_title}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="futuraBkBtN-12">
                        <p
                          className="mb-0 pt-1"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "175px",
                          }}
                        >
                          {vals.building || vals.area}{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      className="pt-2 pb-1 pl-0 pr-0 ml-n3"
                      style={{ fontFamily: "futuraBkBt" }}
                    >
                      <p className="mb-0">
                        <span
                          style={{
                            fontFamily: "futuraMdBt",
                            color: "#1C2C4A",
                          }}
                        >
                          AED {vals.room_expected_rent}
                        </span>
                      </p>

                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        {vals.total_lead.total_views} views
                      </p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between border-top pt-1 mt-2 ml-n3">
                      <div className="col-6 pl-0 pr-0">
                        <p>Actions</p>
                      </div>

                      <div className="col-6 pl-0 pr-4 text-right">
                        <IconButton
                          size="small"
                          onClick={() => setShowActionsModal(true)}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          popup
          visible={showActionsModal}
          onClose={() => setShowActionsModal(false)}
        >
          <div className="d-flex align-items-center justify-content-between p-3">
            <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

            <h5
              className="mb-0"
              style={{ color: "#1c1c1c", fontFamily: "futuraMdBt" }}
            >
              Actions
            </h5>

            <div>
              <img
                src={CrossIcon}
                alt="Close"
                onClick={() => {
                  setShowActionsModal(false);
                }}
              />
            </div>
          </div>

          <div
            style={{
              fontFamily: "futuraBkBt",
              color: "#1C1C1C",
              fontSize: "16px",
            }}
            className="container px-4 pt-3 pb-4"
          >
            {!isDraft ? (
              <div
                className="d-flex align-items-center mb-3 w-100"
                onClick={() =>
                  history.push(`/flatshare/list/${vals?.bedspace_sku}`)
                }
              >
                <img
                  className="mr-3"
                  width="23px"
                  style={{ marginLeft: "-2px" }}
                  src={ViewIcon}
                  alt="Booked"
                />

                <span>View</span>
              </div>
            ) : null}

            {vals.is_listing_approved !== 3 && vals.is_listing_approved === 1 && (
              <div
                className="d-flex align-items-center mb-3 w-100"
                onClick={() => {
                  setShowActionsModal(false);
                  setShowBookingModal(true);
                }}
              >
                <img
                  className="mr-3"
                  width="20px"
                  src={BookedIcon}
                  alt="Booked"
                />

                <span>Mark as booked</span>
              </div>
            )}

            <div
              onClick={() => {
                history.push("/room_rent");
                setEditRoomDetail({ roomEditId: item.bedspace_id });
              }}
              className="d-flex align-items-center mb-3 w-100"
            >
              <img className="mr-3" width="20px" src={EditIcon} alt="Edit" />
              <span>{isDraft ? "Finish listing" : "Edit"}</span>
            </div>

            {!isDraft ? (
              <div
                className="d-flex align-items-center mb-3 w-100"
                onClick={() => {
                  setStatsPopup(true);
                  setShowActionsModal(false);
                }}
              >
                <img
                  className="mr-3"
                  width="20px"
                  src={ChartIcon}
                  alt="Chart"
                />
                <span>Stats</span>
              </div>
            ) : null}

            {!isDraft ? (
              <div
                className="d-flex align-items-center mb-3 w-100"
                onClick={() => {
                  setSharePopup(true);
                  setShowActionsModal(false);
                }}
              >
                <img
                  className="mr-3"
                  width="20px"
                  src={ShareIcon}
                  alt="Share"
                />
                <span>Share</span>
              </div>
            ) : null}

            {!isDraft ? (
              vals.is_listing_approved === 2 ? (
                <div
                  className="d-flex align-items-center mb-3 w-100"
                  onClick={() => {
                    setShowActionsModal(false);
                    onRecordPublish(vals.room_id, 1, vals.avalability_date);
                  }}
                >
                  <img
                    className="mr-3"
                    width="20px"
                    src={RelistIcon}
                    alt="Unpublish"
                  />
                  <span>Publish</span>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center mb-3 w-100"
                  onClick={() => {
                    setShowActionsModal(false);
                    onRecordUnpublish(vals.room_id, 2, vals.avalability_date);
                  }}
                >
                  <img
                    className="mr-3"
                    width="20px"
                    src={RelistIcon}
                    alt="Unpublish"
                  />
                  <span>Unpublish</span>
                </div>
              )
            ) : null}

            <div
              className="d-flex align-items-center mb-3 w-100"
              style={{ color: "#E04025" }}
              onClick={() => {
                setDeletePopup(true);
                setShowActionsModal(false);
              }}
            >
              <img
                className="mr-3"
                width="20px"
                src={DeleteIcon}
                alt="Delete"
              />
              <span>Remove</span>
            </div>
          </div>
        </Modal>

        <Modal
          popup
          visible={showBookingModal}
          onClose={() => setShowBookingModal(false)}
        >
          <div className="d-flex align-items-center justify-content-between p-3">
            <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

            <h5
              className="mb-0"
              style={{ color: "#1c1c1c", fontFamily: "futuraMdBt" }}
            >
              Book Property
            </h5>

            <div>
              <img
                src={CrossIcon}
                alt="Close"
                onClick={() => {
                  setShowBookingModal(false);
                }}
              />
            </div>
          </div>

          <div
            style={{
              fontFamily: "futuraBkBt",
              color: "#1C1C1C",
              fontSize: "16px",
            }}
            className="custom-body container px-4 pt-3 pb-4"
          >
            <input
              type="text"
              className="rd-input w-100"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <input
              type="text"
              className="rd-input w-100"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              type="text"
              className="rd-input w-100"
              placeholder="Phone No"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />

            <input
              type="text"
              className="rd-input w-100"
              placeholder="Deposit"
              value={deposit}
              onChange={(e) => setDeposit(e.target.value)}
            />

            <input
              type="text"
              className="rd-input w-100"
              placeholder="Rent"
              value={rent}
              onChange={(e) => setRent(e.target.value)}
            />

            <DatePicker
              size="large"
              disabledDate={disabledDate}
              defaultValue={moment(userInfo.checkIn, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              onChange={(e) => setDateVal(e.format("YYYY-MM-DD"))}
            />

            <Button className="bookN w-100 mt-3" onClick={handleBooking}>
              Book
            </Button>
          </div>
        </Modal>

        {deletePopup && (
          <ConfirmPopup
            title="Delete"
            onConfirm={() => handleDelete("CONFIRM")}
            onCancel={() => handleDelete("CANCEL")}
          >
            Are you sure?
          </ConfirmPopup>
        )}

        {sharePopup && (
          <ConfirmPopup
            title="Copy Link"
            onCancel={() => setSharePopup(false)}
            isGeneral
          >
            <input
              id="rd-share-link"
              type="text"
              value={link}
              style={{ display: "none" }}
            />

            <div className="d-flex align-items-center justify-content-between">
              <div className="share-input w-100 mr-2">{link}</div>

              <Button className="copy-btn" onClick={copyLink}>
                <img src={ClipboardIcon} alt="Copy" />
              </Button>
            </div>
          </ConfirmPopup>
        )}

        {statsPopup && (
          <ConfirmPopup
            title="Stats"
            onCancel={() => setStatsPopup(false)}
            isGeneral
          >
            <div className="rd-stats">
              <p>
                <span>Chat Lead</span>
                <span>{vals.total_lead.chat_lead}</span>
              </p>

              <p>
                <span>Email Lead</span>
                <span>{vals.total_lead.email_lead}</span>
              </p>

              <p>
                <span>Phone Lead</span>
                <span>{vals.total_lead.phone_lead}</span>
              </p>

              <p>
                <span>Total Views</span>
                <span>{vals.total_lead.total_views}</span>
              </p>
            </div>
          </ConfirmPopup>
        )}
      </div>
    )
  );
};

export default CoLivingListing;
