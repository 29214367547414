import WifiIcon from "../icons/wifi-router.svg";
import ACIcon from "../icons/air-conditioner.svg";
import SupportIcon from "../icons/customer-support.svg";
import PlumbingIcon from "../icons/plumbing.svg";
import OvenIcon from "../icons/oven.svg";
import KeysIcon from "../icons/keys.svg";
import ElectricIcon from "../icons/electric.svg";
import CleaningIcon from "../icons/cleaning.svg";

const sr = [
  { icon: CleaningIcon, title: "Cleaning", value: "Cleaning" },
  { icon: PlumbingIcon, title: "Plumbing", value: "Plumbing" },
  { icon: ElectricIcon, title: "Electrical", value: "Electrical" },
  { icon: ACIcon, title: "AC", value: "AC" },
  { icon: KeysIcon, title: "Key", value: "Key" },
  { icon: WifiIcon, title: "Internet", value: "Internet" },
  { icon: OvenIcon, title: "Kitchen-Appliances", value: "Kitchen-Appliances" },
  { icon: SupportIcon, title: "Other", value: "Other" },
];

const imgArr = {
  Cleaning: CleaningIcon,
  Plumbing: PlumbingIcon,
  Electrical: ElectricIcon,
  Electircal: ElectricIcon,
  AC: ACIcon,
  Key: KeysIcon,
  Internet: WifiIcon,
  "Kitchen-Appliances": OvenIcon,
  "Kitchen-Applainces": OvenIcon,
  Other: SupportIcon,
};

export { sr, imgArr };
