import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { msg } from "../../firebase";
import { getUser, setLsValue } from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import VerifyImage from "../images/verifyEmail.svg";
import DotsTop from "../images/dots_login-1.svg";
import DotsBottom from "../images/dots_login-2.svg";
import "./style/verifyEmail.scss";
import { CACHE__AUTH_USER } from "../data/cacheKeys";

const VerifyEmailSend = () => {
  // POST: get_verify_email DATA: email

  //const user = getUser();
  const history = useHistory();
  const [userid, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [Email, setEmail] = useState({
    email: "",
  });

//  console.log("user",user);

//   useEffect(() => {
//     if (!user) history.push();
//   }, [user]);

  const verifyThisEmail = async (e) => {
    e.preventDefault();

    if (!Email) {
      message.error("Please Enter Email");
      return;
    }

    setIsLoading(true);

    let data = new FormData();
    data.append("email", Email.email);

    await axios
      .post(`${app.baseUrl}/api/get_verify_email`, data)
      .then((res) => {
        console.log("Send Mail Api", res);

        if (!res.data.status) message.error(res.data.message);
        else if (res.data.message === "Verification Code Error")
          message.error("Incorrect OTP");
        else message.success("Mail Sent! Please verify your account with otp");

       

        //let data = res.data.data;
        let userData = {
          id: res.data.data.uid,
          name: res.data.data.name,
          email: res.data.data.email,
          otp: res.data.data.otp,
        };
        let appState = {
          //isLoggedIn: true,
          user: userData,
        };
        console.log("user",userData);
setUserId(data.uid);
        setLsValue(CACHE__AUTH_USER, appState);

        //setIsLoggedIn(true);
        setIsLoading(false);

        window.location = "/get_email";
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

 

  return  (
    <div className="verify">
      <PageHeader homeLink="/" />

      <img src={DotsTop} alt="RoomDaddy" className="verify__dots--top" />

      <div className="verify__body">
        <img src={VerifyImage} alt="RoomDaddy" className="verify__image mt-4" />

        <h1 className="py-3">Verify Email</h1>

        <div className="reset-pass__caption px-2">
          Please enter your Email to verify <br />
          
        </div>

        <form className="verify__form mt-4" onSubmit={verifyThisEmail}>
          <div className="">
            <input
              id="email"
              
              onChange={(e) => {
                setEmail({ ...Email, email: e.target.value });
              }}
              className="verify__input"
            />

           
          </div>

          <Button
            id="email-submit"
            type="submit"
            variant="contained"
            className="verify__button"
            disabled={isLoading}
          >
            {isLoading ? "Sending Mail..." : "Send Mail"}
          </Button>
        </form>
      </div>

      <img src={DotsBottom} alt="RoomDaddy" className="verify__dots--bottom" />
    </div>
  );
};

export default VerifyEmailSend;
