const data = [
    {
        title: "PRIVACY POLICY",
        content:   [
            {
                title: `Roomdaddy (“us”, “we”, or “our”) operates http://roomdaddy.com (the “Site”) as well as a messaging platform for the efficient and convenient booking of rooms and rental spaces via the Site, email, SMS, or MMS (the “Service”). This Privacy Policy informs you of our policies regarding the collection, use and disclosure of personal information we receive from users of the Site or Service. We use your personal information only for providing the Service and improving the Site. By using the Site or Service, you agree to the collection and use of information in accordance with this policy.`
            }
        ]
    },
    {
        title: "INFORMATION COLLECTION AND USE",
        content: [
            {
                title: `We may ask you to provide us with certain personally identifiable information, while you are using the Site, for the purpose of contacting or identifying you. This information may include, but is not limited to, your name, email address, mobile phone numbers, address(es), username, password, living/renting preferences, credit card information, billing information, and other information you provide us (Personal Information).

                Your Personal Information may be shared by us with third-parties, including but not limited to, payment vendors, hotel vendors, or third-party suppliers, in order to facilitate the processing and fulfillment of your bookings, improve our Service, comply with laws, protect our rights and property, in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets to another company.`,
            },
        ]
    },
    {
        title: "SOCIAL NETWORKING",
        content: [
            {
                title: `You may be able to login to the Site or Services using another social networking site, such as Google, or Facebook. We may receive Personal Information from the social networking site you connect your account with, as per their privacy policies.`,
            },
        ]
    },
    {
        title: "LOG DATA",
        content: [
            {
                title: `We collect data information that your browser sends whenever you visit and use our Site (referred to as “Log Data”). Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.

                We may also log information using web beacons (or pixel tags) on our Site or in our emails, which we use in order to manage cookies, count visits, learn if you open or act on our emails, and learn what promotional strategies work best.`,
            }
        ]
    },
    {
        title: "COOKIES",
        content: [
            {
                title: `Like many sites, we use cookies to collect information. You can instruct your browser to notify you when a cookie is being sent, or refuse all cookies. However, your use of some portions of the Site may be restricted when you refuse our cookies.`,
            }
        ]
    },
    {
        title: "ANALYTICS",
        content: [
            {
                title: `To improve our Site and Service, we may use analytical services such as Google Analytics (see
                    http://www.google.com/policies/privacy/partners/ and
                    https://tools.google.com/dlpage/gaoptout)`,
            }
        ]
    },
    {
        title: "SECURITY",
        content: [
            {
                title: `The security of your personal information is of significant importance to us. You must acknowledge that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.`,
            },
        ]
    },
    {
        title: "POLICY CHANGES",
        content: [
            {
                title: `We reserve the right to change these policies from time to time. You will be notified of any changes in the policies by us, in the new Privacy Policy on the Site.`,
            }
        ]
    },
    {
        title: "CONTACT US",
        content: [
            {
                title: `If you have any questions about this Privacy Policy, please contact us at info@roomdaddy.com`,
            },
        ]
    },
]
export default data;