import React, { useState, useContext } from 'react'
import { AppContext } from "../../AppContext";
import Header from "../../components/pageHeader/index"
import { DatePicker } from 'antd';
import moment from "moment"
// import axios from 'axios'
// import app from './../../utils/appConfig';

export default function ParkingBook() {
    const { userInfo } = useContext(AppContext);
    const [bookParking, setBookParking] = useState({
        email: "",
        monthlyPrice: "",
        startDate: userInfo.checkIn,
        endDate: userInfo.checkOut,
        initialDeposit: "",
        vehicalNumber: ""
    });
    function updateFormData(key, value){
        setBookParking({...bookParking, [key]: value})
    }
    const data = [
        {
            name: "Title",
            subTitle: " This Is Title For Parking Nadeem"
        },
        {
            name: "Location",
            subTitle: "DHA Phase 6"
        },
        {
            name: "Company Deparment",
            subTitle: "Indoor",
        },
        {
            name: "Initial Deposit",
            subTitle: "500",
        }
    ]
    return (
        <>
            <Header
                arrowLink={'/my-listings'}
                heading={"Parking Details"}
            />
            <main className="flex-shrink-0">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {data.map((item, index)=>{
                                    return(
                                        <>
                                            <div className="col-12 col-md-6 mt-2 mb-2">
                                                <h6>{item.name}</h6>
                                                <div className="text-secondary">
                                                    <div className="col-12 text-dark p-0">
                                                        <p className="small">
                                                            {item.subTitle}
                                                            {
                                                                item.subTypes && 
                                                                <>
                                                                    <ul className="text-secondary small lists">
                                                                        {item.subTypes.map((item, index)=>{
                                                                            return(<><li key={index}>{item.name}</li></>)
                                                                        })}
                                                                    </ul>
                                                                </>
                                                            }
                                                        </p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                <div className="col-12">
                                    <h6>Book Parking</h6>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={bookParking.email !=0 ? "form-group floating-form-group active" : "form-group floating-form-group"}>
                                        <input type="email" 
                                            onChange={(e)=>{updateFormData("email", e.target.value)}} 
                                            className="form-control floating-input"
                                            value={bookParking.email}
                                        />
                                        <label className="floating-label">Email address</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={bookParking.monthlyPrice !=0 ? "form-group floating-form-group active" : "form-group floating-form-group"}>
                                        <input type="number" 
                                            onChange={(e)=>{updateFormData("monthlyPrice", e.target.value)}} 
                                            className="form-control floating-input"
                                            value={bookParking.monthlyPrice}
                                        />
                                        <label className="floating-label">Monthly price</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="small">Start & End date</p>
                                </div>
                                <br/>
                                <div className="col-12 col-md-6">
                                    <DatePicker  
                                        style={{ width: 250 }}
                                        defaultValue={moment(bookParking.startDate, 'YYYY/MM/DD')}
                                        onChange={(e, str)=> updateFormData("startDate", str)} 
                                    />
                                </div>
                                <br/>
                                <br/>
                                <div className="col-12 col-md-6">
                                    <DatePicker  
                                        style={{ width: 250 }}
                                        defaultValue={moment(bookParking.endDate, 'YYYY/MM/DD')}
                                        onChange={(e, str)=> updateFormData("endDate", str)} 
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={bookParking.initialDeposit !=0 ? "form-group floating-form-group active" : "form-group floating-form-group"}>
                                        <input type="number" 
                                            onChange={(e)=>{updateFormData("initialDeposit", e.target.value)}} 
                                            className="form-control floating-input"
                                            value={bookParking.initialDeposit}
                                        />
                                        <label className="floating-label">Initial deposit</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className={bookParking.vehicalNumber !=0 ? "form-group floating-form-group active" : "form-group floating-form-group"}>
                                        <input type="text" 
                                            onChange={(e)=>{updateFormData("vehicalNumber", e.target.value)}} 
                                            className="form-control floating-input"
                                            value={bookParking.vehicalNumber}
                                        />
                                        <label className="floating-label">Vehical number</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <button type="button" className="mt-4 mb-3 btn btn-info btn-block btn-lg btn btn-primary" onClick={""}>Book</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
