import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import OneSignal from "react-onesignal";
import axios from "axios";
import { message, Select } from "antd";
import { CloseRounded } from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import NoImage from "../images/no-image.png";
import Back from "../icons/back.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
import BathIcon from "../icons/bathAm.svg";
import UserIcon from "../icons/user.svg";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import "./style/hotelDetailRoom.scss";
import {
  getLsValue,
  removeLsValue,
  separateNumbers,
  setLsValue,
} from "../../utils/functions";
import { CACHE__H_ROOMS_CART } from "../data/cacheKeys";
import RdLoader from "../_newComponents/RdLoader";
import Modal from "../_newComponents/Modal";

export default function HotelDetailRooms() {
  const { Option } = Select;

  const history = useHistory();
  const { id } = useParams();
  const {
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [hotel, setHotel] = useState({});
  const [rooms, setRooms] = useState([]);
  const [guests, setGuests] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [state, setState] = useState({
    numberOfRooms: numOfRooms,
    breakfast: false,
    numberOfBreakfast: 0,
    internet: false,
    parking: false,
    pets: false,
  });

  const [phonePopUp, setPhonePopUp] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  function updateState(name, value) {
    setState({ ...state, [name]: value });
  }

  function getRoomPrice(index) {
    if (hotel.hotel_active_rooms) {
      let tax = 0;

      if (
        // hotel["hotel_active_rooms"][index]["room_rent_type"][
        //   "room_rent_tax"
        // ] === "no"

        hotel.hotel_active_rooms[index].room_rent_type &&
        hotel.hotel_active_rooms[index].room_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(hotel["hotel_tax"]) / 100) *
          hotel["hotel_active_rooms"][index]["room_base_price"];
        tax =
          tax +
          hotel["hotel_active_rooms"][index]["room_base_price"] +
          hotel["hotel_tax_amount"];
      } else {
        tax = hotel["hotel_active_rooms"][index]["room_base_price"];
      }

      return Math.ceil(tax);
    }
  }

  function updatePrice() {
    if (rooms.hotel_active_rooms) {
      let total = getRoomPrice(activeModalIndex);
      total = total * state.numberOfRooms;

      if (state.breakfast) {
        let bfastPrice = Number(rooms.services.optional_breakfast_price);
        total = total + bfastPrice * state.numberOfBreakfast;
      }

      setTotalPrice(total);
      return total;
    }
  }

  function getOptions(e) {
    if (!e)
      return (
        <Option value={0} key={0}>
          0 rooms
        </Option>
      );

    let elem = [];

    for (let i = 1; i <= parseInt(e); i++) {
      if (i <= 3) {
        elem.push(
          <Option value={i} key={i}>
            {i} {i === 1 ? "room" : "rooms"}
          </Option>
        );
      } else {
        break;
      }
    }

    return elem;
  }

  function clearRoom(id) {
    let temp = selectedRooms;
    delete temp[id];

    setSelectedRooms(temp);
    setState({
      numberOfRooms: 1,
      breakfast: false,
      numberOfBreakfast: 0,
      internet: false,
      parking: false,
      pets: false,
    });

    if (Object.keys(temp).length) {
      const cart = {
        selectedRooms: temp,
        userInfo,
        state,
      };

      setLsValue(CACHE__H_ROOMS_CART, cart);
    } else {
      removeLsValue(CACHE__H_ROOMS_CART);
    }
  }

  function getTotalPrice() {
    let price = 0;

    Object.keys(selectedRooms).map((elem) => {
      price += selectedRooms[elem].totalAmmount;
    });

    return price;
  }

  function reserveRoom(index) {
    const sR = rooms[index];
    const newData = {
      ...selectedRooms,
      [sR.id]: {
        ...state,
        hotelName: hotel.hotel_name,
        hotelId: sR.parent_id,
        roomId: sR.id,
        roomName: sR.room_name,
        roomType: sR.room_type,
        roomSize: sR.room_size,
        checkIn: userInfo.checkIn,
        checkOut: userInfo.checkOut,
        currency: hotel.hotel_currency,
        // totalAmmount: updatePrice(),
        totalAmmount: getRoomPrice(index) * state.numberOfRooms,
      },
    };

    setActiveModalIndex(index);
    setSelectedRooms(newData);

    // const cart = {
    //   selectedRooms: newData,
    //   userInfo,
    //   state,
    // };

    // setLsValue(CACHE__H_ROOMS_CART, cart);

    // OneSignal.sendTags({
    //   is_cart: true,
    //   cart_update: Math.floor(Date.now() / 1000),
    //   // hotel_id: sR.parent_id,
    //   hotel_name: hotel?.hotel_name,
    //   hotel_image: hotel?.hotel_images?.[0]?.image,
    //   // rooms: Object.keys(newData).length,
    // })
    //   .then((res) => console.log("Cart Tagged:", res))
    //   .catch((err) => console.log(err));

    getTotalPrice();
  }

  // useEffect(() => {
  //   const cart = getLsValue(CACHE__H_ROOMS_CART);

  //   if (cart) {
  //     setSelectedRooms(cart.selectedRooms);
  //     setUserInfo(cart.userInfo);
  //     setState(cart.state);
  //   }
  // }, []);

  useEffect(() => {
    axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_hotel_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        setHotel(res.data[0]);
        setRooms(res.data[0].hotel_active_rooms);

        let n = 0;
        res.data[0].hotel_active_rooms.forEach((room) => {
          n += room.number_guests_room;
        });

        setGuests(n);
        setLoading(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  }, []);

  return (
    <div className="layoutBody" style={{ padding: "65px 0" }}>
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  // history.push(`/hotel/detail/${id}`);
                  history.push(`/hotel-rooms`);
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Available rooms</div>
          </div>
        </div>
      </div>

      {loading ? (
        <RdLoader />
      ) : (
        rooms.map((room, i) => (
          <>
            <div key={i} className="container-fluid">
              <div className="pt-2 pb-2">
                <div className="card" style={{ overflow: "hidden" }}>
                  <div className="card-body">
                    <Link to={`/hotel/detail/${id}/rooms/${room.id}`}>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p
                              className="futuraN700-17 m-0 mb-3"
                              // style={{
                              //   width: "165px",
                              //   whiteSpace: "nowrap",
                              //   overflow: "hidden",
                              //   textOverflow: "ellipsis",
                              // }}
                            >
                              {room.room_custom_name &&
                              room.room_custom_name !== "null" &&
                              room.room_custom_name !== "0"
                                ? room.room_custom_name
                                : room.room_name}
                            </p>

                            <p className="mb-2">
                              Size: {room.room_size} ft<sup>2</sup>
                            </p>

                            <ul className="futuraBkBtN-14 p-0">
                              {/* <li>
                                <img src={BedIcon} alt="" />
                                <span className="ml-2">1 king bed</span>
                              </li> */}

                              <li className="d-flex align-items-center">
                                <img src={UserIcon} alt="" className="mr-2" />
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {/* {guests} person(s) */}
                                  {userInfo.adult} person(s)
                                </span>
                              </li>

                              {/* <li className="d-flex align-items-center">
                                <img src={BathIcon} alt="" className="mr-2" />
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {room.number_bathrooms_apartments} Bathroom(s)
                                </span>
                              </li> */}

                              {/* <li className="d-flex align-items-center">
                                <img src={DoorIcon} alt="" className="mr-2" />
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {room.number_rooms_this_type || "N/A"} room(s)
                                  remaining
                                </span>
                              </li> */}
                            </ul>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="room-image">
                            <img
                              src={room?.room_images?.[0]?.image || NoImage}
                              alt="BannerImage"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="card-footer">
                    <div
                      className="row d-flex align-items-end"
                      style={{
                        justifyContent:
                          hotel.policies.live_booking === "yes"
                            ? "space-between"
                            : "flex-end",
                        marginRight:
                          hotel.policies.live_booking === "yes"
                            ? "0 !important"
                            : "inherit",
                        marginLeft:
                          hotel.policies.live_booking === "yes"
                            ? "0 !important"
                            : "inherit",
                      }}
                    >
                      {hotel?.policies?.live_booking === "yes" && (
                        <div className="col-auto pr-1">
                          <div>
                            {selectedRooms[room.id] ? (
                              <>
                                <Select
                                  defaultValue={
                                    selectedRooms[room.id].numberOfRooms
                                  }
                                  onChange={(value) => {
                                    updateState("numberOfRooms", value);
                                    setSelectedRooms({
                                      ...selectedRooms,
                                      [room.id]: {
                                        ...selectedRooms[room.id],
                                        numberOfRooms: value,
                                      },
                                    });
                                  }}
                                  disabled
                                >
                                  {getOptions(
                                    room?.number_rooms_this_type || 0
                                  )}
                                </Select>

                                <button
                                  className="close-btn ml-2"
                                  onClick={() => clearRoom(room.id)}
                                >
                                  <CloseRounded />
                                </button>
                              </>
                            ) : (
                              <>
                                <Select
                                  className="selector-ant"
                                  style={{ width: 100 }}
                                  placeholder="Select room"
                                  optionFilterProp="children"
                                  defaultValue={
                                    selectedRooms[room.id]?.numberOfRooms ||
                                    state.numberOfRooms
                                  }
                                  onChange={(value) =>
                                    updateState("numberOfRooms", value)
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {getOptions(
                                    room?.number_rooms_this_type || 0
                                  )}
                                </Select>

                                <Button
                                  className="bookN-s futuraN700-14"
                                  onClick={() => {
                                    reserveRoom(i);

                                    setNumOfRooms(1);
                                    setState({
                                      numberOfRooms: 1,
                                      breakfast: false,
                                      numberOfBreakfast: 0,
                                      internet: false,
                                      parking: false,
                                      pets: false,
                                    });
                                  }}
                                >
                                  Select
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-auto">
                        <div class="text-end text-right">
                          <p class="futuraBkBtN-14 m-0">
                            Price for {userInfo.diffInDays} night
                          </p>

                          <p class="montserratNB-16 m-0">
                            {/* AED {separateNumbers(getRoomPrice(i))} */}

                            {getRoomPrice(i) ? (
                              hotel?.hotel_currency +
                              " " +
                              separateNumbers(getRoomPrice(i))
                            ) : (
                              <span onClick={() => setPhonePopUp(true)}>
                                Inquire Now
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ResponsiveAd />

            <Modal visible={phonePopUp}>
              <div className="p-4 text-center">
                <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
                Would you like to contact the host on WhatsApp?
                </p>

                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    className="bookN-s mr-3"
                    onClick={() => setPhonePopUp(false)}
                  >
                    {/* <a href={`tel:${hotel?.alternate_phone_number}`}>Yes</a> */}
                    {/* <a href={`tel:+971582191323`}>Yes</a> */}
                    <a href={`whatsapp://send?phone=+971523971907&text=I'm interested to know about ${hotel?.hotel_name}`} 
                  class="bla"
                  >Yes</a>
                  </Button>

                  <Button
                    className="bookN-s"
                    onClick={() => setPhonePopUp(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </Modal>

            {hotel?.policies?.live_booking === "yes" && (
              <div
                className="bottom-navigation d-flex align-items-center justify-content-between"
                style={{
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(10px) saturate(180%)",
                  zIndex: 999,
                }}
              >
                <div className="futuraBkBtN-14 w-50">
                  {getTotalPrice() ? (
                    <>
                      <span className="text-muted">Total price</span>
                      <h5 style={{ fontFamily: "futuraMdBt" }}>
                        AED {separateNumbers(getTotalPrice())}
                      </h5>
                    </>
                  ) : (
                    <span className="text-muted">Select a room first</span>
                  )}
                </div>

                <div className="futuraN700-14">
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={open}
                    title={
                      selectedRooms &&
                      Object.keys(selectedRooms).length === 0 &&
                      selectedRooms.constructor === Object
                        ? "You need to select a room first"
                        : "Go to checkout"
                    }
                  >
                    <Button
                      size="large"
                      type="primary"
                      className="bookN ReserveHroom"
                      id="hotel-room-mob-reserve"
                      onClick={() => {
                        handleTooltipOpen();
                        history.push(
                          selectedRooms &&
                            Object.keys(selectedRooms).length === 0 &&
                            selectedRooms.constructor === Object
                            ? "#"
                            : hotel.services.breakfast === "optional" ||
                              hotel.services.internet === "paid" ||
                              hotel.services.parking === "paid" ||
                              hotel.policies.allow_pets === "yes"
                            ? `/hotel/detail/${id}/addons`
                            : "/checkout"
                        );
                      }}
                    >
                      Reserve
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        ))
      )}
    </div>
  );
}
