import React, { Component  } from 'react'

class MyLeaderBoardAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>
<ins className="adsbygoogle"
     style={{display:"block"}}
     data-ad-format="fluid"
     data-ad-layout-key="-58+bt-70-a9+1ne"
     data-ad-client="ca-pub-8263454666215740"
     data-ad-slot="7312252572"></ins>
        </div>)
    }
}

export default MyLeaderBoardAd