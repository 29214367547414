import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Collapse, DatePicker, message } from "antd";
import { WingBlank, Carousel } from "antd-mobile";

import app from "../utils/appConfig";
import { AppContext } from "../AppContext";

import BannerImage from "../images/mobileBannerImage.png";
import UploadProfile from "../images/uploadProfile.jpg";
import Plus from "../images/plus.png";
import Minus from "../images/minus.png";

import PhoneCall from "../icons/phone-call.svg";
import Email from "../icons/email.svg";
import Back from "../icons/back.svg";
import Next from "../icons/next.svg";
import RatingStar from "../icons/rating.svg";
import PinMark from "../icons/pinMarker.svg";
import UserIcon from "../icons/user.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
import BathIcon from "../icons/bathAm.svg";
import User from "../icons/guest2Icon.svg";
import Cross from "../icons/cross.svg";
import Tick from "../icons/tick.svg";

import { MetaTags } from "react-meta-tags";
import PageHeader from "../_newComponents/pageHeader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import GoogleMap from "../_newComponents/GoogleMap";
import "./style/hotelApartment.css";
import { Button } from "@material-ui/core";
import moment from "moment";
import Modal from "../_newComponents/Modal";
import ImageGrid from "../_newComponents/ImageGrid";
import LeafletMap from "../_newComponents/LeafletMap";
import RdLoader from "../_newComponents/RdLoader";
import { getUser, separateNumbers } from "../../utils/functions";
import ContentLoader from "react-content-loader";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const dateFormat = "YYYY-MM-DD";

export default function HotelApartmentDetail() {
  const history = useHistory();
  const { id } = useParams();
  const { userInfo, setUserInfo } = useContext(AppContext);

  const user = getUser();
  const [isLeadCalled, setIsLeadCalled] = useState(false);
  const [ip, setIp] = useState();
  const [loading, setLoading] = useState(true);
  const [calculating, setCalculating] = useState(false);
  const [apartmentIndex, setApartmentIndex] = useState(-1);
  const [apt, setApt] = useState({});
  const [reviews, setReviews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [guestNumbers, setGuestNumbers] = useState(1);
  const [guestPopUp, setGuestPopUp] = useState(false);
  const [images, setImages] = useState([]);
  const [position, setPosition] = useState([25.2048, 55.2708]);
  const [phonePopUp, setPhonePopUp] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const [contact, setContact] = useState({
    name: user?.name,
    email: user?.email,
    number: user?.phone,
    message:
      "I saw your ad on Roomdaddy. When is it available for viewing? Thanks.",
  });


  const handleClose = () => setIsModalVisible(false);
  const handleShow = () => setIsModalVisible(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const collectLead = (type) => {
    const data = new FormData();

    data.append("user_id", user?.id);
    data.append("hotel_id", id);
    data.append("fld_system_ip", ip);
    data.append("lead_type", type);
    data.append("access_type", "A");

    axios
      .post(`${app.hotelBaseUrl}/api/save_phone_lead`, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // axios
    //   .get("http://geoplugin.net/json.gp")
    //   .then((res) => setIp(res.data.geoplugin_request))
    //   .catch((err) => console.log(err));

    axios
      .get(`${app.baseUrl}/api/get_ip`)
      .then((res) => setIp(res.data.ip))
      .catch((err) => console.log(err));

    fetchData();
    // fetchSmartListing();

    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (
      // user?.id &&
      apt?.id &&
      ip &&
      !isLeadCalled
    ) {
      collectLead("v");
      setIsLeadCalled(true);
    }
  }, [
    apt?.id,
    ip,
    user?.id,
    isLeadCalled,
  ]);


  const startDate = (date, dateStr) => {
    setUserInfo({
      ...userInfo,
      checkIn: dateStr,
    });
  };

  const endDate = (date, dateStr) => {
    setUserInfo({
      ...userInfo,
      checkIn: userInfo.checkIn,
      checkOut: dateStr,
      diffInDays: date.diff(userInfo.checkIn, "days"),
    });
  };

  const guestIncrement = () => {
    if (guestNumbers == 6) return;
    else setGuestNumbers((prev) => prev + 1);
  };

  const guestDecrement = () => {
    if (guestNumbers == 1) return;
    else setGuestNumbers((prev) => prev - 1);
  };

  const onOpen = (key) => (e) => {
    e.preventDefault();
    if (key === "Guest") setGuestPopUp(true);
  };

  const onClose = (key) => (e) => {
    if (key === "Guest") setGuestPopUp(false);
  };

  const disabledDate = (current) => {
    let d = new Date();
    return current && current < d.setDate(d.getDate() - 1);
  };

  const sendEmail = () => {
    const data = new FormData();

    for (let key in contact) {
      if (key !== "number" && !contact[key]) return;
      data.append(key, contact[key]);
    }

    data.append("owner_mail_id", 'info@roomdaddy.com');
    data.append("owner_name", apt?.hotel_name);
    data.append("own_property_description", apt?.hotel_desc.substr(0,100));
    // data.append("room_name", coLiving?.room_title);
    data.append("link", window.location.href);

    axios
      .post(`${app.baseUrl}/api/reply-form`, data)
      .then((res) => {
        message.success("Your email has been sent.");
        onClose("Email")();
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });
  };

  // const disabledDate = (current) => {
  //   if (!dates || dates.length === 0) return false;

  //   const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
  //   const tooEarly = dates[1] && dates[1].diff(current, "days") > 7;
  //   return tooEarly || tooLate;
  // };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else setHackValue(undefined);
  };

  const fetchData = () => {
    axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_apart_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        let aptIndex = res.data[0].apartments.findIndex(
          (e) => e.hotel_id == id
        );

        const imgArr = [];
        res.data[0].hotel_images.forEach((image) => imgArr.push(image.image));
        setImages(imgArr);

        setApartmentIndex(aptIndex);
        setApt(res.data[0]);

        if (res.data[0].hotel_latitude && res.data[0].hotel_longitude)
          setPosition([
            res.data[0].hotel_latitude,
            res.data[0].hotel_longitude,
          ]);

          setLoading(false);
        setCalculating(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  };

  const fetchReviews = () => {
    axios
      .get(`${app.hotelBaseUrl}/api/get_hotel_reviews/${id}`)
      .then((res) => setReviews(res.data))
      .catch((err) => {
        message.error("An error occurred while fetching reviews");
        console.log(err);
      });
  };

  const getRoomPrice = (index) => {
    console.log("-->", index);

    if (apt.apartments) {
      let tax = 0;

      if (
        apt.apartments[index] &&
        apt.apartments[index].apartment_rent_type &&
        apt.apartments[index].apartment_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(apt.hotel_tax) / 100) *
          apt.apartments[index].room_base_price;
        tax =
          tax + apt.apartments[index].room_base_price + apt.hotel_tax_amount;
      } else {
        tax = apt.apartments[index]
          ? apt.apartments[index].apartment_base_price
          : 0;
      }

      return Math.ceil(tax);
    }
  };

  const getMinPrice = (rooms) => {
    const prices = [];

    for (let i = 0; i < rooms?.length; i++) {
      const price = getRoomPrice(i);
      if (price) prices.push(price);
    }

    return Math.min(...prices);
  };

  useEffect(() => {
    fetchData();
    fetchReviews();
  }, [id, userInfo]);

  const disabledCheckInDate = (current) =>
    current && current < moment().subtract(1, "d");

  const disabledCheckOutDate = (current) => {
    const checkIn = dates[0];
    const isAfter = checkIn?.isAfter(moment());
    const diffInDays = isAfter ? checkIn?.diff(moment(), "days") : 0;

    return current && current < moment().add(30 + diffInDays, "days");
  };

  return (
    <div style={{ height: "100%" }}>

      <MetaTags>
        <title>{apt?.hotel_name}</title>
        <meta name="description" content={apt?.hotel_desc || "A heaven of luxury & superior living set a usual concept of creating an awesome life system around"} />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>

      <PageHeader isCustom homeLink="/" />

      {loading ? (
        <RdLoader />
      ) : (
        <div style={{ paddingTop: "60px" }}>
          <ImageGrid
            images={images}
            title={apt?.hotel_name}
            share={window.location.href}
            type="A"
          />

          <div className="container-fluid">
            {/* <div className="row">
            <div className="imgGallery grid-container">
              <div className="backIcon">
                <img
                  onClick={() => {
                    history.goBack();
                  }}
                  src={Back}
                  alt=""
                  srcset=""
                />
              </div>

              {apt?.hotel_images?.map((item, index) => {
                if (index <= 2)
                  return (
                    <div key={index} className={`grid-item-${index}`}>
                      <img className="grid-img" src={item.image} alt="" />
                    </div>
                  );
              })}

              <div className="blurIcon futura-12" onClick={handleShow}>
                <span>+{apt?.hotel_images?.length}</span>
              </div>
            </div>
          </div>

          <div
            className="filter-backdrop"
            style={{ display: isModalVisible ? "block" : "none" }}
          >
            <button className="btn text-white" onClick={handleClose}>
              &#10005;
            </button>

            <div className="mt-2">
              <Carousel autoplay={false} infinite>
                {apt?.hotel_images?.map((item, index) => (
                  <div key={index} className="frame">
                    <img
                      className="d-block w-100"
                      alt=""
                      src={item.image}
                      style={{ width: "100%", verticalAlign: "top" }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div> */}

            <div className="row backgroudLightBlue p-3 d-flex flex-column">
              <div className="montserratN900-22 mb-2">
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: 22,
                  lineHeight: "145.9%",
                  color: "#1c2c4a",
                }}
              >{apt?.hotel_name}</h1>
              </div>

              <div className="pb-2">
                <div className="futura-14">
                  <img src={RatingStar} alt="" srcset="" />
                  <span className="p-1">{apt?.hotel_rating?.toFixed(1)}</span>
                  <span className="p-1 futuraBkBtN-14">
                    <u>All reviews</u>
                  </span>
                </div>
              </div>

              <div className="futuraBkBtN-14">
                <p>{apt?.address_line_two}</p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6 hotel-date-pickers">
                <DatePicker
                  className="mb-2"
                  format={dateFormat}
                  disabledDate={disabledCheckInDate}
                  value={dates[0] || moment(userInfo.checkIn, dateFormat)}
                  onChange={(date, dateStr) => {
                    setCalculating(true);

                    setUserInfo({
                      ...userInfo,
                      checkIn: dateStr,
                      checkOut: moment(dateStr, dateFormat).add(30, "days").format(dateFormat),
                    });

                    setDates([
                      date,
                      moment(dateStr, dateFormat).add(30, "days"),
                    ]);
                  }}
                />

                <DatePicker
                  format={dateFormat}
                  disabledDate={disabledCheckOutDate}
                  value={dates[1] || moment(userInfo.checkOut, dateFormat)}
                  onChange={(date, dateStr) => {
                    setCalculating(true);

                    setUserInfo({
                      ...userInfo,
                      checkOut: dateStr,
                      diffInDays:
                        dates[0] && date ? date.diff(dates[0], "days") : 30,
                    });

                    setDates([moment(userInfo.checkIn, dateFormat), date]);
                  }}
                />

                {/* <RangePicker
                  size="small"
                  format="DD-MM-YYYY"
                  defaultValue={[
                    moment(userInfo.checkIn, "YYYY-MM-DD"),
                    moment(userInfo.checkOut, "YYYY-MM-DD"),
                  ]}
                  // value={hackValue || value}
                  disabledDate={disabledDate}
                  onOpenChange={onOpenChange}
                  onChange={(m, str) => {
                    startDate(m, str[0]);
                    endDate(m, str[1]);
                  }}
                /> */}

                <div className="guest mt-3" onClick={onOpen("Guest")}>
                  <img src={User} alt="" srcset="" />
                  <span className="pl-3">{guestNumbers} guest</span>
                </div>
              </div>
              <div className="col-6">
                <div className="text-right">
                  <p className="futuraBkBtN-14 m-0">
                    {apt?.apartments?.length} apt type(s) available
                  </p>

                  <p className="montserratNB-16 m-0  py-1">
                    {/* {separateNumbers(getRoomPrice(apt?.apartments))} */}

                    {calculating ? (
                      <ContentLoader
                        speed={2}
                        width={90}
                        height={19}
                        viewBox="0 0 90 19"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          width="90"
                          height="19"
                        />
                      </ContentLoader>
                    ) : getMinPrice(apt?.apartments) != "Infinity" ? (
                      apt?.hotel_currency +
                      " " +
                      separateNumbers(getMinPrice(apt?.apartments))
                    ) : (
                      <span onClick={() => setPhonePopUp(true)}>
                        Inquire Now
                      </span>
                    )}
                  </p>

                  <div className="montserratNB-16">
                    <Link
                      to={`/hotel-apartment/detail/${id}/rooms`}
                      className="p-1 montserratN400-16 VAprtRoom"
                    >
                      <u>See rooms</u>
                    </Link>

                    <img src={Next} className="ml-1" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <ResponsiveAd />
            <div className="mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="montserratN900-22 pl-3">
                    <p className="">Property Details</p>
                  </div>
                  <div className="futuraBkBtN-14 pl-3">
                    <p className="py-2">
                      {apt.hotel_desc ||
                        "A heaven of luxury & superior living set a usual concept of creating an awesome life system around"}
                    </p>
                  </div>
                  <div className="futuraBkBtN-14 d-flex">
                    <div className="m-2">
                      <img src={PinMark} alt="" />
                    </div>
                    <div className="m-2">
                      <p className="mb-0">{apt?.hotel_pin_location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="montserratN900-22 pl-3">
                <p className="">Apartment Features</p>
              </div>

              <p className="futuraN700-17 pl-3">Most popular facilities</p>

              <ul className="hotel-feature-list p-0">
                {apt?.services?.facilities_array?.map((item, i) => (
                  <li key={i} className="p-1">
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "futuraBkBt",
                      }}
                    >
                      <img src={Tick} alt="" className="mr-2" />
                      {item.split("_").join(" ").split("-").join(" ")}
                    </span>
                  </li>
                ))}
              </ul>

              {/* <ul className="hotel-feature-list p-0">
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Restaurant
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Hot tub / Jacuzzi
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Bar
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                24/7 Front Desk
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Sauna Spa
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Fitness Centre
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Garden
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Terrace
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Airport Shuttle
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Spa &amp; Welness Centre
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Room Service
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Air Conditioning
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Water Park
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Electric Vehicle Charging Station
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Swimming Pool
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Beach
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Smoking Rooms
              </li>
              <li>
                <span>
                  <img src={Tick} alt="" />
                </span>
                Family Rooms
              </li>
            </ul>*/}

              <div className="mt-4">
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                >
                  {apt?.services?.hotel_amenities_media?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Media and technology"
                      key="1"
                    >
                      <ul className="hotel-feature-list p-0">
                        {apt?.services?.hotel_amenities_media?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {apt?.services?.hotel_amenities_food?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Food and drink"
                      key="2"
                    >
                      <ul className="hotel-feature-list p-0">
                        {apt?.services?.hotel_amenities_food?.map((item, i) => (
                          <li key={i} className="p-1">
                            <span style={{ textTransform: "capitalize" }}>
                              <img src={Tick} alt="" className="mr-2" />
                              {item}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  )}

                  {apt?.services?.hotel_amenities_services?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Services and extra"
                      key="3"
                    >
                      <ul className="hotel-feature-list p-0">
                        {apt?.services?.hotel_amenities_services?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {apt?.services?.hotel_amenities_outdoor?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Outdor and view"
                      key="4"
                    >
                      <ul className="hotel-feature-list p-0">
                        {apt?.services?.hotel_amenities_outdoor?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {apt?.services?.hotel_amenities_entertainment?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Entertainment and family services"
                      key="5"
                    >
                      <ul className="hotel-feature-list p-0">
                        {apt?.services?.hotel_amenities_entertainment?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  <Panel className="futuraN700-17" header="Polices" key="6">
                    <div className="futuraN700-14 pl-3">
                      <div className="futuraBkBtN-14">
                        <p className="mb-0">
                          Check-in: From{" "}
                          {moment(
                            apt?.policies?.check_in_from,
                            "HH:mm:ss"
                          ).format("HH:mm A")}
                        </p>
                        <p className="mb-0">
                          Advance cancellation of{" "}
                          {apt?.policies?.advance_cancel} day(s)
                        </p>
                        <p className="mb-0">
                          {apt?.policies?.live_booking === "yes"
                            ? "Live booking"
                            : "Pay manually at the property"}
                        </p>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="mt-5 float-left w-100">
              <div className="montserratN900-22 pl-3">
                <p className="">Reviews</p>
              </div>
              <WingBlank>
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={10}
                  slideWidth={0.9}
                  infinite
                  dots={false}
                >
                  {reviews?.map((item, i) => (
                    <div className="card" key={i}>
                      <div className="card-body">
                        {/* <div className="row">
                        <div className="col-3">
                          <div className="icon icon-50">
                            <figure
                              className="background"
                              style={{ backgroundImage: `url(${UploadProfile})` }}
                            >
                              <img
                                src={UploadProfile}
                                alt=""
                                style={{ display: "none" }}
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="col-9">
                          <p className="futuraN700-18 mb-0">
                            Mary, <span>32</span>
                          </p>
                        </div>
                      </div> */}

                        <div className="futuraBkBtN-14 p-3">
                          <p>{item.review}</p>
                        </div>
                      </div>
                    </div>
                  ))}

                  {!reviews?.length && (
                    <div className="card">
                      <div className="card-body">
                        <div className="futuraBkBtN-14 p-3">
                          <p>No reviews found for this listing</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Carousel>
              </WingBlank>

              {reviews.length ? (
                <div className="pt-2 futuraN700-14">
                  <Button type="button" className="bookN">
                    Show all reviews
                  </Button>
                </div>
              ) : null}
            </div>

            {/* map */}
            <div
              className="map-box-new mt-5 float-left w-100"
              style={{ paddingBottom: "100px" }}
            >
              <div className="montserratN900-22 pl-3 mb-1">
                <p className="">Location</p>
              </div>

              <div className="futuraBkBtN-14 pl-3 mb-3">
                <p>{apt.hotel_pin_location}</p>
              </div>

              <LeafletMap position={position} />

              {/* <GoogleMap
              lat={apt?.hotel_latitude && parseInt(apt?.hotel_latitude)}
              lng={apt?.hotel_longitude && parseInt(apt?.hotel_longitude)}
            /> */}
            </div>

            {/* smart listing */}
            {/* <div className="mt-5 float-left pb60 w-100">
            <div className="montserratN900-22 pl-3">
              <p className="">Smart Listings</p>
            </div>
            <WingBlank>
              <Carousel
                className="space-carousel"
                frameOverflow="visible"
                cellSpacing={10}
                slideWidth={1}
                // autoplay
                infinite
                dots={false}
              >
                {smartListings.map((smartListing, i) => (
                  <div key={i} className="col-12">
                    <div className="card">
                      <div className="card-head"></div>
                      <div className="card-bod">
                        {/* <Carousel>
                        {/* {smartListing?.room_images.map((img, i) => (
                        <img
                          key={i}
                          className="d-block w-100 carousalImg"
                          src={BannerImage}
                          // src={img}
                          alt={smartListing?.custom_room_name}
                        />
                        {/* ))}
                        {/* </Carousel>
                      </div>
                      <Link to="/">
                        <div className="pt-3">
                          <div className="futuraBold18 pl-4">
                            <p className="">{smartListing?.custom_room_name}</p>
                          </div>
                          <div className="montserratNB-16 pl-4">
                            <p className="text-black">
                              {smartListing?.currency} {smartListing?.rent}
                            </p>
                          </div>
                          <div className="futuraBkBtN-14 pl-4">
                            <p className="text-gray">{smartListing?.Area}</p>
                          </div>
                        </div>
                        <div className="pl-4">
                          <ul className="cardItemUl d-flex">
                            <li className="cardItemsList">
                              <img src={UserIcon} alt="" />
                              <span className="p-2 text-black">5</span>
                            </li>
                            <li className="cardItemsList">
                              <img src={DoorIcon} alt="" />
                              <span className="p-2 text-black">1</span>
                            </li>
                            <li className="cardItemsList">
                              <img src={BedIcon} alt="" />
                              <span className="p-2 text-black">2</span>
                            </li>
                            <li className="cardItemsList">
                              <img src={BathIcon} alt="" />
                              <span className="p-2 text-black">1</span>
                            </li>
                          </ul>
                        </div>
                        <div className="futuraN700-14 pl-4 py-3">
                          <Link
                            to={`/flatshare/list/${smartListing?.custom_room_name}`}
                            className="bookN"
                          >
                            Book now
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </Carousel>
            </WingBlank>
          </div>
         */}
          </div>
          <WingBlank>
            <Modal
              popup
              visible={guestPopUp}
              onClose={onClose("Guest")}
              animationType="slide-up"
              // afterClose={() => { alert('afterClose'); }}
            >
              <div className="container">
                <div className="row">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                    <div className="row">
                      <div className="col-10">
                        <p>Select Guests</p>
                      </div>
                      <div className="col-2">
                        <span onClick={onClose("Guest")}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex futuraBkBtN-14 pl-3">
                            Number of occupants
                            <div className="counter pl-3">
                              <img
                                id="decrement"
                                className="decrement button-counter"
                                src={Minus}
                                alt=""
                                onClick={guestDecrement}
                              />
                              <span id="tottleCount" className="pl-2 pr-2">
                                {guestNumbers}
                              </span>
                              <img
                                id="increment"
                                className="increment button-counter"
                                src={Plus}
                                alt=""
                                onClick={guestIncrement}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex justify-content-between">
                <div className="form-group mt-2 mb-4">
                  <Button className="w-100 filter-Reset futuraN700-14">
                    Reset
                  </Button>
                </div>
                <div className="form-group mt-2 mb-4">
                  <Button className="w-100 filter-Apply futuraN700-14">
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </WingBlank>


          <Modal
            popup
            visible={emailPopUp}
            onClose={() => setEmailPopUp(false)}
            animationType="slide-up"
          >
            <div class="container">
              <div className="">
                <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                  <div className="row">
                    <div className="col-10 text-left">
                      <p>Email</p>
                    </div>

                    <div className="col-2">
                      <span onClick={() => setEmailPopUp(false)}>
                        <img src={Cross} alt="" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your name </label>

                  <input
                    type="name"
                    name="userName"
                    className="rd-custom-input"
                    placeholder="Name"
                    value={contact.name}
                    onChange={(e) =>
                      setContact({ ...contact, name: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your email </label>

                  <input
                    type="email"
                    name="userEmail"
                    className="rd-custom-input"
                    placeholder="username@email.com"
                    value={contact.email}
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your phone </label>

                  <input
                    type="phone"
                    name="userPhone"
                    className="rd-custom-input"
                    placeholder="+971 xxx xxx xxx"
                    maxLength={32}
                    value={contact.number}
                    onChange={(e) =>
                      setContact({ ...contact, number: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2 mb-3">
                  <label className="futura-14 text-xyz my-2">
                    Your message{" "}
                  </label>

                  <textarea
                    className="rd-custom-textarea"
                    rows="3"
                    cols="48"
                    onChange={(e) =>
                      setContact({ ...contact, message: e.target.value })
                    }
                  >
                    {contact.message}
                  </textarea>
                </div>

                <div className="form-group mt-2 mb-4">
                  <Button
                    className="w-100 bookN futuraN700-14 EmailHotelApartMsg"
                    onClick={() => {
                      sendEmail();
                      collectLead("e");
                    }}
                  >
                    Send Email
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal visible={phonePopUp}>
            <div className="p-4 text-center">
              <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
              Would you like to contact the host on WhatsApp?
              </p>

              <div className="d-flex justify-content-center align-items-center">
                <Button
                  className="bookN-s mr-3 CallHotelApartClick"
                  onClick={() => setPhonePopUp(false)}
                >
                  {/* <a href={`tel:${hotel?.alternate_phone_number}`}>Yes</a> */}
                  {/* <a href={`tel:${'+971585625660'}`}
                  onClick={() => collectLead("p")}
                  >Yes</a> */}
                   <a href={`whatsapp://send?phone=+971523971907&text=I'm interested to know about ${apt?.hotel_name}`} 
                  class="bla"
                  onClick={() => collectLead("p")}
                  >Yes</a>
                </Button>

                <Button
                  className="bookN-s"
                  onClick={() => setPhonePopUp(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>

          <div className="bottom-navigation-wrap py-3" style={{ zIndex: 9999 }}>
            <div className="pl-2 pr-1 w-100">
              {/* <a href={`tel:${hotel?.alternate_phone_number}`}></a> */}
              <a href="#">
                <Button
                  className="bookN-s futuraN700-14 CallHotelApart"
                  style={{ width: "100%" }}
                  onClick={() => setPhonePopUp(true)}
                >
                  <img src={PhoneCall} alt="" />
                  <span className="p-1">WhatsApp</span>
                </Button>
              </a>
            </div>

            <div className="pr-2 pl-1 w-100">
              <a href="#">
                <Button
                  className="bookN-s futuraN700-14 EmailHotelApart"
                  style={{ width: "100%" }}
                  onClick={() => setEmailPopUp(true)}
                >
                  <img src={Email} alt="" />
                  <span className="p-1">Email</span>
                </Button>
              </a>
            </div>

            {/* <div className="futuraN700-14">
              <Link
                id="hotel-room-mob-view-rooms"
                to={`/hotel/detail/${id}/rooms`}
              >
                <Button className="bookN w-100 VRooms">View rooms</Button>
              </Link>

              <Button
                className="bookN w-100"
                onClick={() => setContactPopUp(true)}
              >
                Contact
              </Button>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
