import React from "react";
import { useHistory } from "react-router";
import CoLivingIcon from "../icons/postColiving.svg";
// import BackIcon from "../icons/back.svg";
import { Button } from "@material-ui/core";
import "./style/thankYou.scss";
import { Link } from "react-router-dom";

const ThankYouPage = () => {
  const history = useHistory();

  return (
    <div className="thank-you">
      {/* <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={BackIcon}
              alt="back_Arrow"
              onClick={() => history.push("/")}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Go to home</div>
          </div>
        </div>
      </div> */}

      <div className="thank-you__body">
        <img src={CoLivingIcon} alt="Thank You - RoomDaddy" />

        <div className="thank-you__body-content">
          <h1>Thank you for hosting with us</h1>

          <div>
            <Button
              onClick={() => history.push("/place-an-ad")}
              className="bookN"
            >
              Post another ad
            </Button>

            <Button
              onClick={() => history.push("/my-listings")}
              className="bookN"
            >
              View your listings
            </Button>
          </div>
        </div>

        <div className="thank-you__body-home">
          <Link
            to="/"
            className="text-darkBlue"
            style={{ fontFamily: "futuraMdBt" }}
          >
            Go to home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
