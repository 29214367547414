import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
// Amenities
import NaturalLight from "../../icons/naturalLights.svg";
import Bath from "../../icons/bath.svg";
import Window from "../../icons/window.svg";
import Fridge from "../../icons/fridge.svg";
import Tv from "../../icons/tv.svg";
import Gym from "../../icons/fitness.svg";
import FreeWifi from "../../icons/internet.svg";
import Parking from "../../icons/parkingAm.svg";
import Lake from "../../icons/lake.svg";
import Park from "../../icons/park.svg";
import Elevator from "../../icons/elevator.svg";
import Laundry from "../../icons/laundry.svg";
import Balcony from "../../icons/balcony.svg";
import Wheelchair from "../../icons/wheelchair.svg";
import Terrace from "../../icons/terrace.svg";
import DishWasher from "../../icons/dishwasher.svg";
import Pool from "../../icons/pool.svg";
import Landscape from "../../icons/landscape.svg";
import Squash from "../../icons/tennis.svg";
import TableTennis from "../../icons/table-tennis.svg";
import TennisCourt from "../../icons/tennis-court.svg";
import CommonRoom from "../../icons/relax.svg";
import Smoke from "../../icons/smoking.svg";
import Couple from "../../icons/couple.svg";
import Pets from "../../icons/pets.svg";
import Children from "../../icons/toys.svg";
import Male from "../../icons/mars.svg";
import Female from "../../icons/femenine.svg";
import Mix from "../../icons/mixG.svg";
import Shared from "../../icons/maleFemale.svg";

// Recent
import Plus from "../../icons/plus.svg";
import Minus from "../../icons/minus.svg";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Button } from "@material-ui/core";
import { message,Select } from "antd";

const { Option } = Select;


export default function StepTwo({ nextStep ,stepTwoInfo,to }) {
    const { userInfo,editHomeDetail,editHomeStepsDetail, setEditHomeStepsDetail } = useContext(AppContext);
    const [secondStepInfo, setSecondStepInfo] = useState({
        hotel_facilities: [],amenities:[], house_rules: [], number_guests: 1,
        ad_type: "entire-accomodation", bathroom_quantity: 1,hotel_allow_pets:"no",
        smoking:"non-smoking",quantity:1,type:"",livingroom_quantity:0,size: "",hotel_parking:0
      });

    function sendFormData() {
        let data = new FormData();    
        for(let i in secondStepInfo ) {
            data.append(i, secondStepInfo[i]);
        }
        data.append("fld_tanent", userInfo.id);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
         nextStep(2);
         setEditHomeStepsDetail({...editHomeStepsDetail,secondStepInfo});
    
    }

    useEffect(() => {
        if(editHomeDetail.id) {
            setSecondStepInfo({
                amenities:editHomeDetail.apartments[0].apartment_amenities,
                hotel_facilities:editHomeDetail.services.facilities_array,
                number_guests:editHomeDetail.apartments[0].apartment_rooms[0].no_of_guests,
                bathroom_quantity:editHomeDetail.apartments[0].number_bathrooms_apartments ?editHomeDetail.apartments[0].number_bathrooms_apartments : 1,
                hotel_allow_pets:editHomeDetail.policies.allow_pets ? editHomeDetail.policies.allow_pets : "no",
                smoking:editHomeDetail.apartments[0].apartment_smoking_option_select ?editHomeDetail.apartments[0].apartment_smoking_option_select :"non-smoking",
                quantity:editHomeDetail.number_apartments_this_type ?editHomeDetail.number_apartments_this_type : 1,
                type:editHomeDetail.apartments[0].apartment_type,
                livingroom_quantity:editHomeDetail.apartments[0].number_living_rooms_apartments,
                size:editHomeDetail.apartments[0].apartment_size,
                hotel_parking:editHomeDetail.services.parking,
            
            });
        }
    }, [editHomeDetail]);

    function updateSecondForm(property, value) {
        if([property] == "amenities" || [property] == "hotel_facilities" ) {
            if(secondStepInfo[property].includes(value)) {
                const index = secondStepInfo[property].indexOf(value);
                secondStepInfo[property].splice(index, 1);
                 setSecondStepInfo({...secondStepInfo, [property]: secondStepInfo[property]});
            } else {
                secondStepInfo[property].push(value);
                setSecondStepInfo({
                    ...secondStepInfo,
                    [property]: secondStepInfo[property],
                  });
            }
        } else if([property] == "number_guests" || [property] == "bathroom_quantity" ) {
            if(value == "add") {
                setSecondStepInfo({...secondStepInfo, [property]: secondStepInfo[property]+1});
            } else if(value == "sub") {
                if(secondStepInfo[property] !== 0) {
                    setSecondStepInfo({...secondStepInfo, [property]: secondStepInfo[property]-1});
                }
            }
        } else {
            setSecondStepInfo({...secondStepInfo, [property]: value});
        }
    }
    return (
        <div className="py-3 px-2">
           
          <div className="container">
            <div className="row">
              <div className="col pb-4">
              <div className="form-group">
                    <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                        What do you want to list?
                    </label>
                    <div className="mr-3"></div>
            
                    <div className="form-control inputBorder">
                            <Select
                                // showSearch
                                style={{ width: "100%" }}
                                placeholder="No. of Rooms"
                                onChange={ value => updateSecondForm("type", value)}
                                value={secondStepInfo.type }
                                // onSearch={val=> onSearch("location", val)}
                                // filterOption={ (input, option) =>
                                //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }
                            >
                                    <option value="0"> Studio </option>
                                    <option value="1"> 1 Bedroom</option>
                                    <option value="2"> 2 Bedroom</option>
                                    <option value="3"> 3 Bedroom</option>
                                    <option value="4"> 4 Bedroom</option>
                                    <option value="5"> 5 Bedroom</option>
                                    <option value="6"> 6 Bedroom</option>
                                    <option value="7"> 7 Bedroom</option>
                                    <option value="8"> 8 Bedroom or more</option>
                            </Select>
                    </div>
                </div>
              <div className="form-group ">
                    <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">No. of Living Room*</label>
                    <div className="form-control inputBorder">
                        <Select
                            // showSearch
                            style={{ width: "100%" }}
                            placeholder="No. of Living Room"
                            onChange={ value => updateSecondForm("livingroom_quantity", value)}
                            value={secondStepInfo.livingroom_quantity }
                            // onSearch={val=> onSearch("location", val)}
                            // filterOption={ (input, option) =>
                            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                        >
                                <option value="0"> 0 </option>
                                <option value="1"> 1 </option>
                                <option value="2"> 2 </option>
                        </Select>
                    </div>
                </div>
                <div>
              <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              No. of Washrooms*
              </h6>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="futuraBkBtN-14 m-auto">Washrooms</p>
                </div>
                <div className="d-flex">
                  <img
                    src={Minus}
                    alt="minus_icon"
                    onClick={() => updateSecondForm("bathroom_quantity", "sub")}
                  />
                  <div className="count">{secondStepInfo.bathroom_quantity}</div>
                  <img
                    src={Plus}
                    alt="plus_icon"
                    onClick={() => updateSecondForm("bathroom_quantity", "add")}
                  />
                </div>
              </div>
            
            </div>
            <div className="form-group ">
                <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">Property size in Feet*</label>
                
                    <input type="number"
                        onChange={ e=> updateSecondForm("size", e.target.value)}
                        className="form-control inputBorder"
                        value={secondStepInfo.size}
                    />
               
            </div>
            <div className="form-group ">
                                <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">No. of Parking*</label>
                                <div className="form-control inputBorder">
                                    <Select
                                        // showSearch
                                        style={{ width: "100%" }}
                                        placeholder="No. of Parking"
                                        onChange={ value => updateSecondForm("hotel_parking", value)}
                                        value={ secondStepInfo.hotel_parking}
                                        // onSearch={val=> onSearch("location", val)}
                                        // filterOption={ (input, option) =>
                                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                    >  <Option value="0">No parking</Option>
                                        <Option value="1">1 parking</Option>
                                        <Option value="2">2 parking</Option>
                                        <Option value="3">3 parking</Option>
                                        <Option value="4">more then 3</Option>
                                    </Select>
                                </div>
                            </div>
                            <div>
              <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
             Accomodates*
              </h6>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="futuraBkBtN-14 m-auto">Number of Occupants</p>
                </div>
                <div className="d-flex">
                  <img
                    src={Minus}
                    alt="minus_icon"
                    onClick={() => updateSecondForm("number_guests", "sub")}
                  />
                  <div className="count">{secondStepInfo.number_guests}</div>
                  <img
                    src={Plus}
                    alt="plus_icon"
                    onClick={() => updateSecondForm("number_guests", "add")}
                  />
                </div>
              </div>
            
            </div>
            <div className="amenities_check">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                House amenities
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="wifi "
                      checked={secondStepInfo.amenities?.includes("wifi") ? "checked" : false} onChange={() => updateSecondForm("amenities", "wifi")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={FreeWifi}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Wifi
                      </label>
                    </div>
                  </li>
                  
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      name="elevator "
                      checked={secondStepInfo.amenities?.includes("elevator") ? "checked" : false} onChange={() => updateSecondForm("amenities", "elevator")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Elevator}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Elevator
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      name="washing_machine "
                      checked={secondStepInfo.amenities?.includes("washing_machine") ? "checked" : false} onChange={() => updateSecondForm("amenities", "washing_machine")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Laundry}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Washing machine
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="balcony "
                      checked={secondStepInfo.amenities?.includes("balcony") ? "checked" : false} onChange={() => updateSecondForm("amenities", "balcony")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Balcony}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Common Balcony
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="dishwasher "
                      checked={secondStepInfo.amenities?.includes("dishwasher") ? "checked" : false} onChange={() => updateSecondForm("amenities", "dishwasher")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={DishWasher}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Dishwasher
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="wheelcheer_friendly"
                      checked={secondStepInfo.amenities?.includes("wheelchair_friendly") ? "checked" : false} onChange={() => updateSecondForm("amenities", "wheelchair_friendly")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Wheelchair}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Wheelchair Friendly
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="terrace"
                      checked={secondStepInfo.amenities?.includes("terrace") ? "checked" : false} onChange={() => updateSecondForm("amenities", "terrace")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Terrace}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Terrace
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="purpose-error1 555555"></div>
              </div>
            </div>
            <div className="amenities_check house_rules">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Outdoor Amenities
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="gym"
                      checked={
                        secondStepInfo.hotel_facilities.includes("gym")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("hotel_facilities", "gym")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Gym}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Gym
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="pool"
                      checked={
                        secondStepInfo.hotel_facilities.includes("Pool")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("hotel_facilities", "Pool")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Pool}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Pool
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="garden"
                      checked={
                        secondStepInfo.hotel_facilities.includes("garden")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("hotel_facilities", "garden")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Park}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Garden
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="table_tennis"
                      checked={
                        secondStepInfo.hotel_facilities.includes("table_tennis")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("hotel_facilities", "table_tennis")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={TableTennis}
                        alt="table_tennis"
                      />
                      <label for="s-option" className="label_active">
                        Table Tennis
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="squash"
                      checked={
                        secondStepInfo.hotel_facilities.includes("squash")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("hotel_facilities", "squash")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Squash}
                        alt="squash"
                      />
                      <label for="s-option" className="label_active">
                        Squash
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      name="lawn_tennis"
                      checked={
                        secondStepInfo.hotel_facilities.includes("lawn_tennis")
                          ? "checked"
                          : false
                      }
                      onChange={() =>
                        updateSecondForm("hotel_facilities", "lawn_tennis")
                      }
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={TennisCourt}
                        alt="table_tennis"
                      />
                      <label for="s-option" className="label_active">
                        Lawn Tennis
                      </label>
                    </div>
                  </li>
                </ul>
                <div className="purpose-error1 555555"></div>
              </div>
            </div>
            <div className="amenities_check house_rules">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Are there any house rules?
              </label>
              <div className="get_started_radio">
                <ul className="container_ul">
                  <li>
                    <input
                      type="checkbox"
                      id="f-option"
                      className="disable_type"
                      name="smoking"
                      checked={secondStepInfo.smoking === "smoking" ||secondStepInfo.smoking === "both" ? "checked" : false} onChange={() => updateSecondForm("smoking", "both")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Smoke}
                        alt="Purchasing_hover"
                      />
                      <label for="f-option" className="label_active">
                        Smoker friendly
                      </label>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="s-option"
                      className="disable_type"
                      //name="house_rules_pet"
                      checked={secondStepInfo.hotel_allow_pets === "yes" ? "checked" : false} name="hotel_allow_pets" onChange={() => updateSecondForm("hotel_allow_pets", "yes")}
                    />
                    <div className="check d-flex p-2 filter-Am">
                      <img
                        className="radio_active"
                        src={Pets}
                        alt="Refinancing_hover"
                      />
                      <label for="s-option" className="label_active">
                        Pet friendly
                      </label>
                    </div>
                  </li>
                  
                </ul>
                <div class="purpose-error1 555555"></div>
              </div>
            </div>
              </div>
            </div>
          </div>
          <Button
            className="bookN futuraN700-14 w-100"
            type="button"
            onClick={sendFormData}
          >
            Continue
          </Button>
          {/* </form> */}
        </div>
      );
}