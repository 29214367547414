import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import moment from "moment";
import app from "../utils/appConfig";
// import { getUser } from "../../utils/functions";
import BackIcon from "../icons/back.svg";
import CheckIcon from "../icons/check.svg";
import MobileNav from "../_newComponents/MobileNav";
import "./style/serviceRequestDetail.scss";

const dateFormat = "YYYY-MM-DD";

const ServiceRequestDetail = () => {
  // const user = getUser();

  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [service, setService] = useState({});

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/get-complaint-detail/${id}`)
      .then((res) => {
        const data = res.data.data[0];

        // if (data.tenant_id === user.id) {
        //   console.log(data.tenant_id, user.id);
        //   history.push("/");
        // }

        setService(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id /* , user */]);

  const getDate = (date) => moment(date, dateFormat).format("D MMM");

  return (
    <div className="sr-detail">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              src={BackIcon}
              alt="Back Arrow - RoomDaddy"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push("/my_booking");
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">History</div>
          </div>
        </div>
      </div>

      {loading ? null : (
        <div className="sr-detail__body">
          <div className="sr-detail__title">
            <h3>Service request {service?.complaint_id}</h3>
          </div>

          <div className="sr-detail__info sr-detail__info--active">
            <div className="sr-detail__info-date">
              <p>{getDate(service?.fld_created_date)}</p>
            </div>

            <div className="sr-detail__info-icon">
              <div className="line">&nbsp;</div>

              <div className="sr-detail__info-icon-circle">
                <img src={CheckIcon} alt="Service Info - RoomDaddy" />
              </div>
            </div>

            <div className="sr-detail__info-status rd-custom-card">
              <p>Submitted</p>
            </div>
          </div>

          <div
            className={`sr-detail__info ${
              service?.fld_is_read && "sr-detail__info--active"
            }`}
          >
            <div className="sr-detail__info-date">
              <p>&nbsp;</p>
            </div>

            <div className="sr-detail__info-icon">
              <div className="line">&nbsp;</div>

              <div className="sr-detail__info-icon-circle">
                {service?.fld_is_read ? (
                  <img src={CheckIcon} alt="Service Info - RoomDaddy" />
                ) : null}
              </div>
            </div>

            <div className="sr-detail__info-status rd-custom-card">
              <p>Reviewed</p>
            </div>
          </div>

          {service?.fld_is_read ? (
            <div
              className={`sr-detail__info ${
                service?.assigned_to && "sr-detail__info--active"
              }`}
            >
              <div className="sr-detail__info-date">
                <p>
                  {service?.assigned_to ? getDate(service?.assigned_on) : ""}
                </p>
              </div>

              <div className="sr-detail__info-icon">
                <div className="line">&nbsp;</div>

                <div className="sr-detail__info-icon-circle">
                  {service?.assigned_to ? (
                    <img src={CheckIcon} alt="Service Info - RoomDaddy" />
                  ) : null}
                </div>
              </div>

              <div className="sr-detail__info-status rd-custom-card">
                <p>
                  Assigned{" "}
                  {service?.assigned_to && `To ${service?.Assign_name?.trim()}`}
                </p>
              </div>
            </div>
          ) : null}

          {service?.assigned_to ? (
            <div
              className={`sr-detail__info ${
                service?.is_accepted && "sr-detail__info--active"
              }`}
            >
              <div className="sr-detail__info-date">
                <p>&nbsp;</p>
              </div>

              <div className="sr-detail__info-icon">
                <div className="line">&nbsp;</div>

                <div className="sr-detail__info-icon-circle">
                  {service?.is_accepted ? (
                    <img src={CheckIcon} alt="Service Info - RoomDaddy" />
                  ) : null}
                </div>
              </div>

              <div className="sr-detail__info-status rd-custom-card">
                <p>In progress</p>
              </div>
            </div>
          ) : null}

          {service?.is_accepted ? (
            <div
              className={`sr-detail__info ${
                service?.fld_is_resolved_by && "sr-detail__info--active"
              }`}
            >
              <div className="sr-detail__info-date">
                <p>
                  {service?.fld_is_resolved_by
                    ? getDate(service?.is_closed_date)
                    : null}
                </p>
              </div>

              <div className="sr-detail__info-icon">
                <div className="sr-detail__info-icon-circle">
                  {service?.fld_is_resolved_by ? (
                    <img src={CheckIcon} alt="Service Info - RoomDaddy" />
                  ) : null}
                </div>
              </div>

              <div className="sr-detail__info-status rd-custom-card">
                <p className={service?.remarks && "mb-2"}>Resolved</p>
                {service?.remarks ? <span>{service?.remarks}</span> : null}
              </div>
            </div>
          ) : null}
        </div>
      )}

      <MobileNav />
    </div>
  );
};

export default ServiceRequestDetail;
