import React, { useState } from "react";
import { Modal, Button, Accordion, Card } from "react-bootstrap";

export default function ModalProfile(props) {
  const [showModal, setShow] = useState(false);
  const [showNumber, setShowNumber] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [firstName, setFirstName] = useState({ name: props.name });
  const [emailAddress, setEmailAddress] = useState({ email: props.email });
  const [cellPhone, setCellPhone] = useState({ phone: props.contactNumber });

  return (
    <>
      <Button
        className="mt-4 btn btn-info btn-block btn-lg"
        onClick={handleShow}
      >
        {props.modalCallButton}
      </Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mt-4 text-center">
            <div className="icon icon-100 position-relative">
              <figure
                className="background"
                style={{ backgroundImage: `url(${props.img})` }}
              >
                <img src={props.img} alt="" style={{ display: "none" }} />
              </figure>
            </div>
          </div>
          <br />
          <h6>{props.subTitle}</h6>
          <div className="text-secondary">
            <ul class="text-secondary small lists">
              <li>
                Name : <span class="listing-address-span-m ">{props.name}</span>
              </li>
              <li>
                Gender :{" "}
                <span class="listing-address-span-m ">{props.gender}</span>
              </li>
              <li>
                Languages :{" "}
                <span class="listing-address-span-Cm ">{props.languages}</span>
              </li>
            </ul>
          </div>
          {/* <Button className="btn-info mb-4" 
              onClick={()=> setShowNumber(!showNumber)}
          >
              {showNumber ? `${props.contactText}` : props.contactNumber}
          </Button> */}
          <a
            href={
              showNumber ? `${props.contactText}` : `tel:${props.contactNumber}`
            }
            onClick={() => setShowNumber(!showNumber)}
            className="btn-info btn btn-primary mb-3 Contact"
          >
            {showNumber ? `${props.contactText}` : props.contactNumber}
          </a>

          <Accordion defaultActiveKey="">
            <Card className="mb-3">
              <Accordion.Toggle
                as={Card.Header}
                className="card-header btn-info btn btn-primary"
                eventKey="0"
              >
                {props.accordionTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="col-12 col-md-6">
                    <div
                      className={
                        firstName != 0
                          ? "form-group floating-form-group active"
                          : "form-group floating-form-group"
                      }
                    >
                      <input
                        type="text"
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        className="form-control floating-input"
                        value={firstName.name}
                      />
                      <label className="floating-label">Your name</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className={
                        emailAddress != 0
                          ? "form-group floating-form-group active"
                          : "form-group floating-form-group"
                      }
                    >
                      <input
                        type="email"
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                        className="form-control floating-input"
                        value={emailAddress.email}
                      />
                      <label className="floating-label">Email address</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className={
                        cellPhone != 0
                          ? "form-group floating-form-group active"
                          : "form-group floating-form-group"
                      }
                    >
                      <input
                        type="tel"
                        id="cell"
                        onChange={(e) => {
                          setCellPhone(e.target.value);
                        }}
                        className="form-control floating-input"
                        name="phone"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        required
                        value={cellPhone.phone}
                      />
                      <label className="floating-label">Cell Number</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <textarea
                      class="form-control"
                      name={props.textareName}
                      rows={props.textareRow}
                      placeholder={props.textarePlaceholder}
                    >
                      {props.textareText}
                    </textarea>
                  </div>
                  <br />
                  <Button className="mb-4 btn btn-info btn-block btn-lg btn btn-primary">
                    {props.accordionButton}
                  </Button>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
}
