import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import OneSignal from "react-onesignal";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import moment from "moment";
import { Carousel, Radio, Space } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import {
  getLsValue,
  getUser,
  removeLsValue,
  separateNumbers,
} from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import Modal from "../_newComponents/Modal";
import ImageGrid from "../_newComponents/ImageGrid";
import NoImage from "../images/no-image.png";
import Back from "../icons/back.svg";
import RatingStar from "../icons/rating.svg";
import Bath from "../icons/bath.svg";
import Smoke from "../icons/smoking.svg";
import Cross from "../icons/cross.svg";
import "./style/checkout.scss";
import { CACHE__H_ROOMS_CART } from "../data/cacheKeys";

const { Group } = Radio;

const CheckoutPage = () => {
  const authUser = getUser();
  const history = useHistory();
  const {
    addons,
    setAddons,
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
  } = useContext(AppContext);

  if (
    selectedRooms &&
    Object.keys(selectedRooms).length === 0 &&
    Object.getPrototypeOf(selectedRooms) === Object.prototype
  )
    history.push("/");

  const [paymentPortal, setPaymentPortal] = useState("");
  const [dino, setDino] = useState(0);
  const [user, setUser] = useState({});
  const [steps, setSteps] = useState(1);
  const [finished, setFinished] = useState(false);
  const [manual, setManual] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [done, setDone] = useState(false);

  const [show, setShow] = useState(false);

  const [formName, setFormName] = useState(user.name);
  const [formPhNumber, setFormPhNumber] = useState(user.phNumber);
  const [formEmail, setFormEmail] = useState(user.email);
  const [formGuestName, setFormGuestName] = useState("");
  const [formGuestPhNumber, setFormGuestPhNumber] = useState("");

  const [isWork, setIsWork] = useState();
  const [guest, setGuest] = useState();

  const [images, setImages] = useState([]);
  const [hotel, setHotel] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hotelId, setHotelId] = useState(
    selectedRooms?.[Object.keys(selectedRooms)?.[0]]?.hotelId
  );

  // useEffect(() => {
  // if (Object.getPrototypeOf(selectedRooms) === Object.prototype) {
  //   if (Object.values(selectedRooms).length)
  //     setHotelId(Object.values(selectedRooms)[0].hotelId);
  //   else history.push("/");
  // } else {
  //   if (selectedRooms.length) setHotelId(selectedRooms[0].hotelId);
  //   else history.push("/");
  // }
  // const cart = getLsValue(CACHE__H_ROOMS_CART);
  // if (cart) {
  //   setHotelId(
  //     cart.selectedRooms?.[Object.keys(cart.selectedRooms)?.[0]]?.hotelId
  //   );
  //   setSelectedRooms(cart.selectedRooms);
  //   setUserInfo(cart.userInfo);
  //   setAddons(cart.state);
  // } else {
  //   history.push("/");
  // }
  // }, []);

  const handleClose = () => setIsModalVisible(false);
  const handleShow = () => setIsModalVisible(true);

  const fetchData = () => {
    axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_hotel_data/${hotelId}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        setHotel(res.data[0]);

        userInfo.policyId = res.data[0].policies.id;
        const imgArr = [];

        res.data[0].hotel_images.forEach((image) => imgArr.push(image.image));
        setImages(imgArr);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [hotelId]);

  useEffect(() => {
    if (!manual && dino) document.getElementById("dinoS").click();
  }, [manual, dino]);

  // useEffect(() => {
  //   if (authUser?.id)
  //     axios
  //       .get(`${app.baseUrl}/api/get-profile?user_id=${authUser?.id}`)
  //       .then((res) => {
  //         let data = res.data.data;

  //         setUser({
  //           name: data.fld_name,
  //           email: data.email,
  //           phNumber: data.fld_number,
  //         });
  //       })
  //       .catch((err) => console.log(err));
  // }, []);

  // const prefixSelector = (
  //   <Item name="prefix">
  //     <Select style={{ width: 70 }}>
  //       <Option value="86">+86</Option>
  //       <Option value="87">+87</Option>
  //     </Select>
  //   </Item>
  // );

  function getTotalPrice() {
    let price = 0;

    Object.keys(selectedRooms).map((elem) => {
      price += selectedRooms[elem].totalAmmount;
    });

    if (addons.full_board)
      price += parseInt(addons.full_board_price) * addons.numberOfPersons;
    if (addons.half_board)
      price += parseInt(addons.half_board_price) * addons.numberOfPersons;
    if (addons.breakfast) price += parseInt(addons.breakfast_price);
    if (addons.internet) price += parseInt(addons.internet_price);
    if (addons.parking) price += parseInt(addons.parking_price);
    if (addons.pets) price += parseInt(addons.pets_price);

    return separateNumbers(price);
  }

  const roomCheckOut = (booking_type) => {
    let rooms = [];
    let roomsprices = [];
    let roomsquantity = [];
    let selectedRoomid = [];
    let roomsids = "";
    let roomtype = "";
    let currency = "";
    let hotelName = "";
    let hotelId = "";

    for (const key in selectedRooms) {
      rooms.push(selectedRooms[key].roomName);
      roomsquantity.push(selectedRooms[key].numberOfRooms);
      roomsids += `${selectedRooms[key].numberOfRooms} x ${selectedRooms[key].roomName}`;
      roomsprices.push(selectedRooms[key].totalAmmount);
      roomtype += `${selectedRooms[key].roomName}`;
      selectedRoomid.push(selectedRooms[key].roomId);
      currency = selectedRooms[key].currency;
      hotelName = selectedRooms[key].hotelName;
      hotelId = selectedRooms[key].hotelId;
    }

    let totalPrice = getTotalPrice();
    let data = {
      addons,
      booking_type,
      booking_status: "pending",
      check_in: userInfo.checkIn,
      check_out: userInfo.checkOut,
      days: userInfo.diffInDays,
      adults_quantity: userInfo.adult,
      customer_currency: currency,
      // guest_name: values.guest_name,
      // guest_email: values.email,
      // phone_number: values.ph_number,
      guest_name: formGuestName,
      guest_email: formEmail,
      phone_number: formPhNumber,
      booking_date: moment().format("YYYY-MM-DD"),
      booking_date_time: moment(),
      booking_number: Math.floor(100000 + Math.random() * 900000),
      rooms_quantity: roomsquantity[0],
      selected_room_price: roomsprices[0],
      room_type: roomtype,
      policy_id: userInfo.policyId,
      hotel_name: hotelName,
      room_ids: roomsids,
      selected_room_ids: selectedRoomid,
      selected_rooms_quantities: roomsquantity,
      // selected_room_price: roomsprices,
      trip_type: "on",
      guest_type: "on",
      hotel_id: hotelId,
      optional_things: "3 x breakfast",
      commission: totalPrice * 0.12,
      guest_number: 3024883791,
      user_id: userInfo.id,
      full: "",
      total_price: totalPrice,
      source_type: "mobile",
    };

    axios
      .post(`${app.hotelBaseUrl}/api/hotel_booking`, { data })
      .then((res) => {
        console.log("find id ---", res);
        setPaymentPortal(res.data.payment_portal);

        if (!manual && res.data.transaction_id)
          setDino(res.data.transaction_id);
        else setDone(true);

        setProcessing(false);
        setFinished(true);

        // removeLsValue(CACHE__H_ROOMS_CART);

        // OneSignal.sendTags({
        //   is_cart: false,
        //   cart_update: Math.floor(Date.now() / 1000),
        //   // hotel_id: sR.parent_id,
        //   hotel_name: null,
        //   hotel_image: null,
        //   // rooms: Object.keys(newData).length,
        // })
        //   .then((res) => console.log("Cart Tagged:", res))
        //   .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const checkDate = () => {
    const today = new Date();
    const nextWeek = Date.parse(
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
    );

    if (nextWeek < Date.parse(userInfo.checkIn)) {
      console.log("*** Within next week ***");
      return true;
    }

    console.log("*** Outside next week ***");
    return false;
  };

  return (
    <div className="checkout">
      <PageHeader homeLink="/" isCustom title="Your Hotel" />

      <div style={{ paddingTop: 65, marginBottom: -65 }}>
        <ImageGrid images={images} />
      </div>

      <div className="checkout__body">
        <div className="row backgroudLightBlue p-3 d-flex flex-column">
          <div className="montserratN900-22 mb-2">
            <p>{hotel?.hotel_name}</p>
          </div>

          {/* <div className="pb-2">
            <div className="futura-14">
              <img src={RatingStar} alt="" srcset="" />
              <span className="p-1">{hotel?.hotel_rating?.toFixed(1)}</span>
              <span className="p-1 futuraBkBtN-14">
                <u>All reviews</u>
              </span>
            </div>
          </div> */}

          <div className="futuraBkBtN-14">
            <p>{hotel?.address_line_two}</p>
          </div>
        </div>

        <div className="rd-custom-card checkout__card">
          <h3>Your booking details</h3>

          <div className="checkout__in-out">
            <div>
              <p>Check - in</p>
              <p>Check - out</p>
            </div>

            <div className="in-out">
              <p>
                {moment(userInfo.checkIn, "YYYY-MM-DD").format(
                  "ddd, DD MMM YYYY"
                )}
              </p>

              <p>
                {moment(userInfo.checkOut, "YYYY-MM-DD").format(
                  "ddd, DD MMM YYYY"
                )}
              </p>
            </div>
          </div>

          <p>Duration: {userInfo.diffInDays} nights</p>

          <div className="infos">
            <div className="info">
              <p className="mb-2" style={{ fontSize: 16 }}>
                You selected
              </p>

              {Object.keys(selectedRooms).map((id, i) => {
                const room = selectedRooms[id];

                return (
                  <p className="font-weight-bold mb-1" key={i}>
                    {room.numberOfRooms}&nbsp;x&nbsp;{room.roomName}
                  </p>
                );
              })}
            </div>

            <div className="info">
              <p className="mb-2 mt-3" style={{ fontSize: 16 }}>
                Sleeps upto
              </p>

              <p className="font-weight-bold mb-1">{userInfo.adult} adult(s)</p>
            </div>
          </div>
        </div>

        <div className="rd-custom-card checkout__cancellation">
          <h3>Cancellation Policy</h3>

          <p className="red">Booking is non - refunable</p>

          {hotel?.policies?.accidental_booking === "yes" ? (
            <p className="blue">Protected for accidental booking</p>
          ) : (
            <p className="red">Non protected for accidental booking</p>
          )}
        </div>

        <div className="rd-custom-card checkout__total">
          <h3>Price Summary</h3>

          {Object.keys(selectedRooms).map((id, i) => {
            const room = selectedRooms[id];

            return (
              <div>
                <p>
                  {room.numberOfRooms}&nbsp;x&nbsp;{room.roomName}
                </p>

                <p className="price">
                  AED {separateNumbers(room.totalAmmount)}
                </p>
              </div>
            );
          })}

          {addons.full_board && (
            <div>
              <p>{addons.numberOfPersons}&nbsp;x&nbsp;Full Board</p>
              <p className="price">
                AED {separateNumbers(addons.full_board_price)}
              </p>
            </div>
          )}

          {addons.half_board && (
            <div>
              <p>{addons.numberOfPersons}&nbsp;x&nbsp;Half Board</p>
              <p className="price">
                AED {separateNumbers(addons.half_board_price)}
              </p>
            </div>
          )}

          {addons.breakfast && (
            <div>
              <span>{addons.numberOfBreakfast}&nbsp;x&nbsp;Breakfast</span>
              <p className="price">
                AED {separateNumbers(addons.breakfast_price)}
              </p>
            </div>
          )}

          {addons.internet && (
            <div>
              <p>Internet</p>
              <p className="price">
                AED {separateNumbers(addons.internet_price)}
              </p>
            </div>
          )}

          {addons.parking && (
            <div>
              <p>Parking</p>
              <p className="price">
                AED {separateNumbers(addons.parking_price)}
              </p>
            </div>
          )}

          {addons.pets && (
            <div>
              <p>Pets</p>
              <p className="price">AED {separateNumbers(addons.pets_price)}</p>
            </div>
          )}

          <hr />

          <div>
            <p className="mb-0 font-weight-bold">Total</p>
            <p className="mb-0 price">AED {getTotalPrice()}</p>
          </div>
        </div>

        <div className="checkout__info">
          <div className="info">
            <p>Are you going for work?</p>

            <Group onChange={(e) => setIsWork(e.target.value)} value={isWork}>
              <Space direction="vertical">
                <Radio value="no">No</Radio>
                <Radio value="yes">Yes</Radio>
              </Space>
            </Group>
          </div>

          <div className="info">
            <p>Are you going for work?</p>

            <Group onChange={(e) => setGuest(e.target.value)} value={guest}>
              <Space direction="vertical">
                <Radio value="main">I’m the main guest</Radio>
                <Radio value="else">I’m booking for someone else</Radio>
              </Space>
            </Group>
          </div>
        </div>

        <div className="checkout__data">
          <label htmlFor="name">Name</label>

          <input
            id="name"
            autoComplete="off"
            value={formName}
            defaultValue={user.name}
            className="rd-custom-input form-control"
            onChange={(e) => setFormName(e.target.value)}
            placeholder="Name"
          />

          <label htmlFor="email">Email</label>

          <input
            id="email"
            type="email"
            autoComplete="off"
            value={formEmail}
            defaultValue={user.email}
            className="rd-custom-input form-control"
            onChange={(e) => setFormEmail(e.target.value)}
            placeholder="Email address"
          />

          <label htmlFor="phone">Phone number</label>

          <input
            id="email"
            autoComplete="off"
            value={formPhNumber}
            defaultValue={user.phNumber}
            className="rd-custom-input form-control"
            onChange={(e) => setFormPhNumber(e.target.value)}
            placeholder="Phone Number"
          />

          {/* <div>
            <PhoneInput
              id="phone"
              value={formPhNumber}
              defaultValue={user.phNumber}
              onChange={(val) => setFormPhNumber(val)}
              placeholder="Phone Number"
            />
          </div> */}

          <label htmlFor="guest_name">Guest Name</label>

          <input
            id="guest_name"
            autoComplete="off"
            value={formGuestName}
            className="rd-custom-input form-control"
            onChange={(e) => setFormGuestName(e.target.value)}
            placeholder="Guest Name"
          />

          <label htmlFor="guest_phone">Guest Phone number</label>

          <input
            id="guest_phone"
            autoComplete="off"
            value={formGuestPhNumber}
            className="rd-custom-input form-control"
            onChange={(e) => setFormGuestPhNumber(e.target.value)}
            placeholder="Guest Phone Number"
          />

          {/* <div>
            <PhoneInput
              id="guest_phone"
              value={formGuestPhNumber}
              onChange={(val) => setFormGuestPhNumber(val)}
              placeholder="Guest Phone Number"
            />
          </div> */}
        </div>

        <div className="checkout__extra">
          <p>
            <img src={Bath} alt="RoomDaddy" />
            <span>Room can accomodate 2 persons</span>
          </p>

          <p>
            <img src={Smoke} alt="RoomDaddy" />
            <span style={{ textTransform: "capitalize" }}>
              {hotel?.hotel_active_rooms?.[0]?.smoking_option}
            </span>
          </p>
        </div>

        <div className="rd-custom-card checkout__disclose">
          <p>
            Your booking is with {hotel?.hotel_name} directly and by completing
            this booking you agree to the <a href="#">booking conditions</a>,{" "}
            <Link to="/terms-and-conditions">general terms</Link>, and{" "}
            <Link to="/privacy-policy">privacy policy</Link>.
          </p>
        </div>

        <div className="checkout__button">
          <Button className="bookN w-100" onClick={() => setShow(true)}>
            Continue to payment
          </Button>
        </div>
      </div>

      <Modal visible={show}>
        {!finished && (
          <div className="d-flex align-items-center justify-content-between p-3">
            <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

            <h5
              className="mb-0"
              style={{
                color: "#1c2c4a",
                fontSize: 18,
                fontFamily: "futuraMdBt",
              }}
            >
              Payment option
            </h5>

            <div>
              <img
                src={Cross}
                alt="Close - RoomDaddy"
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
          </div>
        )}

        <div className="p-4">
          {!finished ? (
            <Button
              className="bookN w-100"
              disabled={processing}
              onClick={() => {
                roomCheckOut("pay_at_property");
                setManual(true);
                setProcessing(true);
              }}
            >
              {processing ? "Processing..." : "Pay At Property"}
            </Button>
          ) : (
            <div className="text-center pb-2">
              <p className="pt-3 pb-2">
                Your booking is valid till{" "}
                <b>{moment().add("d").format("HH:mm A DD MMM YYYY")}</b>. Please
                make the payment at the property within that time or, it will be
                declined.
              </p>

              <a
                href="/"
                style={{ fontFamily: "futuraMdBt" }}
                className="text-darkBlue"
              >
                Go to homepage
              </a>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CheckoutPage;
