import Pusher from 'pusher-js';

Pusher.logToConsole = false;

// const pusher = new Pusher('c6b5373c64f1600160a5', {
//     cluster: 'ap2',
//     encrypted: true,
// });

const pusher = new Pusher('c7bb4d59d82dced35731', {
    cluster: 'us2',
    encrypted: true,
});

export default pusher;
