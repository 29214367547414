import PrivacyPolicyIcon from "../../icons/privacyPolicyIcon.svg";
import WorldIcon from "../../icons/worldIcon.svg";
import LogOutIcon from "../../icons/logOut.svg";
import FileIcon from "../../icons/fileIcon.svg";

const sideBarData = [
  {
    name: "My Account",
    subTypes: [
      {
        image: LogOutIcon,
        name: "Sign In",
        linkTo: "/login",
      },
      {
        image: PrivacyPolicyIcon,
        name: "Privacy policy",
        linkTo: "/privacy-policy",
      },
      {
        image: WorldIcon,
        name: "General Questions",
        linkTo: "/faqs/general-questions",
      },
      {
        image: PrivacyPolicyIcon,
        name: "FAQs about Listing",
        linkTo: "/faqs/listing",
      },
      {
        image: WorldIcon,
        name: "FAQs about Searching",
        linkTo: "/faqs/home-search",
      },
      {
        image: FileIcon,
        name: "Hire Us",
        linkTo: "/hire-us",
      },
      { name: null },
    ],
  },
];

export default sideBarData;
