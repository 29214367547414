import MapIcon from "../../icons/map.svg";
import FilterIcon from "../../icons/filter.svg";
import SortIcon from "../../icons/sort.svg";

const buttons = [
  // { icon: MapIcon, title: "Map" },
  // { icon: FilterIcon, title: "Filters" },
  { icon: null, title: "Budget" },
  { icon: null, title: "Amenities" },
  { icon: null, title: "House rules" },
  { icon: null, title: "Listing type" },
  { icon: SortIcon, title: "Sort by" },
  { icon: null, title: "Reset all" },
];

export default buttons;
