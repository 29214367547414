import React from 'react'
import Header from "../../components/pageHeader/index"

export default function Payment() {
    return (
        <>
            <Header
                arrowLink={"/booking"}
                heading={"Payment history"}
            />
            <main className="flex-shrink-0">
                <div className="container">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="text-secondary">
                                <ul class="text-secondary small lists">
                                    <li>Sr. no. : <span class="float-right ">EUR 200</span></li>
                                    <li>Amount : <span class="float-right ">EUR 15</span></li>
                                    <li>Payment Date : <span class="float-right">2020-12-25</span></li>
                                    <li>Invoice Type : <span class="float-right">6 months</span></li>
                                    <li>Payment Type : <span class="float-right ">EUR 1000</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
