import React from 'react'
import Footer from '../_newComponents/Footer'
import PageHeader from '../_newComponents/pageHeader'
import image1 from '../images/card1.png'
import image2 from '../images/card2.png'
import Dots from '../icons/dots.svg'
import { Our_Story} from '../data/tilesData'

export default function OurStory() {
  return (
    <>
        <PageHeader homeLink="/" />
        <div className='blog_body'>
            <div className='nHeader'>
                <div className='nHeader_bg'>
                    <p className='montserratN900-45 text-white'>Our Story </p>
                </div>
            </div>
            <div className='card-body'>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    When WE left our family and home to move to a new country for better opportunities, nothing felt better, WE experienced alienation and loneliness. WE struggled to build a new life, to make new friends (even acquaintances), to manage expenses, to find quality accommodations that matched our lifestyle. Life felt very mechanical and joyless then. <br/>
                    <br/>
                    Our first-hand experience taught us a lesson for life. WE understood what the absence of human interactions and social well-being meant. To protect others, from such circumstances and fast track their adjustment period we have put ultimate efforts in building a perfect community - where you will feel at home no matter where you go. 
                </p>
                <div className='mt-5'>
                    <div className='section pb-2'>
                        <div className='custom_card_img-text'>
                            <img className='w-100 borderRadius-10' src={`${image1}`} alt='RMD_img'/>
                            <div className='custom_card_text'>
                                <p className='futuraNBold-30 text-white'>Our Vision </p>
                                <p className='futuraBkBtN-14 text-white'> We aim to grow the coliving community structure with such diversity and to such an extent that everyone around the globe starts belonging to it.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='section pb-2'>
                        <div className='custom_card_img-text'>
                            <img className='w-100 borderRadius-10' src={`${image2}`} alt='RMD_img'/>
                            <div className='custom_card_text'>
                                <p className='futuraNBold-30 text-white'>Our Mission </p>
                                <p className='futuraBkBtN-14 text-white'> To redefine coliving by  creating and managing a seamless community system which fulfills the social and housing needs of individuals with ultimate convenience and flexibility.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <img className='w-100' src={`${Dots}`}/>
                    <p class="futuraN700-20 text-center">What We Stand for</p>
                </div>
                {Our_Story.map((item, index)=>{
                    return(
                        <>
                            <div className='our_story_card' key={index}>
                                <div className='abcc borderRadius-15 badge_colour_primary blur'>
                                    <div>
                                        <img src={`${item.icon}`} />
                                    </div>
                                    <div className='futuraN700-16 text-lightBlack'>
                                        <p className='mb-0'>{item.name}</p>
                                        <p className='futuraBkBtN-14'>{item.title}</p>
                                    </div>
                                </div>
                                <img className='w-100 borderRadius-10' src={`${item.img}`} alt='RMD_img'/>
                            </div>
                        </>
                    )
                })}
            </div>

            <Footer/>
        </div>
    </>
  )
}
