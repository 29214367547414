import colivingIcon from "../icons/postColiving.svg";
import entireIcon from "../icons/postEntire.svg";
import parkingIcon from "../icons/postParking.svg";

const PostnAddData = [
  {
    name: "Coliving",
    icon: colivingIcon,
    linkTo: "/room_rent",
  },
  {
    name: "Entire home",
    icon: entireIcon,
    linkTo: "/entire-accomodation",
  },
  {
    name: "Parking",
    icon: parkingIcon,
    linkTo: "/parking/add",
  },
];

export default PostnAddData;
