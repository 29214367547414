import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import LocationIcon from "../../icons/pinLocation.svg";

const icon = new Icon({
  iconUrl: LocationIcon,
  iconSize: [25, 41],
});

const LeafletMap = (position) => {
  return (
    <MapContainer
      style={{ height: "250px" }}
      center={Object.values(position)[0]}
      zoom={9}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={Object.values(position)[0]} icon={icon}></Marker>
    </MapContainer>
  );
};

export default LeafletMap;
