import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { setLsValue } from "../../utils/functions";
import { CACHE__PWD_RESET_MAIL } from "../data/cacheKeys";
import PageHeader from "../_newComponents/pageHeader";
import ForgetPassImage from "../images/forgotPass.svg";
import DotsTop from "../images/dots_login-1.svg";
import DotsBottom from "../images/dots_login-2.svg";
import "./style/forgetPass.scss";

const ForgetPassPage = () => {
  const [email, setEmail] = useState("");

  const sendMail = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("email", email);

    axios
      .post(`${app.baseUrl}/api/password/email`, data)
      .then((res) => message.success("Email is sent!"))
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });

    setLsValue(CACHE__PWD_RESET_MAIL, email);
  };

  return (
    <div className="forget-pass">
      <PageHeader isHome homeLink="/" />

      <img src={DotsTop} alt="RoomDaddy" className="forget-pass__dots--top" />

      <div className="forget-pass__body">
        <img
          src={ForgetPassImage}
          alt="RoomDaddy"
          className="forget-pass__image"
        />

        <h1>Forgot your password?</h1>

        <div className="forget-pass__caption">
          Enter your e-mail address and we’ll send you a link to reset your
          password
        </div>

        <form className="forget-pass__form" onSubmit={sendMail}>
          <div className="forget-pass__labels">
            <label htmlFor="email">Email address</label>
          </div>

          <input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="forget-pass__input"
            type="email"
          />

          <Button
            type="submit"
            variant="contained"
            className="forget-pass__button"
          >
            Send Email
          </Button>
        </form>

        <Link to="/login">
          <Button
            type="submit"
            variant="contained"
            className="forget-pass__cancel"
          >
            Cancel
          </Button>
        </Link>
      </div>

      <img
        src={DotsBottom}
        alt="RoomDaddy"
        className="forget-pass__dots--bottom"
      />
    </div>
  );
};

export default ForgetPassPage;
