import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { decode } from "html-entities";
import moment from "moment";
import { getUser } from "../../utils/functions";
import pusher from "../../utils/pusher";
import app from "../utils/appConfig";
import PageHeader from "../_newComponents/pageHeader";
import MobileNav from "../_newComponents/MobileNav";
import NotificationImage from "../images/notification.svg";
import NotificationIcon from "../icons/notifAvatar.svg";
import "./style/notification.scss";

const NotificationPage = () => {
  const user = getUser();
  const userId = user?.id;

  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`${app.baseUrl}/api/get-notification/${userId}`)
        .then((res) => {
          const data = res.data.data.data;

          setNotifications(data);
          setUnread(res.data.total_unread);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    const notifChannel = pusher.subscribe("all-notification");
    notifChannel.bind("App\\Events\\NotificationEvent", (data) => {
      getData();
    });

    getData();

    return () => {
      notifChannel.unbind_all();

      axios
        .post(`${app.baseUrl}/api/clear-notification/${userId}`)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    };
  }, []);

  return (
    <div className="notif">
      <PageHeader homeLink="/" isCustom title="Notification" />

      {!loading && !notifications.length ? (
        <div className="notif__no-messages">
          <div className="notif__img-container">
            <img src={NotificationImage} alt="Notification - RoomDaddy" />
          </div>

          <div className="notif__no-messages-info">
            <h3>You have no notifications</h3>

            <p>
              Plan a new trip and explore places to stay close to all the places
              you love. <Link to="/co-living">Browse Places</Link>
            </p>
          </div>
        </div>
      ) : (
        <div className="notif__messages">
          {notifications.map(
            ({ fld_noti_type, fld_read, fld_message, fld_updated_at }, i) => {
              const relevant_id = fld_message.split(":")?.[1]?.trim();

              return (
                <Link
                  key={i}
                  className="nn"
                  to={`${
                    fld_noti_type === "New Message"
                      ? "/message"
                      : (fld_noti_type === "invoice" ||
                          fld_noti_type === "rent_invoice") &&
                        !isNaN(relevant_id)
                      ? `/my_booking/co-living/${relevant_id}/payment-history`
                      : "#"
                  }`}
                >
                  <div className="nn__icon">
                    <img
                      src={NotificationIcon}
                      alt="Notification - RoomDaddy"
                    />
                  </div>

                  <div className="nn__info">
                    <p className={`nn__msg ${!fld_read && "font-weight-bold"}`}>
                      {decode(fld_message)}
                    </p>

                    <span
                      className={`nn__time ${!fld_read && "font-weight-bold"}`}
                    >
                      {moment(fld_updated_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD MMM"
                      )}
                    </span>
                  </div>
                </Link>
              );
            }
          )}
        </div>
      )}

      <MobileNav />
    </div>
  );
};

export default NotificationPage;
