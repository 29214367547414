import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Carousel } from "react-responsive-carousel";
import { message } from "antd";
import NoImage from "../../images/no-image.png";
import BackIcon from "../../icons/back.svg";
import CrossIcon from "../../icons/cross_dark.svg";
import ShareIcon from "../../icons/copy.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.scss";

const ImageGrid = ({ images, title, share, type, prev }) => {
  const history = useHistory();
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    document.body.style.overflow = overlay ? "hidden" : "unset";
  }, [overlay]);

  const copyLink = () => {
    const copyText = document.getElementById("rd-share-link");

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);
    message.success("Link copied");
  };

  return (
    <div className="image-grid">
      <img
        src={BackIcon}
        className="img-back"
        alt="Go Back - RoomDaddy"
        onClick={() => {
          const prevHost = window.document.referrer
            ? new URL(window.document.referrer).hostname
            : null;
          const currHost = window.location.hostname;

          if (prev) {
            console.log("back -->", prev);
            history.push(prev);
          } else if (!prevHost || prevHost !== currHost) {
            const redirectUrl =
              type === "C"
                ? "/co-living"
                : type === "R"
                ? "/hotel-rooms"
                : type === "A"
                ? "/hotel-apartment"
                : type === "H"
                ? "/short-term-rental"
                : "/";

            history.push(redirectUrl);
          } else {
            history.goBack();
          }
        }}
      />

      <div className="image-grid__container">
        {images.length <= 1 ? (
          <img
            src={images[0] || NoImage}
            alt={title}
            className="image-grid__container-full"
          />
        ) : (
          <div className="image-grid__container-triple">
            <div className="first">
              <img
                src={images[0]}
                alt={title}
                className="image-grid__container-full"
                onClick={() => images.length && setOverlay(true)}
              />
            </div>

            <div className="last">
              <div className="one">
                <img
                  src={images[1]}
                  alt={title}
                  onClick={() => images.length && setOverlay(true)}
                />
              </div>

              <div className="two">
                {images[2] ? (
                  <img
                    src={images[2]}
                    alt={title}
                    onClick={() => images.length && setOverlay(true)}
                  />
                ) : (
                  <p>+{images.length}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {share ? (
        <div className="image-grid__share" onClick={copyLink}>
          <input type="text" id="rd-share-link" value={share} />

          <div className="image-grid__share-icon">
            <img src={ShareIcon} alt={`Share ${share} - RoomDaddy`} />
          </div>
        </div>
      ) : null}

      {images[2] ? (
        <div className="image-grid__count" onClick={() => setOverlay(true)}>
          <p>+{images.length}</p>
        </div>
      ) : null}

      {overlay ? (
        <div className="image-grid__overlay">
          <img
            src={CrossIcon}
            alt="Close Overlay - RoomDaddy"
            onClick={() => setOverlay(false)}
          />

          <div id="grid-overlay">
            <Carousel emulateTouch>
              {images.map((img, i) => (
                <div key={i}>
                  <img src={img} alt={title} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImageGrid;
