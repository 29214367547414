import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Card, Steps } from "antd";
import StepOne from "../PostnAddPages/colivingStepOne";
import StepTwo from "../PostnAddPages/colivingStepTwo";
import StepThree from "../PostnAddPages/colivingStepThree";
import StepFour from "../PostnAddPages/colivingStepFour";
import Back from "../../icons/back.svg";
import Cross from "../../icons/cross.svg";
import GoogleMap from "../../_newComponents/GoogleMap";
import "./index.scss";

const { Step } = Steps;

export default function RentPlace(props) {
  const history = useHistory();
  const { userInfo } = useContext(AppContext);

  const [current, setCurrent] = useState(0);

  const steps = [
    <StepOne nextStep={setCurrent} />,
    <StepTwo nextStep={setCurrent} />,
    <StepThree nextStep={setCurrent} />,
    <StepFour nextStep={setCurrent} />,
  ];

  useEffect(() => {
    window.onbeforeunload = () =>
      "Your changes might not be saved, are you sure?";
    window.scrollTo(0, 0);
  }, [current]);

  return (
    <div className="place-an-ad">
      <div className="header screen-header pt-3 pb-3" style={{ zIndex: "999" }}>
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const confirmation = window.confirm(
                  "Your changes might not be saved, are you sure you want to go back?"
                );

                if (confirmation) {
                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost) history.push("/");
                  else history.goBack();
                }
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Coliving</div>
          </div>

          <div className="col-auto">
            {current > 0 && (
              <img
                className=""
                src={Cross}
                alt="cencel_Arrow"
                onClick={() => setCurrent(current - 1)}
              />
            )}
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "55px" }}>
        {current === 0 ? (
          <GoogleMap lat={userInfo.lat} lng={userInfo.lng} />
        ) : null}

        <div className="steps">
          <Steps current={current}>
            {steps.map((item, idx) => (
              <Step key={"step" + idx} />
            ))}
          </Steps>

          <div className="steps-content">
            <Card style={{ display: `${current === 0 ? "block" : "none"}` }}>
              {steps[0]}
            </Card>

            <Card style={{ display: `${current === 1 ? "block" : "none"}` }}>
              {steps[1]}
            </Card>

            <Card style={{ display: `${current === 2 ? "block" : "none"}` }}>
              {steps[2]}
            </Card>

            <Card style={{ display: `${current === 3 ? "block" : "none"}` }}>
              {steps[3]}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
