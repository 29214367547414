import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import { Collapse, DatePicker, message } from "antd";
import { Carousel, WingBlank } from "antd-mobile";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import { getUser, separateNumbers } from "../../utils/functions";
import Modal from "../_newComponents/Modal";
import { MetaTags } from "react-meta-tags";
import PageHeader from "../_newComponents/pageHeader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import ImageGrid from "../_newComponents/ImageGrid";
import LeafletMap from "../_newComponents/LeafletMap";
import RdLoader from "../_newComponents/RdLoader";
import Next from "../icons/next.svg";
import PhoneCall from "../icons/phone-call.svg";
import Email from "../icons/email.svg";
import RatingStar from "../icons/rating.svg";
import PinMark from "../icons/pinMarker.svg";
import Cross from "../icons/cross.svg";
import Tick from "../icons/tick.svg";
import "./style/entireHomeDetail.scss";

const { Panel } = Collapse;
const dateFormat = "YYYY-MM-DD";

const EntireHomeDetail = () => {
  const user = getUser(); 

  const { id } = useParams();
  const { userInfo, setUserInfo } = useContext(AppContext);

  const [isLeadCalled, setIsLeadCalled] = useState(false);
  const [ip, setIp] = useState();
  const [loading, setLoading] = useState(true);
  const [calculating, setCalculating] = useState(false);
  const [apartmentIndex, setApartmentIndex] = useState(-1);
  const [home, setHome] = useState({});
  const [reviews, setReviews] = useState([]);
  const [images, setImages] = useState([]);
  const [position, setPosition] = useState([25.2048, 55.2708]);
  const [contactPopUp, setContactPopUp] = useState(false);
  const [phonePopUp, setPhonePopUp] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [dates, setDates] = useState([]);
  const [contact, setContact] = useState({
    name: user?.name,
    email: user?.email,
    number: user?.phone,
    message:
      "I saw your ad on Roomdaddy. When is it available for viewing? Thanks.",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const collectLead = (type) => {
    const data = new FormData();

    data.append("user_id", user?.id);
    data.append("hotel_id", id);
    data.append("fld_system_ip", ip);
    data.append("lead_type", type);
    data.append("access_type", "A");

    axios
      .post(`${app.homeBaseUrl}/api/save_phone_lead`, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // axios
    //   .get("http://geoplugin.net/json.gp")
    //   .then((res) => setIp(res.data.geoplugin_request))
    //   .catch((err) => console.log(err));

    axios
      .get(`${app.baseUrl}/api/get_ip`)
      .then((res) => setIp(res.data.ip))
      .catch((err) => console.log(err));

    fetchData();
    // fetchSmartListing();

    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (
      // user?.id &&
      home?.id &&
      ip &&
      !isLeadCalled
    ) {
      collectLead("v");
      setIsLeadCalled(true);
    }
  }, [
    home?.id,
    ip,
    user?.id,
    isLeadCalled,
  ]);



  const fetchData = () => {
    axios
      .get(
        `${app.homeBaseUrl}/api/get_client_apart_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        const data = res.data[0];
        let aptIndex = data.apartments.findIndex((e) => e.hotel_id == id);

        const imgArr = [];
        data.hotel_images.forEach((img) => imgArr.push(img.image));

        if (!imgArr.length)
          data.apartments[0].room_images.forEach((img) =>
            imgArr.push(img.image)
          );

        setImages(imgArr);
        setApartmentIndex(aptIndex);
        setHome(data);

        if (data.hotel_latitude && data.hotel_longitude)
          setPosition([data.hotel_latitude, data.hotel_longitude]);

        setCalculating(false);
        setLoading(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  };

  const fetchReviews = () => {
    // fetch home reviews
  };
  

  const getRoomPrice = (index) => {
    if (home.apartments) {
      let tax = 0;

      if (
        home.apartments[index] &&
        home.apartments[index].apartment_rent_type &&
        home.apartments[index].apartment_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(home.hotel_tax) / 100) *
          home.apartments[index].room_base_price;
        tax =
          tax + home.apartments[index].room_base_price + home.hotel_tax_amount;
      } else {
        tax = home.apartments[index]
          ? home.apartments[index].apartment_base_price
          : 0;
      }

      return Math.ceil(tax);
    }
  };

  const getMinPrice = (rooms) => {
    const prices = [];

    for (let i = 0; i < rooms?.length; i++) {
      const price = getRoomPrice(i);
      if (price) prices.push(price);
    }

    return Math.min(...prices);
  };

  useEffect(() => {
    fetchData();
    fetchReviews();
  }, [id]);

  const disabledCheckInDate = (current) =>
    current && current < moment().subtract(1, "d");

  const disabledCheckOutDate = (current) => {
    const checkIn = dates[0];
    const isAfter = checkIn?.isAfter(moment());
    const diffInDays = isAfter ? checkIn?.diff(moment(), "days") : 0;

    return current && current < moment().add(30 + diffInDays, "days");
  };

  const sendEmail = () => {
    const data = new FormData();

    for (let key in contact) {
      if (key !== "number" && !contact[key]) return;
      data.append(key, contact[key]);
    }

    data.append("owner_mail_id", home.contact_email);
    data.append("owner_name", home?.hotel_name);
    data.append("own_property_description", home?.hotel_desc.substr(0,100));
    // data.append("room_name", coLiving?.room_title);
    data.append("link", window.location.href);

    axios
      .post(`${app.baseUrl}/api/reply-form`, data)
      .then((res) => {
        message.success("Your email has been sent.");
        // onClose("Email")();
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });
  };

  return (
    <div className="entire-home-detail">

      <MetaTags>
        <title>{home?.hotel_name}</title>
        <meta name="description" content={home?.hotel_desc || "A heaven of luxury & superior living set a usual concept of creating an awesome life system around"} />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>

      <PageHeader isHome homeLink="/" />

      {loading ? (
        <RdLoader />
      ) : (
        <div style={{ paddingTop: "60px" }}>
          <ImageGrid
            images={images}
            title={home?.hotel_name}
            share={window.location.href}
            type="H"
          />

          <div className="container-fluid">
            <div className="row backgroudLightBlue p-3 d-flex flex-column">
              <div className="montserratN900-22 mb-2">
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: 22,
                  lineHeight: "145.9%",
                  color: "#1c2c4a",
                }}
              >{home?.hotel_name}</h1>
              </div>

              {home?.hotel_rating ? (
                <div className="pb-2">
                  <div className="futura-14">
                    <img src={RatingStar} alt="" srcset="" />
                    <span className="p-1">
                      {home?.hotel_rating?.toFixed(1)}
                    </span>
                    <span className="p-1 futuraBkBtN-14">
                      <u>All reviews</u>
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="futuraBkBtN-14">
                <p>{home?.address_line_two}</p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6 hotel-date-pickers">
                <DatePicker
                  className="mb-2"
                  format={dateFormat}
                  disabledDate={disabledCheckInDate}
                  value={dates[0] || moment(userInfo.checkIn, dateFormat)}
                  onChange={(date, dateStr) => {
                    setCalculating(true);

                    setUserInfo({
                      ...userInfo,
                      checkIn: dateStr,
                      checkOut: moment(dateStr, dateFormat).add(30, "days").format(dateFormat),

                    });

                    setDates([
                      date,
                      moment(dateStr, dateFormat).add(30, "days"),
                    ]);
                  }}
                />

                <DatePicker
                  format={dateFormat}
                  disabledDate={disabledCheckOutDate}
                  value={dates[1] || moment(userInfo.checkOut, dateFormat)}
                  onChange={(date, dateStr) => {
                    setCalculating(true);

                    setUserInfo({
                      ...userInfo,
                      checkOut: dateStr,
                      diffInDays:
                        dates[0] && date ? date.diff(dates[0], "days") : 30,
                    });

                    setDates([moment(userInfo.checkIn, dateFormat), date]);
                  }}
                />
              </div>

              <div className="col-6">
                <div className="text-right">
                  <p className="futuraBkBtN-14 m-0">
                    {home?.apartments?.length} apt type(s) available
                  </p>

                  <p className="montserratNB-16 m-0">
                    {calculating ? (
                      <ContentLoader
                        speed={2}
                        width={90}
                        height={19}
                        viewBox="0 0 90 19"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          width="90"
                          height="19"
                        />
                      </ContentLoader>
                    ) : getMinPrice(home?.apartments) ? (
                      home?.hotel_currency +
                      " " +
                      separateNumbers(getMinPrice(home?.apartments))
                    ) : (
                      <span onClick={() => setPhonePopUp(true)}>
                        Inquire Now
                      </span>
                    )}
                  </p>

                  <div className="montserratNB-16">
                    <Link
                      to={`/short-term-rental/detail/${id}/rooms`}
                      className="p-1 montserratN400-16 HomeVRoom"
                    >
                      <u>See rooms</u>
                    </Link>

                    <img src={Next} className="ml-1" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <ResponsiveAd />
            <div className="mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="montserratN900-22 pl-3">
                    <p className="">Property Details</p>
                  </div>

                  <div className="futuraBkBtN-14 pl-3">
                    <p className="py-2">
                      {home.hotel_desc ||
                        "A heaven of luxury & superior living set a usual concept of creating an awesome life system around"}
                    </p>
                  </div>

                  <div className="futuraBkBtN-14 d-flex">
                    <div className="m-2">
                      <img src={PinMark} alt="" />
                    </div>

                    <div className="m-2">
                      <p className="mb-0">{home?.hotel_pin_location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="montserratN900-22 pl-3">
                <p className="">Apartment Features</p>
              </div>

              <p className="futuraN700-17 pl-3">Most popular facilities</p>

              <ul className="hotel-feature-list p-0">
                {home?.services?.facilities_array?.map((item, i) => (
                  <li key={i} className="p-1">
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "futuraBkBt",
                      }}
                    >
                      <img src={Tick} alt="" className="mr-2" />
                      {item.split("_").join(" ").split("-").join(" ")}
                    </span>
                  </li>
                ))}
              </ul>

              <div className="mt-4">
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                >
                  {home?.services?.hotel_amenities_media?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Media and technology"
                      key="1"
                    >
                      <ul className="hotel-feature-list p-0">
                        {home?.services?.hotel_amenities_media?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {home?.services?.hotel_amenities_food?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Food and drink"
                      key="2"
                    >
                      <ul className="hotel-feature-list p-0">
                        {home?.services?.hotel_amenities_food?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {home?.services?.hotel_amenities_services?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Services and extra"
                      key="3"
                    >
                      <ul className="hotel-feature-list p-0">
                        {home?.services?.hotel_amenities_services?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {home?.services?.hotel_amenities_outdoor?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Outdor and view"
                      key="4"
                    >
                      <ul className="hotel-feature-list p-0">
                        {home?.services?.hotel_amenities_outdoor?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  {home?.services?.hotel_amenities_entertainment?.length && (
                    <Panel
                      className="futuraN700-17"
                      header="Entertainment and family services"
                      key="5"
                    >
                      <ul className="hotel-feature-list p-0">
                        {home?.services?.hotel_amenities_entertainment?.map(
                          (item, i) => (
                            <li key={i} className="p-1">
                              <span style={{ textTransform: "capitalize" }}>
                                <img src={Tick} alt="" className="mr-2" />
                                {item}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </Panel>
                  )}

                  <Panel className="futuraN700-17" header="Polices" key="6">
                    <div className="futuraN700-14 pl-3">
                      <div className="futuraBkBtN-14">
                        <p className="mb-0">
                          Check-in: From{" "}
                          {moment(
                            home?.policies?.check_in_from,
                            "HH:mm:ss"
                          ).format("HH:mm A")}
                        </p>

                        <p className="mb-0">
                          Advance cancellation of{" "}
                          {home?.policies?.advance_cancel} day(s)
                        </p>

                        <p className="mb-0">
                          {home?.policies?.live_booking === "yes"
                            ? "Live booking"
                            : "Pay manually at the property"}
                        </p>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>

            <div className="mt-5 float-left w-100">
              <div className="montserratN900-22 pl-3">
                <p className="">Reviews</p>
              </div>

              <WingBlank>
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={10}
                  slideWidth={0.9}
                  infinite
                  dots={false}
                >
                  {reviews?.map((item, i) => (
                    <div className="card" key={i}>
                      <div className="card-body">
                        <div className="futuraBkBtN-14 p-3">
                          <p>{item.review}</p>
                        </div>
                      </div>
                    </div>
                  ))}

                  {!reviews?.length && (
                    <div className="card">
                      <div className="card-body">
                        <div className="futuraBkBtN-14 p-3">
                          <p>No reviews found for this listing</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Carousel>
              </WingBlank>

              {reviews.length ? (
                <div className="pt-2 futuraN700-14">
                  <Button type="button" className="bookN">
                    Show all reviews
                  </Button>
                </div>
              ) : null}
            </div>

            <div
              className="map-box-new mt-5 float-left w-100"
              style={{ paddingBottom: "100px" }}
            >
              <div className="montserratN900-22 pl-3 mb-1">
                <p className="">Location</p>
              </div>

              <div className="futuraBkBtN-14 pl-3 mb-3">
                <p>{home.hotel_pin_location}</p>
              </div>

              <LeafletMap position={position} />
            </div>
          </div>

          <Modal visible={contactPopUp}>
            <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
              <div className="row">
                <div className="col-10 pl-5">
                  <p>Contact</p>
                </div>

                <div className="col-2 pr-4">
                  <span onClick={() => setContactPopUp(false)}>
                    <img src={Cross} alt="Close - RoomDaddy" />
                  </span>
                </div>
              </div>
            </div>

            <div className="p-4" style={{ fontFamily: "futuraBkBt" }}>
              <div className="row">
                <div className="col-3 pr-0 py-1">Name</div>
                <div className="col-8">
                  <strong>{home?.contact_name}</strong>
                </div>
              </div>

              <div className="row">
                <div className="col-3 pr-0 py-1">Email</div>
                <div className="col-8">
                  <strong>{home?.contact_email}</strong>
                </div>
              </div>

              <div className="row">
                <div className="col-3 pr-0 py-1">Phone No.</div>
                <div className="col-8">
                  <strong>{home?.phone_number}</strong>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            popup
            visible={emailPopUp}
            onClose={() => setEmailPopUp(false)}
            animationType="slide-up"
          >
            <div class="container">
              <div className="">
                <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                  <div className="row">
                    <div className="col-10 text-left">
                      <p>Email</p>
                    </div>

                    <div className="col-2">
                      <span onClick={() => setEmailPopUp(false)}>
                        <img src={Cross} alt="" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your name </label>

                  <input
                    type="name"
                    name="userName"
                    className="rd-custom-input"
                    placeholder="Name"
                    value={contact.name}
                    onChange={(e) =>
                      setContact({ ...contact, name: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your email </label>

                  <input
                    type="email"
                    name="userEmail"
                    className="rd-custom-input"
                    placeholder="username@email.com"
                    value={contact.email}
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your phone </label>

                  <input
                    type="phone"
                    name="userPhone"
                    className="rd-custom-input"
                    placeholder="+971 xxx xxx xxx"
                    maxLength={32}
                    value={contact.number}
                    onChange={(e) =>
                      setContact({ ...contact, number: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2 mb-3">
                  <label className="futura-14 text-xyz my-2">
                    Your message{" "}
                  </label>

                  <textarea
                    className="rd-custom-textarea"
                    rows="3"
                    cols="48"
                    onChange={(e) =>
                      setContact({ ...contact, message: e.target.value })
                    }
                  >
                    {contact.message}
                  </textarea>
                </div>

                <div className="form-group mt-2 mb-4">
                  <Button
                    className="w-100 bookN futuraN700-14 EmailMsgHome"
                    onClick={() => {
                      sendEmail();
                      collectLead("e");
                    }}
                    
                  >
                    Send Email
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal visible={phonePopUp}>
            <div className="p-4 text-center">
              <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
                Would you like to call the host?
              </p>

              <div className="d-flex justify-content-center align-items-center">
                <Button
                  className="bookN-s mr-3 HomeCall"
                  onClick={() => setPhonePopUp(false)}
                >
                  <a href={`tel:${home?.phone_number}`}
                   onClick={() => collectLead("p")}>Yes</a>
                </Button>

                <Button
                  className="bookN-s"
                  onClick={() => setPhonePopUp(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>

          <div className="bottom-navigation-wrap py-3" style={{ zIndex: 9999 }}>
            <div className="pl-2 pr-1 w-100">
              <a href="#">
                <Button
                  className="bookN-s futuraN700-14 MobCallHome"
                  style={{ width: "100%" }}
                  onClick={() => setPhonePopUp(true)}
                >
                  <img src={PhoneCall} alt="" />
                  <span className="p-1">Call</span>
                </Button>
              </a>
            </div>

            <div className="pr-2 pl-1 w-100">
              <a href="#">
                <Button
                  className="bookN-s futuraN700-14 MobEmailHome"
                  style={{ width: "100%" }}
                  onClick={() => setEmailPopUp(true)}
                >
                  <img src={Email} alt="" />
                  <span className="p-1">Email</span>
                </Button>
              </a>
            </div>

            {/* <div className="futuraN700-14">
              <Link
                id="hotel-room-mob-view-rooms"
                to={`/hotel/detail/${id}/rooms`}
              >
                <Button className="bookN w-100 VRooms">View rooms</Button>
              </Link>

              <Button
                className="bookN w-100"
                onClick={() => setContactPopUp(true)}
              >
                Contact
              </Button>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default EntireHomeDetail;
