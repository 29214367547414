import React from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Calender from "../../icons/calendar.svg";

const CoLivingBooking = ({ item }) => {
  const history = useHistory();

  const getDateString = (checkIn, checkOut) => {
    const checkInDate = moment(checkIn, "YYYY-MM-DD").format("D MMM YYYY");
    const checkOutDate = moment(checkOut, "YYYY-MM-DD").format("D MMM YYYY");

    return checkOut === "0000-00-00"
      ? checkInDate
      : checkInDate + " - " + checkOutDate;
  };

  return (
    <div className="pt-2 pb-3 mx-3">
      <div className="row">
        <div className="col">
          <div className="card" style={{ height: "135px" }}>
            <div className="card-body p-0 h-100">
              <div className="row h-100">
                <div
                  className="col-5 pl-0 d-flex align-items-center justify-content-center"
                  style={{
                    height: "100%",
                  }}
                  onClick={() =>
                    history.push(`/flatshare/list/${item.bedspace_sku}`)
                  }
                >
                  <img
                    className="d-block bookingCard-img"
                    style={{ objectFit: "cover", height: "100%" }}
                    src={item.room_image}
                    alt={item.fld_room_name}
                  />
                </div>

                <div className="col-7 p-0 pt-2 pb-2 pl-3">
                  <div
                    className="row"
                    onClick={() =>
                      history.push(`/flatshare/list/${item.bedspace_sku}`)
                    }
                  >
                    <div class="futuraBold14">
                      <p
                        className="mb-0"
                        style={{
                          color: "#1C2C4A",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "175px",
                        }}
                      >
                        {item.fld_room_name}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="futuraBkBtN-12">
                      <p
                        className="mb-1 pt-1"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "175px",
                        }}
                      >
                        {item.fld_building}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="col pl-0">
                      <p
                        className="mb-0 futuraBkBtN-12 d-flex align-items-center"
                        style={{
                          color: "#1C2C4A",
                          fontWeight: "600",
                        }}
                      >
                        <img
                          className="small_icon mr-1"
                          src={Calender}
                          alt="calender_icon"
                        />

                        <span>
                          {getDateString(
                            item.fld_move_in_date,
                            item.fld_move_out_date
                          )}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between pt-2">
                    <div
                      className="col-6 pl-0 pr-0 ml-n3"
                      style={{ fontFamily: "futuraBkBt" }}
                    >
                      <p>
                        Rent{"  "}
                        <span
                          style={{
                            fontFamily: "futuraMdBt",
                            color: "#1C2C4A",
                          }}
                        >
                          AED {item.fld_rent}
                        </span>
                      </p>
                    </div>

                    <div className="col-6 pl-0 pr-0">
                      <Link to={`/my_booking/co-living/${item.booking_id}`}>
                        <Button className="bookN-s-view-btn futuraN700-14 view-btn">
                          View
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoLivingBooking;
