import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Carousel, WingBlank } from "antd-mobile";
import { message, Select } from "antd";
import axios from "axios";
import app from "../utils/appConfig";
import Back from "../icons/back.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
import { AppContext } from "../AppContext";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import { Button, Tooltip } from "@material-ui/core";
import RdLoader from "../_newComponents/RdLoader";
import { removeLsValue, setLsValue } from "../../utils/functions";
import { CACHE__H_APTS_CART } from "../data/cacheKeys";
import Modal from "../_newComponents/Modal";
import ImageGrid from "../_newComponents/ImageGrid";

const { Option } = Select;

const EntireHomeRoomDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const { id, roomId } = useParams();
  const {
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [home, setHome] = useState({});
  const [rooms, setRooms] = useState([]);
  const [beds, setBeds] = useState(0);
  const [bedType, setBedType] = useState(0);
  const [gallery, setGallery] = useState([]);
  const [showAddon, setShowAddon] = useState(false);
  const [activeApt, setActiveApt] = useState(-1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [phonePopUp, setPhonePopUp] = useState(false);
  const [state, setState] = useState({
    numberOfRooms: numOfRooms,
    breakfast: false,
    numberOfBreakfast: 0,
    internet: false,
    parking: false,
    pets: false,
  });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const selectAddon = () => setShowAddon(true);
  const handleBack = () => setShowAddon(false);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  function updateState(name, value) {
    setState({ ...state, [name]: value });
  }

  function getRoomPrice(index) {
    if (rooms) {
      let tax = 0;

      if (
        rooms[index].apartment_rent_type &&
        rooms[index].apartment_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(home.hotel_tax) / 100) * rooms[index].apartment_base_price;
        tax = tax + rooms[index].apartment_base_price + home.hotel_tax_amount;
      } else {
        tax = rooms[index].apartment_base_price;
      }

      return Math.ceil(tax);
    }
  }

  function updatePrice() {
    if (rooms.hotel_active_rooms) {
      let total = getRoomPrice(activeModalIndex);
      total = total * state.numberOfRooms;

      if (state.breakfast) {
        let bfastPrice = Number(rooms.services.optional_breakfast_price);
        total = total + bfastPrice * state.numberOfBreakfast;
      }

      setTotalPrice(total);
      return total;
    }
  }

  function clearRoom(id) {
    let temp = selectedRooms;
    delete temp[id];

    setSelectedRooms(temp);
    setState({
      numberOfRooms: 1,
      breakfast: false,
      numberOfBreakfast: 0,
      internet: false,
      parking: false,
      pets: false,
    });

    if (Object.keys(temp).length) {
      const cart = {
        selectedRooms: temp,
        userInfo,
        state,
      };

      setLsValue(CACHE__H_APTS_CART, cart);
    } else {
      removeLsValue(CACHE__H_APTS_CART);
    }
  }

  function reserveRoom(index) {
    let sA = rooms[index];

    setActiveModalIndex(index);
    setSelectedRooms({
      ...selectedRooms,
      [sA.id]: {
        ...state,
        hotelName: home.hotel_name,
        hotelId: sA.hotel_id,
        roomId: sA.id,
        roomName: sA.apartment_room_name,
        roomType: sA.apartment_type,
        roomSize: sA.apartment_size,
        checkIn: userInfo.checkIn,
        checkOut: userInfo.checkOut,
        currency: home.hotel_currency,
        // totalAmmount: updatePrice(),
        totalAmmount: getRoomPrice(index) * state.numberOfRooms,
      },
    });

    getTotalPrice();
  }

  function getTotalPrice() {
    let price = 0;
    Object.keys(selectedRooms).map((elem) => {
      price += selectedRooms[elem].totalAmmount;
    });
    return price;
  }

  useEffect(() => {
    axios
      .get(
        `${app.homeBaseUrl}/api/get_client_apart_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        const data = res.data[0];
        setRooms(data.apartments);

        console.log(data);

        data.apartments.forEach((room, i) => {
          if (room.id == roomId) {
            setHome(room);
            setActiveApt(i);

            const images = [];
            room.apartment_rooms?.forEach((r) => {
              if ("beds" in r && r.beds.length) {
                setBeds(r.beds[0].kind_of_beds_number);
                setBedType(r.beds[0].kind_of_beds);
              }
            });

            const _images = room.room_images.map((item) => item.image);
            if (_images.length) images.push(..._images);

            console.log(_images);

            setGallery(images);
            setLoading(false);
          }
        });
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  }, []);

  function getOptions(e) {
    let elem = [];
    for (let i = 1; i <= parseInt(e); i++)
      elem.push(
        <Option value={i} key={i}>
          {i} {i === 1 ? "room" : "rooms"}
        </Option>
      );

    return elem;
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="header screen-header pt-3 pb-3" style={{ zIndex: "999" }}>
        <div style={{ display: `${showAddon ? "none" : "block"}` }}>
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                className=""
                src={Back}
                alt="back_Arrow"
                onClick={() => {
                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost)
                    history.push(`/short-term-rental/detail/${id}/rooms`);
                  else history.goBack();
                }}
              />
            </div>

            <div className="col">
              <div className="futuraN700-17">Room detail</div>
            </div>
          </div>
        </div>

        <div style={{ display: `${showAddon ? "block" : "none"}` }}>
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                className=""
                src={Back}
                alt="back_Arrow"
                onClick={handleBack}
              />
            </div>

            <div className="col">
              <div className="futuraN700-17">Choose addons</div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <RdLoader />
      ) : (
        <>
          <div
            className="layoutBody room-detail"
            style={{ display: `${showAddon ? "none" : "block"}` }}
          >
            <ImageGrid
              images={gallery}
              title={home?.apartment_room_name}
              share={window.location.href}
            />

            <div className="container-fluid p-0">
              <div className="container">
                <div className="border-bottom pt-3 pb-3">
                  <div className="montserratN900-22">
                    <p className="m-0">{home?.apartment_room_name}</p>
                  </div>

                  <div className="futuraBkBtN-14">
                    <p className="m-0">
                      Size: {home?.apartment_size} ft<sup>2</sup>
                    </p>
                  </div>
                </div>

                <div className="border-bottom pt-3 pb-3">
                  <div className="row justify-content-between">
                    <div className="col-7">
                      <div className="row align-items-center justify-content-between">
                        {selectedRooms[home?.id] ? (
                          <>
                            <div className="col-5">
                              <div className="futuraN700-14">
                                <Button
                                  className="bookDanger w-100"
                                  onClick={() => clearRoom(home?.id)}
                                >
                                  ✗
                                </Button>
                              </div>
                            </div>

                            <div className="col-7 pl-0 pr-0">
                              <Select
                                className="selector-ant w-100"
                                defaultValue={
                                  selectedRooms[home?.id].numberOfRooms
                                }
                                onChange={(value) => {
                                  updateState("numberOfRooms", value);
                                  setSelectedRooms({
                                    ...selectedRooms,
                                    [home?.id]: {
                                      ...selectedRooms[home?.id],
                                      numberOfRooms: value,
                                    },
                                  });
                                }}
                                disabled
                              >
                                {getOptions(home?.apartment_rooms?.length)}
                              </Select>
                            </div>
                          </>
                        ) : home?.apartment_base_price?.toFixed(0) ? (
                          <>
                            <div className="col-5 ">
                              <div className="futuraN700-14">
                                <Button
                                  className="bookN-s futuraN700-14 w-100"
                                  onClick={() => {
                                    reserveRoom(activeModalIndex);

                                    setNumOfRooms(1);
                                    setState({
                                      numberOfRooms: 1,
                                      breakfast: false,
                                      numberOfBreakfast: 0,
                                      internet: false,
                                      parking: false,
                                      pets: false,
                                    });
                                  }}
                                >
                                  ✓
                                </Button>
                              </div>
                            </div>

                            <div className="col-7 pl-0 pr-0">
                              <Select
                                className="selector-ant w-100"
                                style={{ width: 100 }}
                                placeholder="Select room"
                                optionFilterProp="children"
                                defaultValue={
                                  selectedRooms[home?.id]?.numberOfRooms ||
                                  state.numberOfRooms
                                }
                                onChange={(value) =>
                                  updateState("numberOfRooms", value)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {getOptions(home?.apartment_rooms?.length)}
                              </Select>
                            </div>
                          </>
                        ) : (
                          <div onClick={() => setPhonePopUp(true)}>
                            <p>
                              <b>Inquire Now</b>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-auto">
                      <div class="text-right">
                        <p class="futuraBkBtN-14 m-0">
                          Price for {userInfo.diffInDays} night
                        </p>

                        <p class="montserratNB-16 m-0">
                          AED {home?.apartment_base_price?.toFixed(0)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="border-bottom pt-3 pb-3">
                      <ul class="futuraBkBtN-14 p-0">
                        <li>
                          <img src={DoorIcon} alt="" />
                          <span className="ml-2">{home?.apartment_type}</span>
                        </li>

                        <li>
                          <img src={DoorIcon} alt="" />

                          <span
                            className="ml-2"
                            style={{ textTransform: "capitalize" }}
                          >
                            {home?.apartment_smoking_option_select}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ResponsiveAd />

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="border-bottom pt-3 pb-3">
                      <p className="futuraN700-17 m-0">Bed preference</p>

                      <ul class="futuraBkBtN-14 p-0">
                        <li>
                          <img src={BedIcon} alt="" />

                          <span className="ml-2">
                            {beds} {bedType}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="pt-3 pb-3">
                      <p className="futuraN700-17">Facilities</p>

                      <ul class="container_ul futuraBkBtN-14 p-0 pb-5">
                        {home?.apartment_amenities?.map((ame, i) => (
                          <li key={i}>
                            <span
                              className="items"
                              style={{ textTransform: "capitalize" }}
                            >
                              {ame.split("-").join(" ").split("_").join(" ")}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bottom-navigation d-flex align-items-center justify-content-between"
                style={{
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(10px) saturate(180%)",
                }}
              >
                <div className="futuraBkBtN-14 w-50">
                  {getTotalPrice() ? (
                    <>
                      <span className="text-muted">Total price</span>

                      <h5 style={{ fontFamily: "futuraMdBt" }}>
                        AED {getTotalPrice()}
                      </h5>
                    </>
                  ) : (
                    <span className="text-muted">Select a room first</span>
                  )}
                </div>

                <div className="futuraN700-14">
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={open}
                    title={
                      selectedRooms &&
                      Object.keys(selectedRooms).length === 0 &&
                      selectedRooms.constructor === Object
                        ? "You need to select a room first"
                        : "Go to checkout"
                    }
                  >
                    <Button
                      size="large"
                      type="primary"
                      className="bookN HomeReserve"
                      onClick={() => {
                        handleTooltipOpen();
                        history.push(
                          selectedRooms &&
                            Object.keys(selectedRooms).length === 0 &&
                            selectedRooms.constructor === Object
                            ? "#"
                            : "/checkout/home"
                        );
                      }}
                    >
                      Reserve
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div
            className="layoutBody"
            style={{ display: `${showAddon ? "block" : "none"}` }}
          >
            <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                  <div className="">
                    <ul className="futuraBkBtN-14 p-0">
                      <li>
                        <div className="counter">
                          <input
                            id="breakFast"
                            className=""
                            type="checkbox"
                            name=""
                          />

                          <label htmlFor="breakFast" className="pl-5 mr-2">
                            Breakfast at AED 500{" "}
                          </label>

                          <Select
                            style={{ width: 70 }}
                            className="pl-5"
                            placeholder="0"
                            optionFilterProp="children"
                            onChange={onChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                          </Select>
                        </div>
                      </li>

                      <li>
                        <input id="pets" className="" type="checkbox" name="" />

                        <label htmlFor="pets" className="pl-5">
                          {" "}
                          Pets can stay for AED
                        </label>
                      </li>

                      <li>
                        <input
                          id="fullBoard"
                          className=""
                          type="checkbox"
                          name=""
                        />

                        <label htmlFor="fullBoard" className="pl-5">
                          Full board
                        </label>
                      </li>

                      <li>
                        <input
                          id="halfBoard"
                          className=""
                          type="checkbox"
                          name=""
                        />

                        <label htmlFor="halfBoard" className="pl-5">
                          Half board
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className="futuraN700-14">
                    <button className="bookN w-100">Confirm</button>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                <div className="futuraN5-14">
                  <span className="text-darkBlue">Skip to checkout</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal visible={phonePopUp}>
        <div className="p-4 text-center">
          <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
            Would you like to call the host?
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="bookN-s mr-3"
              onClick={() => setPhonePopUp(false)}
            >
              <a href={`tel:${home?.alternate_phone_number}`}>Yes</a>
            </Button>

            <Button className="bookN-s" onClick={() => setPhonePopUp(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EntireHomeRoomDetail;
