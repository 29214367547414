import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "@material-ui/core";
import Calender from "../../icons/calendar.svg";

const HotelBooking = ({ item }) => {
  const getDateString = (checkIn, checkOut) => {
    const checkInDate = moment(checkIn, "YYYY-MM-DD").format("D MMM YYYY");
    let checkOutDate = moment(checkOut, "YYYY-MM-DD").format("D MMM YYYY");

    return checkOut === "0000-00-00"
      ? checkInDate
      : checkInDate + " - " + checkOutDate;
  };

  return (
    <div className="pt-2 pb-3 mx-3">
      <div className="row">
        <div className="col">
          <div className="card" style={{ height: "135px" }}>
            <div className="card-body p-0 h-100">
              <div className="row h-100">
                <div
                  className="col-5 pl-0 d-flex align-items-center justify-content-center"
                  style={{
                    height: "100%",
                  }}
                >
                  <img
                    className="d-block bookingCard-img"
                    style={{ objectFit: "cover", height: "100%" }}
                    src={item.hotel_image[0].image}
                    alt={item.room_type}
                  />
                </div>

                <div className="col-7 p-0 pt-2 pb-2 pl-3">
                  <div className="row">
                    <Link to={`/my_booking/hotel/${item.id}`}>
                      <div class="futuraBold14">
                        <p
                          className="mb-0"
                          style={{
                            color: "#1C2C4A",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "175px",
                          }}
                        >
                          {item.hotel_name}
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div className="row">
                    <div className="futuraBkBtN-12">
                      <p
                        className="mb-1 pt-1"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "150px",
                        }}
                      >
                        {item.hotel_location}
                      </p>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="col pl-0">
                      <p
                        className="mb-0 futuraBkBtN-12 d-flex align-items-center"
                        style={{
                          color: "#1C2C4A",
                          fontWeight: "600",
                        }}
                      >
                        <img
                          className="small_icon mr-1"
                          src={Calender}
                          alt="calender_icon"
                        />

                        <span>
                          {getDateString(item.check_in, item.check_out)}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between pt-2">
                    <div
                      className="col-6 pl-0 pr-0 ml-n3"
                      style={{ fontFamily: "futuraBkBt" }}
                    >
                      <p style={{ whiteSpace: "nowrap" }}>
                        Price{"  "}
                        <span
                          style={{
                            fontFamily: "futuraMdBt",
                            color: "#1C2C4A",
                          }}
                        >
                          {item.customer_currency} {item.total_price}
                        </span>
                      </p>
                    </div>

                    <div className="col-6 pl-0 pr-0">
                      <Link to={`/my_booking/hotel/${item.id}`}>
                        <Button className="bookN-s-view-btn futuraN700-14 view-btn">
                          View
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;
