// Amenities
import NaturalLight from "../../icons/naturalLights.svg";
import Bath from "../../icons/bath.svg";
import Window from "../../icons/window.svg";
import Fridge from "../../icons/fridge.svg";
import Tv from "../../icons/tv.svg";
import Gym from "../../icons/fitness.svg";
import FreeWifi from "../../icons/internet.svg";
import Parking from "../../icons/parkingAm.svg";
import Lake from "../../icons/lake.svg";
import Park from "../../icons/park.svg";
import Elevator from "../../icons/elevator.svg";
import Laundry from "../../icons/laundry.svg";
import Balcony from "../../icons/balcony.svg";
import Wheelchair from "../../icons/wheelchair.svg";
import Terrace from "../../icons/terrace.svg";
import DishWasher from "../../icons/dishwasher.svg";
import Pool from "../../icons/pool.svg";
import Landscape from "../../icons/landscape.svg";
import Squash from "../../icons/tennis.svg";
import TableTennis from "../../icons/table-tennis.svg";
import TennisCourt from "../../icons/tennis-court.svg";

// House
import Smoke from "../../icons/smoking.svg";
import Couple from "../../icons/couple.svg";
import Pets from "../../icons/pets.svg";
import Children from "../../icons/toys.svg";
import Male from "../../icons/mars.svg";
import Female from "../../icons/femenine.svg";
import Mix from "../../icons/mixG.svg";

// Listing
import Private from "../../icons/privateHome.svg";
import Shared from "../../icons/mixG.svg";
import Verify from "../../icons/mixG.svg";

// Recent
import Recent from "../../icons/mixG.svg";
import LTH from "../../icons/mixG.svg";
import HTL from "../../icons/mixG.svg";

const AmenitiesFilters = [
  {
    key: "amenities",
    display: "Amenities",
    type: "checkbox",
    children: [
      {
        key: "room_amenities",
        display: "Room Amenities",
        type: "checkbox",
        room_amenities: [
          {
            key: "hr_wifi",
            display: "Free wi-fi",
            icon: FreeWifi,
            value: false,
          },
          {
            key: "hr_window",
            display: "Window",
            icon: Window,
            value: false,
          },
          {
            key: "hr_tv",
            display: "Tv",
            icon: Tv,
            value: false,
          },
          {
            key: "hr_fridge",
            display: "Fridge",
            icon: Fridge,
            value: false,
          },
          {
            key: "natural_light",
            display: "Natural light",
            icon: NaturalLight,
            value: false,
          },
          {
            key: "attached_bath",
            // display: "Shared bath",
            display: "Attached bath",
            icon: Bath,
            value: false,
          },
        ],
      },
    ],
  },
  {
    key: "amenities",
    display: "Apartment Amenities",
    type: "checkbox",
    children: [
      {
        key: "apartment_amenities",
        display: "Apartment Amenities",
        type: "checkbox",
        apartment_amenities: [
          {
            key: "hr_parking",
            display: "Parking",
            icon: Parking,
            value: false,
          },
          {
            key: "hr_elevator",
            display: "Elevator",
            icon: Elevator,
            value: false,
          },
          {
            key: "dishwasher_amenity",
            display: "Dish washer",
            icon: DishWasher,
            value: false,
          },
          {
            key: "wheelchair_amenity",
            display: "Wheelchair friendly",
            icon: Wheelchair,
            value: false,
          },
          {
            key: "hr_common_balcony",
            display: "Balcony",
            icon: Balcony,
            value: false,
          },
          {
            key: "terrace_amenity",
            display: "Terrace",
            icon: Terrace,
            value: false,
          },
          {
            key: "hr_washing",
            display: "Washing machine",
            icon: Laundry,
            value: false,
          },
        ],
      },
    ],
  },
  {
    key: "amenities",
    display: "Outdoor Amenities",
    type: "checkbox",
    children: [
      {
        key: "outdoor_amenities",
        display: "Outdoor Amenities",
        type: "checkbox",
        outdoor_amenities: [
          {
            key: "",
            display: "Gym",
            icon: Gym,
            value: false,
          },
          {
            key: "hr_pool",
            display: "Pool",
            icon: Pool,
            value: false,
          },
          {
            key: "garden_amenity",
            display: "Garden",
            icon: Landscape,
            value: false,
          },
          {
            key: "",
            display: "Table tennis",
            icon: TableTennis,
            value: false,
          },
          {
            key: "",
            display: "Squash",
            icon: Squash,
            value: false,
          },
          {
            key: "",
            display: "Lawn tennis",
            icon: TennisCourt,
            value: false,
          },
          {
            key: "",
            display: "Lake",
            icon: Lake,
            value: false,
          },

          {
            key: "",
            display: "Park",
            icon: Park,
            value: false,
          },
        ],
      },
    ],
  },
];
const HouseRulesFilters = [
  {
    key: "house_rules",
    display: "House rules",
    type: "checkbox",
    children: [
      {
        key: "house_rules",
        display: "House rules",
        type: "checkbox",
        house_rules: [
          {
            key: "hr_smoking",
            display: "Smoker friendly",
            icon: Smoke,
            value: false,
          },
          {
            key: "hr_couples",
            display: "Couples accepted",
            icon: Couple,
            value: false,
          },
          {
            key: "hr_pets",
            display: "Pets allowed",
            icon: Pets,
            value: false,
          },
          {
            key: "hr_children",
            display: "Children allowed",
            icon: Children,
            value: false,
          },
        ],
      },
      {
        key: "other_house_rules",
        display: "Other House Rules",
        type: "radio",
        other_house_rules: [
          {
            key: "gender",
            display: "strictly male",
            icon: Male,
            value: "M",
          },
          {
            key: "gender",
            display: "strictly female",
            icon: Female,
            value: "F",
          },
          {
            key: "gender",
            display: "Mix",
            icon: Mix,
            value: "M/F",
          },
        ],
      },
    ],
  },
];
const ListingFilters = [
  {
    key: "listing_type",
    display: "Listing type",
    type: "checkbox",
    children: [
      {
        key: "listing_type",
        display: "Listing type",
        type: "checkbox",
        listing_type: [
          {
            key: "room",
            display: "Private room",
            icon: Private,
            value: false,
          },
          {
            key: "bedspace",
            display: "Shared Room",
            icon: Shared,
            value: false,
          },
          {
            key: "verified",
            display: "Verified",
            icon: Verify,
            value: false,
          },
        ],
      },
    ],
  },
];
const SortFilters = [
  {
    key: "sort_by",
    display: "Sort by",
    type: "checkbox",
    children: [
      {
        key: "sort_by",
        display: "Sort by",
        type: "checkbox",
        sort_by: [
          {
            key: "sort_by",
            display: "Recent",
            icon: Recent,
            value: "priceRC",
          },
          {
            key: "sort_by",
            display: "Price: low to high",
            icon: LTH,
            value: "priceLH",
          },
          {
            key: "sort_by",
            display: "Price: high to low",
            icon: HTL,
            value: "priceHL",
          },
        ],
      },
    ],
  },
];

const HotelAmenities = [
  {
    key: "amenities",
    display: "Amenities",
    type: "checkbox",
    children: [
      {
        key: "room_amenities",
        display: "Room",
        room_amenities: [
          {
            key: "private_pool",
            display: "Private Pool",
          },
          {
            key: "private bathroom",
            display: "private bathroom",
          },
          {
            key: "balcony",
            display: "Balcony",
          },
          {
            key: "terrace",
            display: "Terrace",
          },
          {
            key: "sofa_bed",
            display: "Sofa Bed",
          },
          {
            key: "free_toileteries",
            display: "Free Toileteries",
          },
          {
            key: "dining_area",
            display: "Dining Area",
          },
        ],
      },
      {
        key: "hotel_amenities_food",
        display: "Hotel Food",
        hotel_amenities_food: [
          {
            key: "Dining Area",
            display: "Dining Area",
          },
          {
            key: "Barbeque",
            display: "Barbeque",
          },
          {
            key: "Outdoor",
            display: "Outdoor Dining Area",
          },
        ],
      },
      {
        key: "hotel_amenities_entertainment",
        display: "Entertainment",
        hotel_amenities_entertainment: [
          {
            key: "Board Games/Puzzles",
            display: "Dining Area",
          },
          {
            key: "Books,DVDs or music for children",
            display: "Books,DVDs or music for children",
          },
          {
            key: "Child Safety Socket Covers",
            display: "Child Safety Socket Covers",
          },
          {
            key: "Game Console",
            display: "Game Console",
          },
        ],
      },
      {
        key: "hotel_amenities_services",
        display: "Services",
        hotel_amenities_services: [
          {
            key: "Executive Lounge Access",
            display: "Executive Lounge Access",
          },
        ],
      },
    ],
  },
];
const HotelFacilities = [
  {
    key: "facilities_and_services",
    display: "Facilities",
    type: "checkbox",
    children: [
      {
        key: "facilities_array",
        display: "Facilities",
        facilities_array: [
          {
            key: "room_service",
            display: "Room Service",
          },
          {
            key: "bar",
            display: "Bar",
          },
          {
            key: "fitness-centre",
            display: "Fitness Centre",
          },
          {
            key: "pool",
            display: "Swimming Pool",
          },
          {
            key: "garden",
            display: "Garden",
          },
          {
            key: "beach",
            display: "Beach",
          },
          {
            key: "smoking-rooms",
            display: "Smoking Rooms",
          },
          {
            key: "shuttle",
            display: "Airport shuttle",
          },
          {
            key: "delivery",
            display: "Delivery Go",
          },
        ],
      },
    ],
  },
];
const HotelAddOn = [
  {
    key: "adons",
    display: "Ad-ons",
    type: "checkbox",
    children: [
      {
        key: "internet",
        display: "",
        internet: [
          {
            key: "free",
            display: "Free Internet",
          },
        ],
      },
      {
        key: "parking",
        display: "",
        parking: [
          {
            key: "free",
            display: "Free Parking",
          },
        ],
      },
      {
        key: "breakfast",
        display: "",
        breakfast: [
          {
            key: "free",
            display: "Free Breakfast",
          },
        ],
      },
      {
        // "key": "lounge",
        // "display": "",
        // "lounge": [
        //     {
        //         "key": "free",
        //         "display": "Executive Lounge Access"
        //     }
        // ]
      },
    ],
  },
];
const HotelRating = [
  {
    key: "hotel_rating",
    display: "Rating",
    type: "checkbox",
    children: [
      {
        key: "hotel_rating",
        display: "Rating",
        type: "checkbox",
        hotel_rating: [
          {
            key: "",
            display: "1 star",
            value: false,
          },
          {
            key: "",
            display: "2 star",
            value: false,
          },
          {
            key: "",
            display: "3 star",
            value: false,
          },
          {
            key: "",
            display: "4 star",
            value: false,
          },
          {
            key: "",
            display: "5 star",
            value: false,
          },
        ],
      },
    ],
  },
];
const HotelSortby = [
  {
    key: "sort-by",
    display: "Sort-by",
    type: "radio",
    children: [
      {
        key: "price",
        display: "",
        price: [
          {
            key: "sort-by",
            display: "Sort By Price",
          },
        ],
      },
      {
        key: "distance",
        display: "",
        distance: [
          {
            key: "sort-by",
            display: "Sort by Distance",
          },
        ],
      },
      {
        key: "booking",
        display: "",
        booking: [
          {
            key: "sort-by",
            display: "Sort by Booking (Recomended)",
          },
        ],
      },
    ],
  },
];

export {
  AmenitiesFilters,
  HouseRulesFilters,
  ListingFilters,
  SortFilters,
  HotelAmenities,
  HotelFacilities,
  HotelAddOn,
  HotelRating,
  HotelSortby,
};
