import React, { useContext, useState,useEffect } from "react";
import axios from "axios";
import { DatePicker, Button, Space, message,Select } from "antd";
import { AppContext } from '../../AppContext';
// import Counter  from "../Counter";
import app from '../../utils/appConfig'
import { months } from "moment";
export default function StepFive({ nextStep ,user }) 
{
    const { userInfo,editCalendarDetail,editHomeDetail } = useContext(AppContext);
    const [fifthStepInfo, setFifthStepInfo] = useState({
        hotels:[],
        hotel_id:user.hotel_id,
        hotel_type:"",
        selectedHotel:'',
        hotel_name:"",
        hotel_currency:'',
        type:"",
        room_rent_tax:"yes",
        hotel_rooms:[],
        hotel_apartments:[],
        hotel_room_id:"",
        hotel_apartment_id:user.hotel_apartment_id,
        month_getter: new Date(),
        current_month:"",
        year_getter: new Date(),
        current_year:"",
        next_year:"",
        months:[],
        bi_months:[],
        week_months:[],
        months_left:"",
        month_id:0,
        average_rate:"",
        can_edit:false,
      });
     
      useEffect(() => {
      
       let current_month = fifthStepInfo.month_getter.getMonth();
       let current_year = fifthStepInfo.year_getter.getFullYear();
       let next_year = current_year + 1;
       let leap='';
        if(current_month==1 ){
            let answer;
            let year =current_year;
            if ((year % 4 === 0 && year % 100 != 0) || year % 400 === 0) {
                leap = '29 Feb';
            } else {
                leap = '28 Feb';
            }
        }else{
            let answer;
            let year =next_year;
            if ((year % 4 === 0 && year % 100 != 0) || year % 400 === 0) {
                leap = '29 Feb';
            } else {
                leap = '28 Feb';
            }
        }

        var  months = [
            {date: '1 Jan - 31 Jan', average_rate:'', rate_type:'', year:''},
            {date: `1 Feb - ${leap}`, average_rate:'', rate_type:'', year:''},
            {date: '1 Mar - 31 Mar', average_rate:'', rate_type:'', year:''},
            {date: '1 Apr - 30 Apr', average_rate:'', rate_type:'', year:''},
            {date: '1 May - 31 May', average_rate:'', rate_type:'', year:''},
            {date: '1 Jun - 30 Jun', average_rate:'', rate_type:'', year:''},
            {date: '1 Jul - 31 Jul', average_rate:'', rate_type:'', year:''},
            {date: '1 Aug - 31 Aug', average_rate:'', rate_type:'', year:''},
            {date: '1 Sep - 30 Sep', average_rate:'', rate_type:'', year:''},
            {date: '1 Oct - 31 Oct', average_rate:'', rate_type:'', year:''},
            {date: '1 Nov - 30 Nov', average_rate:'', rate_type:'', year:''},
            {date: '1 Dec - 31 Dec', average_rate:'', rate_type:'', year:''}
        ];
       var bi_months = [
            {start: '1 Jan - 15 Jan', end: '16 Jan - 31 Jan', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
            {start: '1 Feb - 15 Feb', end: `16 Feb - ${leap}`, rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 14,},
            {start: '1 Mar - 15 Mar', end: '16 Mar - 31 Mar', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
            {start: '1 Apr - 15 Apr', end: '16 Apr - 30 Apr', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 15,},
            {start: '1 May - 15 May', end: '16 May - 31 May', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
            {start: '1 Jun - 15 Jun', end: '16 Jun - 30 Jun', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 15,},
            {start: '1 Jul - 15 Jul', end: '16 Jul - 31 Jul', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
            {start: '1 Aug - 15 Aug', end: '16 Aug - 31 Aug', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 15,},
            {start: '1 Sep - 15 Sep', end: '16 Sep - 30 Sep', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
            {start: '1 Oct - 15 Oct', end: '16 Oct - 31 Oct', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
            {start: '1 Nov - 15 Nov', end: '16 Nov - 30 Nov', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 15,},
            {start: '1 Dec - 15 Dec', end: '16 Dec - 31 Dec', rate_start:'', rate_end:'', year:'', days_first_half: 15, days_second_half: 16,},
        ];

        var week_months = [
            {a: '1 Jan - 7 Jan', b: '8 Jan - 14 Jan', c: '15 Jan - 21 Jan', d: '22 Jan - 31 Jan', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10},
            {a: '1 Feb - 7 Feb', b: '8 Feb - 14 Feb', c: '15 Feb - 21 Feb', d: `22 Feb - ${leap}`, rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 8},
            {a: '1 Mar - 7 Mar', b: '8 Mar - 14 Mar', c: '15 Mar - 21 Mar', d: '22 Mar - 31 Mar', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10},
            {a: '1 Apr - 7 Apr', b: '8 Apr - 14 Apr', c: '15 Apr - 21 Apr', d: '22 Apr - 30 Apr', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 9},
            {a: '1 May - 7 May', b: '8 May - 14 May', c: '15 May - 21 May', d: '22 May - 31 May', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10},
            {a: '1 Jun - 7 Jun', b: '8 Jun - 14 Jun', c: '15 Jun - 21 Jun', d: '22 Jun - 30 Jun', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 9 },
            {a: '1 Jul - 7 Jul', b: '8 Jul - 14 Jul', c: '15 Jul - 21 Jul', d: '22 Jul - 31 Jul', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10},
            {a: '1 Aug - 7 Aug', b: '8 Aug - 14 Aug', c: '15 Aug - 21 Aug', d: '22 Aug - 31 Aug', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10 },
            {a: '1 Sep - 7 Sep', b: '8 Sep - 14 Sep', c: '15 Sep - 21 Sep', d: '22 Sep - 30 Sep', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 9},
            {a: '1 Oct - 7 Oct', b: '8 Oct - 14 Oct', c: '15 Oct - 21 Oct', d: '22 Oct - 31 Oct', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10},
            {a: '1 Nov - 7 Nov', b: '8 Nov - 14 Nov', c: '15 Nov - 21 Nov', d: '22 Nov - 30 Nov', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 9 },
            {a: '1 Dec - 7 Dec', b: '8 Dec - 14 Dec', c: '15 Dec - 21 Dec', d: '22 Dec - 31 Dec', rate_a:'', rate_b:'', rate_c:'', rate_d:'', year:'', days_a: 7 , days_b: 7, days_c: 7, days_d: 10},
        ];


      

        let months_left = 12 - current_month;
        for(let month in months){
            if(current_month > month) {
                months[month].year = next_year;
            }else{
                months[month].year = current_year;
            }

        }

        if(fifthStepInfo.months) {
            setFifthStepInfo({
                ...fifthStepInfo,
                bi_months: bi_months, 
                months: months ,
                week_months: week_months,
                current_year:current_year,
                current_month:current_month,
                next_year:next_year,
                months_left:months_left,
                hotel_id:user.hotel_id,
                hotel_apartment_id:user.hotel_apartment_id
               
            });

              if(editCalendarDetail[0])
        {
          
        
         for(let i in editCalendarDetail){
            months[i].id=editCalendarDetail[i].id;
            months[i].average_rate = editCalendarDetail[i].general_average_rate;
            // months[i].year = editCalendarDetail[i].year;
            months[i].rate_type = editCalendarDetail[i].rate_type;
            bi_months[i].rate_start = editCalendarDetail[i].bi_rate_a;
            bi_months[i].rate_end = editCalendarDetail[i].bi_rate_b;
            week_months[i].rate_a = editCalendarDetail[i].week_rate_a;
            week_months[i].rate_b = editCalendarDetail[i].week_rate_b;
            week_months[i].rate_c = editCalendarDetail[i].week_rate_c;
            week_months[i].rate_d = editCalendarDetail[i].week_rate_d;
            
         }
        }
        }
    
   console.log(userInfo);
      },[editCalendarDetail]);
      function updateCalendar(property,value){
        setFifthStepInfo({...fifthStepInfo, [property]: value});
      }
    function replicate_price(property,price)
    {
        var year_months=fifthStepInfo.months;
        for(let month in year_months){
            if(year_months[month].date === property){
            year_months[month].average_rate = price;
            }
            
         }
         console.log("monthly",year_months);
         setFifthStepInfo({...fifthStepInfo, months: year_months});
    }
    function submit_calendar()
    {
        let data = {
             "hotel_type": fifthStepInfo.hotel_type, 
             "hotel_room_id":fifthStepInfo.hotel_room_id,
             "hotel_apartment_id":fifthStepInfo.hotel_apartment_id,
             "hotel_deal_id":fifthStepInfo.hotel_deal_id,
             "room_rent_tax":fifthStepInfo.room_rent_tax,
             "months":fifthStepInfo.months,
             "bi_months":fifthStepInfo.bi_months,
             "week_months":fifthStepInfo.week_months,
         };

         let uri = 'https://home.roomdaddy.com/public/api/save_calendar';
        
         axios.post(uri,data).then(response => {
            message.success('Processing complete!');
            window.location = "/thank-you"
         });
     }
    return (
        <>
            
                <div className="container">
                    <div className="row">
                        <div className="col">
                            
                            
                           
                        {fifthStepInfo.months?.map((item, index) => {
                            if(index>=(12 - fifthStepInfo.months_left)){
                               return( 
                                        <div className="form-group floating-form-group">
                                            <label className="">Rate for {item.date}</label>
                                            <div className="custom-location-input">
                                                <input type="number"
                                                    onChange={ e=> replicate_price(item.date, e.target.value)}
                                                    className="form-control floating-input"
                                                    value={item.average_rate}
                                                />
                                            </div>
                                        
                                        </div>
                               )}
                             
                           })}

                           {fifthStepInfo.months?.map((item, index) => {
                            if(index<(12 - fifthStepInfo.months_left)){
                               return( 
                                        <div className="form-group floating-form-group">
                                            <label className="">Rate for {item.date}</label>
                                            <div className="custom-location-input">
                                                <input type="number"
                                                    onChange={ e=> replicate_price(item.date, e.target.value)}
                                                    className="form-control floating-input"
                                                    value={item.average_rate}
                                                />
                                            </div>
                                        
                                        </div>
                               )}
                             
                           })}
                                           
                           
                        </div>
                    </div>
                </div>
                <Button type="primary" onClick={submit_calendar}>Done</Button>
            {/* </form> */}
        </>
    )
}