import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {  WingBlank } from "antd-mobile";
import Carousel from 'nuka-carousel';
import { message, Select } from "antd";
import axios from "axios";
import app from "../utils/appConfig";
import Back from "../icons/back.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
import { AppContext } from "../AppContext";
import { Button, Tooltip } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import RdLoader from "../_newComponents/RdLoader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import { removeLsValue, setLsValue } from "../../utils/functions";
import { CACHE__H_APTS_CART } from "../data/cacheKeys";
import "./style/hotelApartmentRoomDetail.scss";

export default function HotelApartmentRoomDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Option } = Select;

  const history = useHistory();
  const { id, roomId } = useParams();
  const {
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [apt, setApt] = useState({});
  const [rooms, setRooms] = useState([]);
  const [beds, setBeds] = useState(0);
  const [bedType, setBedType] = useState(0);
  const [gallery, setGallery] = useState([]);
  const [showAddon, setShowAddon] = useState(false);
  const [activeApt, setActiveApt] = useState(-1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [state, setState] = useState({
    numberOfRooms: numOfRooms,
    breakfast: false,
    numberOfBreakfast: 0,
    internet: false,
    parking: false,
    pets: false,
  });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const selectAddon = () => setShowAddon(true);
  const handleBack = () => setShowAddon(false);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  function updateState(name, value) {
    setState({ ...state, [name]: value });
  }

  function getRoomPrice(index) {
    if (rooms) {
      let tax = 0;

      if (
        rooms[index].apartment_rent_type &&
        rooms[index].apartment_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(apt.hotel_tax) / 100) * rooms[index].apartment_base_price;
        tax = tax + rooms[index].apartment_base_price + apt.hotel_tax_amount;
      } else {
        tax = rooms[index].apartment_base_price;
      }

      return Math.ceil(tax);
    }
  }

  function updatePrice() {
    if (rooms.hotel_active_rooms) {
      let total = getRoomPrice(activeModalIndex);
      total = total * state.numberOfRooms;

      if (state.breakfast) {
        let bfastPrice = Number(rooms.services.optional_breakfast_price);
        total = total + bfastPrice * state.numberOfBreakfast;
      }

      setTotalPrice(total);
      return total;
    }
  }

  function clearRoom(id) {
    let temp = selectedRooms;
    delete temp[id];

    setSelectedRooms(temp);
    setState({
      numberOfRooms: 1,
      breakfast: false,
      numberOfBreakfast: 0,
      internet: false,
      parking: false,
      pets: false,
    });

    if (Object.keys(temp).length) {
      const cart = {
        selectedRooms: temp,
        userInfo,
        state,
      };

      setLsValue(CACHE__H_APTS_CART, cart);
    } else {
      removeLsValue(CACHE__H_APTS_CART);
    }
  }

  function reserveRoom(index) {
    let sA = rooms[index];

    setActiveModalIndex(index);
    setSelectedRooms({
      ...selectedRooms,
      [sA.id]: {
        ...state,
        hotelName: apt.hotel_name,
        hotelId: sA.hotel_id,
        roomId: sA.id,
        roomName: sA.apartment_room_name,
        roomType: sA.apartment_type,
        roomSize: sA.apartment_size,
        checkIn: userInfo.checkIn,
        checkOut: userInfo.checkOut,
        currency: apt.hotel_currency,
        // totalAmmount: updatePrice(),
        totalAmmount: getRoomPrice(index) * state.numberOfRooms,
      },
    });

    getTotalPrice();
  }

  function getTotalPrice() {
    let price = 0;
    Object.keys(selectedRooms).map((elem) => {
      price += selectedRooms[elem].totalAmmount;
    });
    return price;
  }

  useEffect(() => {
    axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_apart_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        setRooms(res.data[0].apartments);

        res.data[0].apartments.forEach((room, i) => {
          if (room.id == roomId) {
            setApt(room);
            setActiveApt(i);

            const images = [];
            room.apartment_rooms?.forEach((r) => {
              if ("beds" in r) {
                setBeds(r.beds[0].kind_of_beds_number);
                setBedType(r.beds[0].kind_of_beds);
              }

              images.push(...r.room_image);
            });

            setGallery(images);
            setLoading(false);
          }
        });
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  }, []);

  function getOptions(e) {
    let elem = [];
    for (let i = 1; i <= parseInt(e); i++)
      elem.push(
        <Option value={i} key={i}>
          {i} {i === 1 ? "room" : "rooms"}
        </Option>
      );

    return elem;
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="header screen-header pt-3 pb-3" style={{ zIndex: "999" }}>
        <div style={{ display: `${showAddon ? "none" : "block"}` }}>
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                className=""
                src={Back}
                alt="back_Arrow"
                onClick={() => {
                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost)
                    history.push(`/hotel-apartment/detail/${id}/rooms`);
                  else history.goBack();
                }}
              />
            </div>
            <div className="col">
              <div className="futuraN700-17">Room detail</div>
            </div>
          </div>
        </div>

        <div style={{ display: `${showAddon ? "block" : "none"}` }}>
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                className=""
                src={Back}
                alt="back_Arrow"
                onClick={handleBack}
              />
            </div>
            <div className="col">
              <div className="futuraN700-17">Choose addons</div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <RdLoader />
      ) : (
        <>
          <div
            className="layoutBody"
            style={{ display: `${showAddon ? "none" : "block"}` }}
          >
            <WingBlank>
              <Carousel autoplay={false} renderCenterLeftControls={()=>null} renderCenterRightControls={()=>null} wrapAround={true}>
                {gallery.map((item, i) => (
                  <img
                    key={i}
                    className="d-block w-100"
                    src={item.image}
                    style={{ width: "100%", verticalAlign: "top" }}
                    alt="RoomDaddy"
                  />
                ))}
              </Carousel>
            </WingBlank>

            <div className="container-fluid p-0">
              <div className="container">
                <div className="border-bottom pt-3 pb-3">
                  <div className="montserratN900-22">
                    <p className="m-0">{apt?.apartment_room_name}</p>
                  </div>

                  <div className="futuraBkBtN-14">
                    <p className="m-0">
                      Size: {apt?.apartment_size} ft<sup>2</sup>
                    </p>
                  </div>
                </div>

                <div className="border-bottom pt-3 pb-3">
                  <div className="row justify-content-between">
                    <div className="col-7">
                      <div className="row align-items-center justify-content-between">
                        {selectedRooms[apt?.id] ? (
                          <>
                            <div className="col-5">
                              <div className="futuraN700-14">
                                <Button
                                  className="bookDanger w-100"
                                  onClick={() => clearRoom(apt?.id)}
                                >
                                  ✗
                                </Button>
                              </div>
                            </div>

                            <div className="col-7 pl-0 pr-0">
                              <Select
                                className="selector-ant w-100"
                                defaultValue={
                                  selectedRooms[apt?.id].numberOfRooms
                                }
                                onChange={(value) => {
                                  updateState("numberOfRooms", value);
                                  setSelectedRooms({
                                    ...selectedRooms,
                                    [apt?.id]: {
                                      ...selectedRooms[apt?.id],
                                      numberOfRooms: value,
                                    },
                                  });
                                }}
                                disabled
                              >
                                {getOptions(apt?.apartment_rooms?.length)}
                              </Select>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-5 ">
                              <div className="futuraN700-14">
                                <Button
                                  className="bookN-s futuraN700-14 w-100"
                                  onClick={() => {
                                    reserveRoom(activeModalIndex);

                                    setNumOfRooms(1);
                                    setState({
                                      numberOfRooms: 1,
                                      breakfast: false,
                                      numberOfBreakfast: 0,
                                      internet: false,
                                      parking: false,
                                      pets: false,
                                    });
                                  }}
                                >
                                  ✓
                                </Button>
                              </div>
                            </div>

                            <div className="col-7 pl-0 pr-0">
                              <Select
                                className="selector-ant w-100"
                                style={{ width: 100 }}
                                placeholder="Select room"
                                optionFilterProp="children"
                                defaultValue={
                                  selectedRooms[apt?.id]?.numberOfRooms ||
                                  state.numberOfRooms
                                }
                                onChange={(value) =>
                                  updateState("numberOfRooms", value)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {getOptions(apt?.apartment_rooms?.length)}
                              </Select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-auto">
                      <div class="text-right">
                        <p class="futuraBkBtN-14 m-0">
                          Price for {userInfo.diffInDays} night
                        </p>

                        <p class="montserratNB-16 m-0">
                          AED {apt?.apartment_base_price?.toFixed(0)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="border-bottom pt-3 pb-3">
                      <ul class="futuraBkBtN-14 p-0">
                        <li>
                          <img src={DoorIcon} alt="" />
                          <span className="ml-2">{apt?.apartment_type}</span>
                        </li>

                        <li>
                          <img src={DoorIcon} alt="" />
                          <span
                            className="ml-2"
                            style={{ textTransform: "capitalize" }}
                          >
                            {apt?.apartment_smoking_option_select}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ResponsiveAd />

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="border-bottom pt-3 pb-3">
                      <p className="futuraN700-17 m-0">Bed preference</p>

                      <ul class="futuraBkBtN-14 p-0">
                        <li>
                          <img src={BedIcon} alt="" />
                          <span className="ml-2">
                            {beds} {bedType}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="container">
            <div
              className="border-bottom pt-3 pb-3"
              // style={{ padding: "0 15px" }}
            >
              <div className="futuraBkBtN-14 mb-2">
                <p>
                  Only {apt?.apartment_rooms?.length} rooms left on
                  Roomdaddy.com
                </p>
              </div>

              <div className="row align-items-center justify-content-between">
                {selectedRooms[apt?.id] ? (
                  <>
                    <div className="col-7">
                      <Select
                        className="selector-ant w-100"
                        defaultValue={selectedRooms[apt?.id].numberOfRooms}
                        onChange={(value) => {
                          updateState("numberOfRooms", value);
                          setSelectedRooms({
                            ...selectedRooms,
                            [apt?.id]: {
                              ...selectedRooms[apt?.id],
                              numberOfRooms: value,
                            },
                          });
                        }}
                        disabled
                      >
                        {getOptions(apt?.apartment_rooms?.length)}
                      </Select>
                    </div>

                    <div className="col-5">
                      <div className="futuraN700-14">
                        <Button
                          className="bookDanger w-100"
                          onClick={() => clearRoom(apt?.id)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-7">
                      <Select
                        className="selector-ant w-100"
                        style={{ width: 100 }}
                        placeholder="Select room"
                        optionFilterProp="children"
                        defaultValue={
                          selectedRooms[apt?.id]?.numberOfRooms ||
                          state.numberOfRooms
                        }
                        onChange={(value) =>
                          updateState("numberOfRooms", value)
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {getOptions(apt?.apartment_rooms?.length)}
                      </Select>
                    </div>

                    <div className="col-5">
                      <div className="futuraN700-14">
                        <Button
                          className="bookN-s futuraN700-14 w-100"
                          onClick={() => {
                            reserveRoom(activeApt);

                            setNumOfRooms(1);
                            setState({
                              numberOfRooms: 1,
                              breakfast: false,
                              numberOfBreakfast: 0,
                              internet: false,
                              parking: false,
                              pets: false,
                            });
                          }}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div> */}

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="pt-3 pb-3">
                      <p className="futuraN700-17">Facilities</p>

                      <ul class="container_ul futuraBkBtN-14 p-0 pb-5">
                        {apt?.apartment_amenities?.map((ame, i) => (
                          <li key={i}>
                            <span
                              className="items"
                              style={{ textTransform: "capitalize" }}
                            >
                              {ame}
                            </span>
                          </li>
                        ))}

                        {/* <li>
                      <span className="items">Terracce</span>
                    </li>
                    <li>
                      <span className="items">Hair dryer</span>
                    </li>
                    <li>
                      <span className="items">Oven</span>
                    </li>
                    <li>
                      <span className="items">Linen</span>
                    </li>
                    <li>
                      <span className="items">Bed</span>
                    </li>
                    <li>
                      <span className="items">Balcony</span>
                    </li>
                    <li>
                      <span className="items">Hair dryer</span>
                    </li>
                    <li>
                      <span className="items">Oven</span>
                    </li>
                    <li>
                      <span className="items">Linen</span>
                    </li>
                    <li>
                      <span className="items">Bed</span>
                    </li>
                    <li>
                      <span className="items">Balcony</span>
                    </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bottom-navigation d-flex align-items-center justify-content-between"
                style={{
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(10px) saturate(180%)",
                }}
              >
                <div className="futuraBkBtN-14 w-50">
                  {getTotalPrice() ? (
                    <>
                      <span className="text-muted">Total price</span>
                      <h5 style={{ fontFamily: "futuraMdBt" }}>
                        AED {getTotalPrice()}
                      </h5>
                    </>
                  ) : (
                    <span className="text-muted">Select a room first</span>
                  )}
                </div>

                <div className="futuraN700-14">
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={open}
                    title={
                      selectedRooms &&
                      Object.keys(selectedRooms).length === 0 &&
                      selectedRooms.constructor === Object
                        ? "You need to select a room first"
                        : "Go to checkout"
                    }
                  >
                    <Button
                      size="large"
                      id="hotel-aprt-mob-reserve"
                      type="primary"
                      className="bookN"
                      onClick={() => {
                        handleTooltipOpen();
                        history.push(
                          selectedRooms &&
                            Object.keys(selectedRooms).length === 0 &&
                            selectedRooms.constructor === Object
                            ? "#"
                            : apt.services.breakfast === "optional" ||
                              apt.services.internet === "paid" ||
                              apt.services.parking === "paid" ||
                              apt.policies.allow_pets === "yes"
                            ? `/hotel/detail/${id}/addons`
                            : "/checkout"
                        );
                      }}
                    >
                      Reserve
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div
            className="layoutBody"
            style={{ display: `${showAddon ? "block" : "none"}` }}
          >
            <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                  <div className="">
                    <ul className="futuraBkBtN-14 p-0">
                      <li>
                        <div className="counter">
                          <input
                            id="breakFast"
                            className=""
                            type="checkbox"
                            name=""
                          />
                          <label for="breakFast" className="pl-5 mr-2">
                            Breakfast at AED 500{" "}
                          </label>
                          <Select
                            style={{ width: 70 }}
                            className="pl-5"
                            placeholder="0"
                            optionFilterProp="children"
                            onChange={onChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                          </Select>
                        </div>
                      </li>

                      <li>
                        <input id="pets" className="" type="checkbox" name="" />
                        <label for="pets" className="pl-5">
                          {" "}
                          Pets can stay for AED
                        </label>
                      </li>

                      <li>
                        <input
                          id="fullBoard"
                          className=""
                          type="checkbox"
                          name=""
                        />
                        <label for="fullBoard" className="pl-5">
                          Full board
                        </label>
                      </li>

                      <li>
                        <input
                          id="halfBoard"
                          className=""
                          type="checkbox"
                          name=""
                        />
                        <label for="halfBoard" className="pl-5">
                          Half board
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className="futuraN700-14">
                    <button className="bookN w-100">Confirm</button>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                <div className="futuraN5-14">
                  <span className="text-darkBlue">Skip to checkout</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
