import React from 'react'
import Header from "../../components/pageHeader/index"
import data from "./faqData"
import { JsonLd } from "react-schemaorg";
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function FAQGQ() {
    
    return (
        <>
            <Header
                arrowLink={'/'}
                heading={"Frequently Asked Questions"}
            />
            <main className="flex-shrink-0">
                <div className="container">
                    <h3>General Questions</h3>

                    <JsonLd
      item={{
        "@context":"http://schema.org",
        "@type":"FAQPage",
        "mainEntity" : [
          {
          "name" : "What is meant by extended stay hotel?",
          "acceptedAnswer" :
          {
          "text" : "A hotel with an extended stay programme provides customers with long-term accommodation on a special rate. It may in some cases come with extra services like in-suite kitchens and self-serve laundry, which make them a useful and cost-effective alternative.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "How long is the maximum stay length in an extended-stay hotel?",
          "acceptedAnswer" :
          {
          "text" : "Hotel prices and demand is season, so they may change prices accordingly. However, many hotels in UAE can offer yearly rates as well. This is best for companies and big corporates who have rotational staff always coming and going. For shorter stays it is monthly based and can lead up to a yearly contract. Monthly bookers have the risk of hotels closing down on Extended stay during peak seasons.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "Why is a month-long hotel stay called extended-stay?",
          "acceptedAnswer" :
          {
          "text" : "It is called so because families on vacation, business travellers, those moving to a new city, and anybody else seeking for a hotel can stay for a period while they find lodging alternatives. In most cases, hotel accommodations for extended stay customers are reserved a month, or longer.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "What sort of hotel offers extended stays?",
          "acceptedAnswer" :
          {
          "text" : "UAE has a great Hotel, and Hotel apartment infrastructure. Which constantly is bringing more and more available keys in the market. During summers while the demand slows down with exception of Luxury Hotels, like Atlantis, Four Seasons etc. Almost all the hotels in the lower tier accept the Extended stay and are good on pocket for travellers and guests.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
            "name" : "Why do people choose extended-stay hotel?",
            "acceptedAnswer" :
            {
            "text" : "People opt extended-stay hotel because they have a more home feel than a hotel. The fact that extended stay hotels provide long-term lodging makes them not only more affordable than booking a hotel room, but also far more convenient, with no commitment to sign up lease and setting up the furnishing.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How can a hotel for extended stays feel like home?",
            "acceptedAnswer" :
            {
            "text" : "Making Extended Stay Hotels Feel More Like Home: Fill the refrigerator with wholesome snacks, bring along some familiar items, unpack, establish a daily routine, and so on. Get some fresh air, keep your room tidy, listen to music, and contact us to find extended stay hotels nearby.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Does Dubai's rent include utilities?",
            "acceptedAnswer" :
            {
            "text" : "Rental agreement decides upon who pays the utilities. However, most of the short term (monthly) rentals the bills and utilities are included in the price. So what you pay is exactly your monthly rent. For yearlong lease the utilities are paid by the tenant almost in every contract with few exceptions.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is Dewa's monthly cost?",
            "acceptedAnswer" :
            {
            "text" : "Up to 2000 kWh of usage each month costs AED 0.23 (USD 0.063) 2001-4000 kWh: 0.28 AED (USD 0.076) 4001-6000 kWh: 0.32 AED (USD 0.087) More than 6001 kWh - AED 0.38 (USD 0.10)",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is the DEWA connection charges?",
            "acceptedAnswer" :
            {
            "text" : "Moving into a new property, the tenant has to apply for a new connection which has a non-refundable AED 110 DEWA activation charge (for small meters). The security deposit is AED 2,000 for apartments and AED 4,000 for villas.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In Dubai, is water a paid service?",
            "acceptedAnswer" :
            {
            "text" : "The DEWA bill comprises charges for sewage, housing, water, and electricity. The housing fee from the Dubai Municipality is paid over a twelve-month period and equals 5% of the annual property rent.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How costly is electricity in the UAE?",
            "acceptedAnswer" :
            {
            "text" : "The United Arab Emirates (UAE), with an average cost of 0.08 U.S. dollars per kilowatt-hour of electricity, has the highest electricity price in the Gulf Cooperation Council (GCC). The cost of electricity in Qatar is the lowest in the neighborhood.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which utilities are provided in an apartment?",
            "acceptedAnswer" :
            {
            "text" : "The essential services that your house, apartment, or place of work needs to remain comfortable and functional are known as utilities. Water, sewer, power, gas, garbage, and recycling are examples of common utilities. Utility subscriptions might also include things like cable TV, internet, security, and phone service.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which Dubai areas are the cheapest?",
            "acceptedAnswer" :
            {
            "text" : "Dubai's five most cheapest neighborhoods: 1- International City The area with the lowest rent. 2- Deira A better option for those on a tight budget. 3- Silicon Oasis. 4- Sports City. 5- Jumeirah village circle (JVC)",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In which UAE city is rent the cheapest?",
            "acceptedAnswer" :
            {
            "text" : "According to information from Trends report, Ajman has the lowest rental expenses in the UAE, with rates three times lower than Dubai's median asking price.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Where can I stay the cheapest in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "International City is one. For good reason, International City is at the top of the UAE's list of communities with low rents.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which area of Dubai is the best for lodging?",
            "acceptedAnswer" :
            {
            "text" : "Downtown Dubai is the finest neighbourhood to stay in if you're a first-time visitor or a business traveller visiting Dubai. The Burj Khalifa, the Dubai Mall, and the renowned Dubai Fountain are just a few of the main attractions in Dubai that can be found in the Downtown area.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Where can I get great value for lodging in the UAE?",
            "acceptedAnswer" :
            {
            "text" : "1- Delta Hotels by Marriott Jumeirah Beach is one of the most popular reasonably priced hotels in Jumeirah Beach Residence Hotel. 2- Millennium Place Dubai Marina Inexpensive hotel in Dubai Marina. 3- Ramada by Wyndham JBR. City Seasons. 4- ROVE Hotels",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What are common areas to find singles in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "1- Apartments at Palm Jumeirah. 2- Dubai Downtown Apartments. 3- Apartments in Dubai Hills. 4- Apartments in JBR, Marina and JLT.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which emirate is the richest?",
            "acceptedAnswer" :
            {
            "text" : "Abu Dhabi is the richest emirate in the UAE as a result of its abundant oil resources. It's one of the richest cities in the world, in fact!",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which UAE emirate is the best place to call home? Which Workplace?",
            "acceptedAnswer" :
            {
            "text" : "The UAE's top three emirates: 1- Dubai Given that Dubai is regarded as the nation's business hub, it should not be a surprise that it ranks at the top of this list. 2- Abu Dhabi Although it's still considerably less well-known than Dubai, Abu Dhabi is probably the second most popular option for expats after Ajman. 3- Ajman Ajman is the cheapest place to rent property in the UAE.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is the price of a bedspace in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "The cost of a bedspace can range from AED 400 to AED 2500 per month. The number of people using the room determines the pricing in most cases. A bedspace might cost as little as AED 400 in Deira, Bur Dubai, yet it can cost as much as AED 2500 in Dubai Marina.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is meant by bedspace?",
            "acceptedAnswer" :
            {
            "text" : "Any floor area, bed, bunk, or sleeping facility utilized or intended to be used as sleeping accommodation for a person while is referred to as a 'bedspace'.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Are rented beds permitted in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "In Dubai, roommates cannot share an apartment unless the landlord gives their consent. Unless it is expressly stated in their Dubai tenancy contract, the tenant is not permitted to sublet the property without the landlord's consent, according to Article 24 of Law No. 26 of 2007.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In Dubai, is rent a monthly rental cheaper than yearly rental?",
            "acceptedAnswer" :
            {
            "text" : "Although it's uncommon, it is possible to discover rental homes in Dubai with monthly payment options. Even though monthly rent payments in Dubai may be convenient, they are also expensive. Long-term costs are more than they would be if you choose a single cheque.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How much should my rent be in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "As a general rule, you shouldn't spend more than 30% of your income on housing. For instance, on a salary of AED 20,000 per month, AED 6,000 or less per month, or AED 72,000 annually, should be set aside for housing. Everyone's financial condition is unique, though.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What would Dubai's rent rise be in 2022?",
            "acceptedAnswer" :
            {
            "text" : "In the year leading up to June, average rentals rose by 21.7%, with increases of 21.2% and 24.7% for apartments and villas, respectively. The average annual rent for an apartment was Dhs85,294 and for a villa was Dhs255,437 as of June 2022.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In which month hotels are cheap in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "The least expensive month is JUNE, JULY , AUGUST, with monthly rent as low as 3500 in a Hotel.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How was 2022 in terms of hotel rates?",
            "acceptedAnswer" :
            {
            "text" : "According to the Global Business Travel Forecast released by CWT and the Global Business Travel Association, hotel prices are predicted to rise 13% globally in 2022 and another 10% in 2023.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Is it better to rent a furnished apartment in Dubai, UAE?",
            "acceptedAnswer" :
            {
            "text" : "In the Emirates, furnished apartments often rent for more money than their unfurnished equivalents. However if you consider the total cost of singing up a unfurnished lease and renting a flexible stay it is in most cases a cheapest option to consider.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How can I afford to live in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "Suggestions for affordable Dubai living: 1- Search for an accessible neighborhood. 2- Legally share an apartment. 3- Use public transport in Dubai, live close to your place of work or children's school, use alternative routes to avoid road tolls, shop at cheap supermarkets, and so on. 4- Purchase locally. 5- Search for free or cheap things to do in Dubai.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Is it legal for unmarried couples to stay together at a hotel in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "Yes, now it is legal for unmarried couples to stay together at a hotel in Dubai, Abu Dhabi, Sharjah, and the rest of the UAE. The government of the UAE announced alterations to the nation's Islamic personal laws. According to this, unmarried couples in the UAE will now be able to cohabitate under the new regulation. Now unmarried couples are free to cohabitate without facing consequences, being investigated, or worrying about being reported.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Can unmarried couples permitted to stay in a hotel room in Abu Dhabi?",
            "acceptedAnswer" :
            {
            "text" : "Yes, unmarried couples permitted to stay in a hotel room in Abu Dhabi and the rest of the UAE. The UAE relaxes Islamic restrictions and permits unmarried couples to live together. The new action is intended to raise the standard of living for citizens of the nation. Article 356 has been completely altered by FDL 15/2020, and the provisions for indecent assault have been eliminated. Following this, unmarried couples are permitted to share a home and cohabit without getting married in UAE.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which months has the lowest cost in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "1- Depends, if you want to enjoy Sun and beach, the summers are good especially August and March. If you are already coming from a hot destination, our advice would be to travel to Dubai in either late November or early December till February.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which month is the least expensive to visit Dubai?",
            "acceptedAnswer" :
            {
            "text" : "June-August is often the cheapest month to go to Dubai.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            }     ],
      }}
    />

                    <Collapse accordion ghost>
                        {
                            data.map((item, index)=>{
                                return(
                                    <>
                                        <Panel header={item.title} key={index}>
                                            {item.content.map((item, index)=>{
                                                return(<><p>{item.title}</p></>)
                                            })}
                                        </Panel>
                                    </>
                                )
                            })
                        }
                    </Collapse>
                </div>
            </main>
        </>
    )
}
