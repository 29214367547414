import React, { useContext, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import axios from "axios";
import moment from "moment";
import { AutoComplete, Select } from "antd";
import { Carousel } from "antd-mobile";
import { Button, ButtonBase } from "@material-ui/core";
import { Person } from "schema-dts";
import { JsonLd } from "react-schemaorg";
// import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import { AppContext } from "../AppContext";
import { getLsValue, setLsValue } from "../../utils/functions";
import app from "../utils/appConfig";
import PageHeader from "../_newComponents/pageHeader";
import MyLeaderBoardAd from "../_newComponents/GoogleAds";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import MobileNav from "../_newComponents/MobileNav";
import SearchListing from "../_newComponents/SearchListing";
import FilterButtons from "../_newComponents/FilterButtons";
import HotelImage from "../images/hotel.png";
import NoImage from "../images/no-image.png";
import PeopleIcon from "../icons/people.svg";
import WashroomIcon from "../icons/washroom.svg";
import PhoneIcon from "../icons/phone.svg";
import SearchIcon from "../icons/search.svg";
import CrossIcon from "../icons/cross_dark.svg";
import RightIcon from "../icons/right-arrow.svg";
import "./style/coLiving.scss";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  CACHE__CO_API,
  CACHE__CO_FILTERS,
  CACHE__COLIVINGS,
  CACHE__CO_PAGENUM,
} from "../data/cacheKeys";

const { Option } = Select;

const cities = [
  { label: "Abu Dhabi", value: "abu-dhabi", id: 48973 },
  { label: "Ajman", value: "ajman", id: 48974 },
  { label: "Dubai", value: "dubai", id: 48975 },
  { label: "Ras Al Khaimah", value: "ras-al-khaimah", id: 48976 },
  { label: "Sharjah", value: "sharjah", id: 48977 },
  { label: "Umm Al Quwain", value: "umm-al-quwain", id: 48979 },
  { label: "Fujairah", value: "fujairah", id: 48980 },
];

const CoLivingPage = () => {
  const { country, city, building, location, roomType } = useParams();

  const [isFilter, setIsFilter] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [val, setVal] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [coLivings, setCoLivings] = useState([]);
  const [noListing, setNoListing] = useState([false]);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [api, setApi] = useState("get-property-list");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState([]);
  const [seoSelectedLocBuil, setSeoSelectedLocBuil] = useState([]);
  const [selectedCity, setSelectedCity] = useState("All Cities");
  const [locAndCityid, setLocAndCityId] = useState({
    city_id: city ? city : 48975,
    data: "",
  });
  // const [filters, setFilters] = useState([
  //   ["hotels.admin_status", 1],
  //   ["hotels.status", 1],
  //   ["hotels.hotel_type", "room"],
  // ]);

  const cachedFilters = getLsValue(CACHE__CO_FILTERS);
  const [filters, setFilters] = useState(
    cachedFilters || [
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "room"],
    ]
  );

  const cachedNum = getLsValue(CACHE__CO_PAGENUM);
  const [pageNumber, setPageNumber] = useState(cachedNum || 1);

  const cachedUrl = getLsValue(CACHE__CO_API);
  const [api, setApi] = useState(cachedUrl || "get-property-list");

  useEffect(() => {
    const prevUrl = getLsValue(CACHE__CO_API);

    if (prevUrl) setApi(prevUrl);
    else setLsValue(CACHE__CO_API, api);
  }, []);

  useEffect(() => {
    const prevNum = getLsValue(CACHE__CO_PAGENUM);

    if (prevNum) setPageNumber(prevNum);
    else setLsValue(CACHE__CO_PAGENUM, pageNumber);
  }, []);

  useEffect(() => {
    const prevItems = getLsValue(CACHE__CO_FILTERS);

    if (prevItems && (Object.keys(prevItems).length || prevItems.length))
      setFilters(prevItems);
    else setLsValue(CACHE__CO_FILTERS, filters);
  }, []);

  useEffect(() => {
    const prevItems = getLsValue(CACHE__COLIVINGS);

    if (prevItems && prevItems.length && !isLoadMore && !isFilter)
      setCoLivings(prevItems);

    const data = new FormData();
    for (let i in filters) data.append(i, filters[i]);

    const config = {
      method: "post",
      url: `${app.baseUrl}/api/${
        api !== "listing_filter" && (country || city || location || roomType)
          ? `flat-share${country !== undefined ? "/" + country : ""}${
              city !== undefined ? "/" + city : ""
            }${location !== undefined ? "/" + location : ""}${
              roomType !== undefined ? "/" + roomType : ""
            }`
          : api
      }?page=${pageNumber}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then((res) => {
        const data = [];

        if(!res.data.data.length)
        {
          setNoListing(true);
                const data = new FormData();
          for (let i in filters) data.append(i, filters[i]);

          const config1 = {
            method: "post",
            url: `${app.baseUrl}/api/get-property-list?page=${pageNumber}`,
            headers: {},
            data: data,
          };

          axios(config1)
      .then((res) => {
        const data = [];
        res.data.data.map((item) => {
          const isAfter = moment(item.avalability_date).isAfter(
            moment().format("YYYY-MM-DD"),
            "day"
          );

          item.date = isAfter
            ? moment(item.avalability_date).format("MMMM D")
            : null;

          data.push({
            bedspace_sku: item.bedspace_sku,
            name: item.name,
            profile: item.fld_profile_pic,
            lat: Number(item.fld_latitude),
            lng: Number(item.fld_longitude),
            roomName: item.custom_room_name,
            bath: item.bath,
            rent: item.rent,
            images: item.room_images,
            bedtype: item.bedtype,
            currency: item.currency,
            building: item.building_id,
            area: item.Area,
            price: item.room_expected_rent,
            otherHouseRules: item.other_house_rules,
            avalability: item.avalability_date,
          });
        });

        setIsLoading(false);
        if (res.data.data.length !== 0) setLoadMore(true);

        if (
          !prevItems ||
          (prevItems && !prevItems.length) ||
          isLoadMore ||
          isFilter
        ) {
          if (isFilter) {
            setCoLivings(res.data.data);
            setLsValue(CACHE__COLIVINGS, res.data.data);
          } else {
            setCoLivings((prev) => [...prev, ...res.data.data]);
            setLsValue(CACHE__COLIVINGS, [...coLivings, ...res.data.data]);
          }
        }

        if (
          api === "get-property-list" &&
          pageNumber === 1 &&
          prevItems &&
          prevItems.length &&
          JSON.stringify(res.data.data[0]) !== JSON.stringify(prevItems[0])
          // res.data.data[0].bedspace_id !== prevItems[0].bedspace_id
        ) {
          reset();
          setCoLivings(res.data.data);
          setLsValue(CACHE__COLIVINGS, res.data.data);
        }

        })
        .catch((err) => {
          console.log(err);
  
          setLoadMore(false);
          setIsLoading(false);
        });
        
      }

        res.data.data.map((item) => {
          const isAfter = moment(item.avalability_date).isAfter(
            moment().format("YYYY-MM-DD"),
            "day"
          );

          item.date = isAfter
            ? moment(item.avalability_date).format("MMMM D")
            : null;

          data.push({
            bedspace_sku: item.bedspace_sku,
            name: item.name,
            profile: item.fld_profile_pic,
            lat: Number(item.fld_latitude),
            lng: Number(item.fld_longitude),
            roomName: item.custom_room_name,
            bath: item.bath,
            rent: item.rent,
            images: item.room_images,
            bedtype: item.bedtype,
            currency: item.currency,
            building: item.building_id,
            area: item.Area,
            price: item.room_expected_rent,
            otherHouseRules: item.other_house_rules,
            avalability: item.avalability_date,
          });
        });

        setIsLoading(false);
        if (res.data.data.length !== 0) setLoadMore(true);

        if (
          !prevItems ||
          (prevItems && !prevItems.length) ||
          isLoadMore ||
          isFilter
        ) {
          if (isFilter) {
            setCoLivings(res.data.data);
            setLsValue(CACHE__COLIVINGS, res.data.data);
          } else {
            setCoLivings((prev) => [...prev, ...res.data.data]);
            setLsValue(CACHE__COLIVINGS, [...coLivings, ...res.data.data]);
          }
        }

        if (
          api === "get-property-list" &&
          pageNumber === 1 &&
          prevItems &&
          prevItems.length &&
          JSON.stringify(res.data.data[0]) !== JSON.stringify(prevItems[0])
          // res.data.data[0].bedspace_id !== prevItems[0].bedspace_id
        ) {
          reset();
          setCoLivings(res.data.data);
          setLsValue(CACHE__COLIVINGS, res.data.data);
        }
  })
      .catch((err) => {
        console.log(err);

        setLoadMore(false);
        setIsLoading(false);
      });

    if (
      location !== undefined &&
      location !== "bed-space-for-rent" &&
      location !== "room-share-for-rent" &&
      location !== "room-for-rent"
    ) {
      let splitLocation = location.split("-");
      let joinResult = splitLocation.join(" ");

      if (seoSelectedLocBuil.indexOf(joinResult) === -1)
        setSeoSelectedLocBuil([...seoSelectedLocBuil, joinResult]);
    }

    return () => {
      setIsFilter(false);
      setIsLoadMore(false);
      // setApi("get-property-list");
    };
  }, [
    pageNumber,
    filters,
    isLoadMore,
    isFilter,
    selectedLocation,
    selectedBuilding,
  ]);

  const checkNext = () => {
    if (coLivings.length < 10) setLoadMore(false);

    const data = new FormData();
    for (let i in filters) data.append(i, filters[i]);

    const config = {
      method: "post",
      url: `${app.baseUrl}/api/${
        api !== "listing_filter" && (country || city || location || roomType)
          ? `flat-share${country !== undefined ? "/" + country : ""}${
              city !== undefined ? "/" + city : ""
            }${location !== undefined ? "/" + location : ""}${
              roomType !== undefined ? "/" + roomType : ""
            }`
          : api
      }?page=${pageNumber + 1}`,
      headers: {},
      data: data,
    };

    axios(config)
      .then((res) => {
        if (!res.data.data.length) setLoadMore(false);
      })
      .catch((err) => console.log(err));
  };

  const applyFilters = (val = "") => {
    console.log("-->", "object");

    if (val) {
      setSelectedCity(val);
      setLocAndCityId({ ...locAndCityid, city_id: val });
    }

    setIsFilter(true);
    setApi("listing_filter");
    setPageNumber(1);
    setLoadMore(false);
    setFilters({
      ...filters,
      select_city: locAndCityid.city_id,
      selected_areas: selectedLocation,
      selected_buildings: selectedBuilding,
    });

    setLsValue(CACHE__CO_FILTERS, {
      ...filters,
      select_city: locAndCityid.city_id,
      selected_areas: selectedLocation,
      selected_buildings: selectedBuilding,
    });

    setLsValue(CACHE__CO_API, "listing_filter");
    setLsValue(CACHE__CO_PAGENUM, pageNumber);
  };

  const change = (e, type, key, value) => {
    if (type === "checkbox") filters[key] = value || e.target.checked;
    else if (type === "radio") filters[key] = value;
    else if (type === "number") filters[key] = e.target.value;

    setLsValue(CACHE__CO_FILTERS, filters);
  };

  const onSearch = (key, value) => {
    setLocAndCityId({ ...locAndCityid, [key]: value });

    if (value.length > 2) {
      let data = new FormData();
      for (let i in locAndCityid) data.append(i, locAndCityid[i]);

      axios
        .post(`${app.baseUrl}/api/location_filter`, data)
        .then((res) => {
          console.log(res);

          for (let i = 0; i < options.length; i++)
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j].value === options[i].value)
                options.splice(i, 1);
              if (res.data.data[j].id === options[i].id) options.splice(i, 1);
            }

          setOptions([...options, ...res.data.data]);
        })
        .catch((err) => console.log(err));
    }
  };

  const onSelect = (data, e) => {
    const name = e.value;
    const type = e.type;

    if (type === "location")
      if (!selectedLocation.includes(name))
        setSelectedLocation([...selectedLocation, name]);

    if (type === "building")
      if (!selectedBuilding.includes(name))
        setSelectedBuilding([...selectedBuilding, name]);

    // console.log("-->", "data");
    // applyFilters();
  };

  useEffect(() => {
    const elem = document.getElementById("search-autocomplete");

    elem.onblur = () => {
      if (selectedBuilding.length || selectedLocation.length) {
        setVal("");

        // elem.value = "";
        // elem.placeholder = "Find a place to stay";
      }
    };
  }, [selectedBuilding, selectedLocation]);

  function reset() {
    setSelectedCity(48975);
    setLocAndCityId({
      city_id: 48975,
      data: "",
    });
    setIsLoading(true);
    setSelectedLocation([]);
    setSelectedBuilding([]);
    setPageNumber(1);
    setIsFilter(false);
    setApi("get-property-list");
    setVal("");
    setFilters([
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "room"],
    ]);

    

    // const elem = document.getElementById("search-autocomplete");

    // elem.value = "";
    // elem.placeholder = "Find a place to stay";

    setLsValue(CACHE__CO_FILTERS, [
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "room"],
    ]);

    setLsValue(CACHE__CO_API, "get-property-list");
    setLsValue(CACHE__CO_PAGENUM, pageNumber);
  }

  const removeList = (e) => {
    let locationArray = [...selectedLocation];
    let locArrayIndex = locationArray.indexOf(
      e.target.parentElement.textContent
    );
    let buildingArray = [...selectedBuilding];
    let builArrayIndex = buildingArray.indexOf(
      e.target.parentElement.textContent
    );
    // let seoLocBuildArray = [...seoSelectedLocBuil]
    // let seoIndex = seoLocBuildArray.indexOf(e.target.parentElement.textContent)

    if (locArrayIndex !== -1) {
      locationArray.splice(locArrayIndex, 1);
      setSelectedLocation([...locationArray]);
    }
    if (builArrayIndex !== -1) {
      buildingArray.splice(builArrayIndex, 1);
      setSelectedBuilding([...buildingArray]);
    }

    setTimeout(() => {
      if (selectedLocation.length && selectedBuilding.length) {
        applyFilters();
        document.getElementById("filter-it-btn").click();
      } else {
        reset();
      }
    }, 100);

    // if (seoIndex !== -1) {
    //     seoLocBuildArray.splice(seoIndex, 1);
    //     setSeoSelectedLocBuil([...seoSelectedLocBuil]);
    // }
  };

  const separateNumbers = (num) =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;

  console.log(selectedCity, locAndCityid);
  console.log(country, city, location, roomType);

  return (
    <>
      {roomType === undefined ? (
        <MetaTags>
          <title>
            Find A Monthly Flat Share In {`${city}`} | Short Term | Rent Out For
            Free{" "}
          </title>
          <meta
            name="description"
            content="Find and choose from wide range of available monthly flat share in Dubai. We have trusted and top rated monthly flat and accomodation share options. Also post free ad for your flat."
          />
          <link rel="canonical" href={window.location.href} />
          {/* <meta property="og:title" content="MyApp" />
          <meta property="og:image" content="path/to/image.jpg" /> */}
        </MetaTags>
      ) : (
        [
          roomType === "bed-space-for-rent" ? (
            <MetaTags>
              <title>
                Find A Monthly{" "}
                {`${
                  roomType === "bed-space-for-rent" ? "Bed Space For Rent" : ""
                }`}{" "}
                In {`${seoSelectedLocBuil}`} {`${city}`} | Roomdaddy
              </title>
              <meta
                name="description"
                content={`Look for a monthly bed space in ${seoSelectedLocBuil} ${city}? Look no further, with RoomDaddy now you can find best and cheap bed space for short term monthly rent in ${seoSelectedLocBuil} ${city}. Also place free ad today`}
              />
              <link rel="canonical" href={window.location.href} />
            </MetaTags>
          ) : (
            [
              roomType === "room-share-for-rent" ? (
                <MetaTags>
                  <title>
                    Find A Monthly{" "}
                    {`${
                      roomType === "room-share-for-rent"
                        ? "Room Share For Rent"
                        : ""
                    }`}{" "}
                    In {`${seoSelectedLocBuil}`} {`${city}`} | Roomdaddy
                  </title>
                  <meta
                    name="description"
                    content={`Look for a monthly bed space in ${seoSelectedLocBuil} ${city}? Look no further, with RoomDaddy now you can find best and cheap bed space for short term monthly rent in ${seoSelectedLocBuil} ${city}. Also place free ad today`}
                  />
                  <link rel="canonical" href={window.location.href} />
                </MetaTags>
              ) : roomType === "room-for-rent" ? (
                <MetaTags>
                  <title>
                    Find A Monthly{" "}
                    {`${roomType === "room-for-rent" ? "Room For Rent" : ""}`} In{" "}
                    {`${seoSelectedLocBuil}`} | Monthly Rent | Place Free Ad{" "}
                  </title>
                  <meta
                    name="description"
                    content={`With RoomDaddy's rental market place you can find a monthly room for rent in ${seoSelectedLocBuil} ${city} on cheap monthly short term rent. You can post an ad for free of you room in ${seoSelectedLocBuil} ${city}.`}
                  />
                  <link rel="canonical" href={window.location.href} />
                </MetaTags>
              ) : null,
            ]
          ),
        ]
      )}
      {/* if SEO url not have 4 prams */}
      {location === undefined ? (
        <MetaTags>
          <title>
            Find A Flat Share In {`${city ? city : "Room For"}`} Short Term Rent
            | Post A Free Ad Here{" "}
          </title>
          <meta
            name="description"
            content="Find and choose from wide range of available flat share in Dubai. We have trusted and top rated flat and accomodation share options. Also post free ad for your flat."
          />
        </MetaTags>
      ) : (
        [
          location === "bed-space-for-rent" ? (
            <MetaTags>
              <title>
                Find A Monthly{" "}
                {`${
                  location === "bed-space-for-rent" ? "Bed Space For Rent" : ""
                }`}{" "}
                In {`${seoSelectedLocBuil}`} {`${city}`} | Roomdaddy
              </title>
              <meta
                name="description"
                content={`Look for bed space in ${seoSelectedLocBuil} ${city}? Look no further, with RoomDaddy now you can find best and cheap bed space for short term monthly rent in ${seoSelectedLocBuil} ${city}. Also place free ad today`}
              />
            </MetaTags>
          ) : (
            [
              location === "room-share-for-rent" ? (
                <MetaTags>
                  <title>
                    Find A Monthly{" "}
                    {`${
                      location === "room-share-for-rent"
                        ? "Room Share For Rent"
                        : ""
                    }`}{" "}
                    In {`${seoSelectedLocBuil}`} {`${city}`} | Roomdaddy
                  </title>
                  <meta
                    name="description"
                    content={`Look for bed space in ${seoSelectedLocBuil} ${city}? Look no further, with RoomDaddy now you can find best and cheap bed space for short term monthly rent in ${seoSelectedLocBuil} ${city}. Also place free ad today`}
                  />
                </MetaTags>
              ) : location === "room-for-rent" ? (
                <MetaTags>
                  <title>
                    Find A Monthly{" "}
                    {`${location === "room-for-rent" ? "Room For Rent" : ""}`} In{" "}
                    {`${seoSelectedLocBuil}`} {`${city}`} | Monthly Rent | Place
                    Free Ad
                  </title>
                  <meta
                    name="description"
                    content={`With RoomDaddy's rental market place you can find a room for rent in ${seoSelectedLocBuil} ${city} on cheap monthly short term rent. You can post an ad for free of your room in ${seoSelectedLocBuil} ${city}.`}
                  />
                </MetaTags>
              ) : null,
            ]
          ),
        ]
      )}
      <div className="co-living">
        <PageHeader homeLink="/" title="Coliving" isCustom />

        <div
          className="co-living__body"
          style={{ paddingBottom: "75px", paddingTop: "75px" }}
        >
        <h1
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 22,
              lineHeight: "145.9%",
              color: "rgb(0 0 0 / 7%)",
            }}
          >
              <b>Co-living, Room for Rent in UAE</b>
        </h1>
        <ResponsiveAd />
<JsonLd
      item={{
        "@context":"http://schema.org",
        "@type":"FAQPage",
        "mainEntity" : [
          {
          "name" : "How much is a room for rent in dubai?",
          "acceptedAnswer" :
          {
          "text" : "Independent rooms in Dubai starts from AED 1500 upto AED 6000 in a flatshare accommodation. It depends on the size, location, amenities and other features like Gym, Pool, etc.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "How much does a bedspace cost in dubai?",
          "acceptedAnswer" :
          {
          "text" : "Bedspaces can start as low as AED 400 per month upto AED 2500 per month. The price is generally based on number of occupants sharing the room. A bedspace in Diera, Bur Dubai can be cheapest amongst other locations AED 400 however in Dubai Marina Bedspace can go upto AED 2500.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "Is it secure to share a flat with strangers in Dubai?",
          "acceptedAnswer" :
          {
          "text" : "Dubai is considered as one of the safest places globally, most of the young working population considers flat share due to its cost effectiveness and convenience.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "What is the most popular areas to rent a room in Dubai?",
          "acceptedAnswer" :
          {
          "text" : "The most sought for locations to live in Dubai are Dubai Marina, Palm Jumeirah, JLT, JBR, Downtown, Sheikh Zayed Road, Business Bay. All these locations are conveniently accessible to metro stations.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          }    ],
      }}
    />


          <div className="search-container">
            <div className="search-listing d-flex align-items-center justify-content-between">
              <form
                className="search-listing__container mr-2"
                style={{ flex: 1 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  applyFilters();
                }}
              >
                <img src={SearchIcon} alt="Search" />

                <AutoComplete
                  style={{
                    background: "inherit",
                    borderRadius: "999px",
                    boxShadow: "none",
                  }}
                  id="search-autocomplete"
                  className="form-control search"
                  // type="text"
                  // value={terms}
                  // onChange={(e) => setTerm(e.target.value)}
                  value={val}
                  placeholder="Find a place to stay"
                  allowClear={true}
                  options={options}
                  onSelect={async (data, e) => {
                    await onSelect(data, e);
                    setVal("");

                    setTimeout(() => {
                      applyFilters();
                      document.getElementById("filter-it-btn").click();
                    }, 100);

                    // const elem = document.getElementById("search-autocomplete");

                    // elem.value = "";
                    // elem.placeholder = "Find a place to stay";
                  }}
                  onSearch={(value) => {
                    onSearch("data", value);
                    setVal(value);
                  }}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  // options={options}
                  // onSelect={onSelect}
                  // onSearch={(value) => {
                  //   onSearch("data", value);
                  //   setVal(value);
                  // }}
                  // filterOption={(inputValue, option) =>
                  //   option.value
                  //     .toUpperCase()
                  //     .indexOf(inputValue.toUpperCase()) !== -1
                  // }
                />

                {/* <button type="submit" className="search-listing__action">
                  <img src={RightIcon} alt="Right Arrow - RoomDaddy" />
                </button> */}
              </form>

              <div className="search-listing__actions">
                <Select
                  style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                  value={selectedCity}
                  onChange={(val) => {
                    setSelectedCity(val);
                    setLocAndCityId({ ...locAndCityid, city_id: val });
                    applyFilters();
                    // setTimeout(() => applyFilters(val), 100);
                  }}
                >
                  {cities.map((city, i) => (
                    <Option key={i} value={city.id}>
                      {city.label}
                    </Option>
                  ))}
                </Select>

                <Button
                  id="filter-it-btn"
                  onClick={() => applyFilters()}
                  className="bookN-s d-none"
                  style={{ fontWeight: "bold" }}
                >
                  Search
                </Button>
              </div>
            </div>

            {/* <div className="cities">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={(value) =>
                  setLocAndCityId({ ...locAndCityid, city_id: value })
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cities.map((city, i) => (
                  <Option key={i} value={city.id}>
                    {city.label}
                  </Option>
                ))}
              </Select>
            </div> */}
          </div>

          {/* <p className="text-muted py-1 px-3 mb-0">No location selected</p> */}

          {!seoSelectedLocBuil.length &&
          !selectedBuilding.length &&
          !selectedLocation.length ? null : (
            <div className="px-3 loc-info">
              {seoSelectedLocBuil.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">You search for</p>
                  <ul className="text-secondary small lists pl-0">
                    {seoSelectedLocBuil.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              {selectedLocation.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">Locations</p>
                  <ul className="text-secondary small lists pl-0">
                    {selectedLocation.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              {selectedBuilding.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">Buildings</p>
                  <ul className="text-secondary small lists pl-0">
                    {selectedBuilding.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          )}

          <div className="co-living__filters">
            <FilterButtons
              onReset={reset}
              onSearch={applyFilters}
              filters={filters}
              setFilters={(newFilters) => {
                setFilters(newFilters);
                applyFilters();
                // setLsValue(CACHE__CO_FILTERS, newFilters);
              }}
              change={change}
            />
          </div>

          <div className="co-living__listing">
            {isLoading && (
              <ContentLoader
                speed={2}
                style={{ width: "100%", height: "100%" }}
                viewBox="0 0 300 340"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="-2" y="-2" rx="0" ry="0" width="400" height="225" />
                <rect x="12" y="240" rx="0" ry="0" width="135" height="12" />
                <rect x="12" y="265" rx="0" ry="0" width="215" height="12" />
                <rect x="12" y="300" rx="0" ry="0" width="85" height="12" />
                <rect x="190" y="300" rx="0" ry="0" width="100" height="12" />
              </ContentLoader>
            )}

            {noListing===true && !isLoading ? (
              <div className="co-living__no-listing">
                <p>Oops! No results found in this area.</p>

                {/* <p className="text-darkBlue" onClick={reset}>
                  See latest listings
                </p> */}
              </div>
            ) : null}

            {coLivings.map((coLiving, i) => {
              if (
                (!coLiving.custom_room_name && !coLiving.room_name) ||
                coLiving.rent < 300
              )
                 return <></>;
              if(i === 3 || i === 15 || i === 29 ||  i===42)
              return <MyLeaderBoardAd/>

              return (
                <div key={i} className="co-living__list">
                  <Link to={`/flatshare/list/${coLiving.bedspace_sku}`}>
                    {coLiving.room_images.length ? (
                      <Carousel className="co-living__carousel">
                        {coLiving.room_images.map((img, idx) => (
                          <img
                            key={idx}
                            src={img}
                            alt={
                              coLiving.custom_room_name || coLiving.room_name
                            }
                          />
                        ))}
                      </Carousel>
                    ) : (
                      <img
                        style={{ width: "100%" }}
                        src={NoImage}
                        alt={coLiving.custom_room_name || coLiving.room_name}
                      />
                    )}
                  </Link>

                  <JsonLd
      item={{
        "@context":"http://schema.org",
        "@type":"accommodation",
        "accommodationCategory": (coLiving.fld_type === 'B' ? 'Shared Room' : 'Private Room'),
        "name": (coLiving.fld_custom_room_name),
        "description": (coLiving.description),
        "url": `https://m.roomdaddy.com/flatshare/list/${coLiving.bedspace_sku}`,
        "address":{"@type":"PostalAddress",
        "addressLocality":coLiving.Area,
        "addressRegion":coLiving.city},
        "leaseLength": {
          "@type": "QuantitativeValue",
          "value": 1,
          "unitText": "months"},
        "":{"@type":"Product","name": (coLiving.fld_type === 'B' ? 'Bedspace for rent' : 'Room for rent'),
        "offers":{"@type":"Offer","price":(coLiving.room_expected_rent)+".00","priceCurrency":coLiving.currency,"description": coLiving.description,"url":`https://m.roomdaddy.com/flatshare/list/${coLiving.bedspace_sku}`},"image":coLiving.room_images[0]},
        "image":coLiving.room_images[0],
        "geo":{"@type":"GeoCoordinates","latitude":coLiving.fld_latitude,"longitude":coLiving.fld_longitude}
      }}
/>
        <JsonLd
        item={{
        "@context":"https://schema.org",
                   "@type":"RentAction",
                   "startTime":coLiving.avalability_date,
                   "endTime":coLiving.avalability_end,
                   "agent":{"@type":"Person","name":coLiving.name},
                   "object":{"@type":"Residence","name":coLiving.fld_custom_room_name},
                   "priceSpecification":{"@type":"PriceSpecification","price":coLiving.room_expected_rent+".00","priceCurrency":coLiving.currency}
      }}
    />

                  {/* <ButtonBase> */}
                  <div className="co-living__ripple">
                    <div className="co-living__list-body">
                      <Link to={`/flatshare/list/${coLiving.bedspace_sku}`}>
                        <h2>
                          {coLiving.custom_room_name || coLiving.room_name}
                        </h2>
                      </Link>

                      <p>
                        {coLiving.Area}, {coLiving.city}
                      </p>

                      <div className="co-living__info">
                        <div>
                          <img src={PeopleIcon} alt="Room" />
                          <span>
                            {coLiving.fld_type === "R"
                              ? "Private Room"
                              : "Shared Room"}
                          </span>
                        </div>

                        <div>
                          <img src={WashroomIcon} alt="Washroom" />
                          <span>
                            {coLiving.bath ||
                              coLiving.bath_type ||
                              "Unspecified"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="co-living__list-footer pb-4">
                      <div className="co-living__pricing">
                        <p>
                          Available{" "}
                          {coLiving.date ? `from ${coLiving.date}` : "Now"}
                        </p>

                        <p className="co-living__price">
                          <span>AED {separateNumbers(coLiving.rent)}</span> /
                          month
                        </p>
                      </div>

                      <div
                        className="co-living__cta"
                        style={coLiving.number ? {} : { display: "none" }}
                      >
                        <a href={`tel:${coLiving.number}`}>
                          <Button variant="contained">
                            <img src={PhoneIcon} alt="call" />
                            <span>Call</span>
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* </ButtonBase> */}
                  
                </div>
                
              );


            })}

            {!isLoading ? (
              <>
                {loadMore && coLivings.length ? (
                  <div
                    className="pt-3 border-top px-2"
                    style={{ display: "grid", placeItems: "center" }}
                  >
                    <Button
                      className="filter-Reset w-100"
                      onClick={() => {
                        setPageNumber(pageNumber + 1);
                        setLoadMore(false);
                        setIsLoadMore(true);
                        setIsLoading(true);
                        setLsValue(CACHE__CO_PAGENUM, pageNumber + 1);
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Load more"}
                    </Button>
                  </div>
                ) : null}

                {!loadMore ? (
                  <div className="co-living__no-listing border-top">
                    <p>You reached the end.</p>

                    <p className="text-darkBlue" onClick={reset}>
                      See latest listings
                    </p>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          {/* Accordion */}
        <div className="w-100 border-top">
          {
            
              <>
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='futuraN700-20'>Frequently Asked Questions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  
                    <div className='borderRadius-15 badge_colour_primary'>
                      <div className='futuraN700-16 text-lightBlack'>
                        <p className='mb-0'>How much is a room for rent in dubai?</p>
                        <p className='futuraBkBtN-14'>Independent rooms in Dubai starts from AED 1500 upto AED 6000 in a flatshare accommodation. It depends on the size, location, amenities and other features like Gym, Pool, etc.</p>
                        <p className='mb-0'>How much does a bedspace cost in dubai?</p>
                        <p className='futuraBkBtN-14'>Bedspaces can start as low as AED 400 per month upto AED 2500 per month. The price is generally based on number of occupants sharing the room. A bedspace in Deira, Bur Dubai can be cheapest amongst other locations AED 400 however in Dubai Marina Bedspace can go upto AED 2500.</p>
                        <p className='mb-0'>Is it secure to share a flat with strangers in Dubai?</p>
                        <p className='futuraBkBtN-14'>Dubai is considered as one of the safest places globally, most of the young working population considers flat share due to its cost effectiveness and convenience.</p>
                        <p className='mb-0'>What is the most popular areas to rent a room in Dubai?</p>
                        <p className='futuraBkBtN-14'>The most sought for locations to live in Dubai are Dubai Marina, Palm Jumeirah, JLT, JBR, Downtown, Sheikh Zayed Road, Business Bay. All these locations are conveniently accessible to metro stations.</p>
                      </div>
                    </div>
                    
                </AccordionDetails>
              </Accordion> 
              </>
            
          }
        </div>
        </div>

        <MobileNav />
      </div>
    </>
  );
};

export default CoLivingPage;
