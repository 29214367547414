import React from 'react'
import Header from "../../components/pageHeader/index"
import data from "../../pages/Routes/termsData"
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function TermsCondition() {
    
    return (
        <>
            <Header
                arrowLink={'/'}
                heading={"Terms & conditions"}
            />
            <main className="flex-shrink-0">
                <div className="container">
                    <Collapse accordion ghost>
                        {
                            data.map((item, index)=>{
                                return(
                                    <>
                                        <Panel header={item.title} key={index}>
                                            {item.content.map((item, index)=>{
                                                return(<><p>{item.title}</p></>)
                                            })}
                                        </Panel>
                                    </>
                                )
                            })
                        }
                    </Collapse>
                </div>
            </main>
        </>
    )
}
