import React, { useContext, useState, useEffect } from "react";
import { DatePicker, Space, message } from "antd";
import moment from "moment";
import axios from "axios";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Button } from "@material-ui/core";

const dateFormat = "YYYY-MM-DD";

export default function StepThree({ nextStep, to }) {
  const { userInfo, editRoomDetail } = useContext(AppContext);
  const [toDate, setToDate] = useState(false);
  const [thirdStepInfo, setThirdStepInfo] = useState({
    from_date: userInfo.checkIn,
    to_date: null,
    bills_included: 1,
    distance: "",
    room_deposit: "",
    rent: "",
    minimum_stay: 1,
    typeofview: "Lake View",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function sentFormData() {
    thirdStepInfo.Room_id = userInfo.roomId;
    thirdStepInfo.Bedspace_id = userInfo.bedspaceId;
    thirdStepInfo.building_id = userInfo.propertyId;

    for (let v in thirdStepInfo) {
      if (v === "to_date" || v === "bills_included" || v=== "distance") continue;

      if (Array.isArray(thirdStepInfo[v]) && !thirdStepInfo[v].length) {
        message.error("One or more fields are empty");
        console.log("-->", v, thirdStepInfo[v]);
        return;
      }

      if (thirdStepInfo[v] === "" || thirdStepInfo[v] === 0) {
        message.error("One or more fields are empty");
        console.log("-->", v, thirdStepInfo[v]);
        return;
      }
    }

    let data = new FormData();
    for (let i in thirdStepInfo) {
      data.append(i, thirdStepInfo[i]);
    }
    data.append("fld_tanent", userInfo.id);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`${app.baseUrl}/api/${to ? to : "step-three"}`, data, config)
      .then((res) => {
        message.success("Step 3 completed");
        nextStep(3);
      })
      .catch((err) => console.log(err));
  }
  // useEffect(()=> {
  //     console.log(thirdStepInfo);
  // }, [thirdStepInfo]);

  function updateThirdForm(property, value) {
    if (
      [property] === "room_amenities" ||
      [property] === "house_amenities" ||
      [property] === "outDoorAmenities" ||
      [property] === "house_rules"
    ) {
      if (setThirdStepInfo[property].includes(value)) {
        const index = setThirdStepInfo[property].indexOf(value);
        setThirdStepInfo[property].splice(index, 1);
        // setsetThirdStepInfo({...setThirdStepInfo, [property]: setThirdStepInfo[property]});
      } else {
        setThirdStepInfo[property].push(value);
        // setsetThirdStepInfo({...setThirdStepInfo, [property]: [...setThirdStepInfo[property], value]});
      }
    } else if (
      [property] === "occupancy" ||
      [property] === "room_mates_male" ||
      [property] === "room_mates_female"
    ) {
      if (value === "add") {
        setThirdStepInfo({
          ...setThirdStepInfo,
          [property]: setThirdStepInfo[property] + 1,
        });
      } else if (value === "sub") {
        if (setThirdStepInfo[property] !== 0) {
          setThirdStepInfo({
            ...setThirdStepInfo,
            [property]: setThirdStepInfo[property] - 1,
          });
        }
      }
    } else {
      setThirdStepInfo({ ...thirdStepInfo, [property]: value });
    }
  }

  function disabledDate(current) {
    return current && current < moment(thirdStepInfo.from_date, dateFormat);
  }
  function getSetDate(dateObj, dateStr) {
    if (dateStr.length === 2) {
      setThirdStepInfo({
        ...thirdStepInfo,
        from_date: dateStr[0],
        to_date: dateStr[1],
      });
    }
  }

  useEffect(() => {
    if (editRoomDetail.room_id) {
      setThirdStepInfo({
        ...thirdStepInfo,
        bed_type: editRoomDetail.bed_type ? editRoomDetail.bed_type : "",
        minimum_stay: editRoomDetail.minimum_stay
          ? editRoomDetail.minimum_stay
          : null,
        bills_included: editRoomDetail.bills_included === 1 ? 1 : 0,
        distance: editRoomDetail.metro_train ? editRoomDetail.metro_train : 0,
        room_deposit: editRoomDetail.room_deposit
          ? editRoomDetail.room_deposit
          : 0,
        rent: editRoomDetail.fld_expected_rent
          ? editRoomDetail.fld_expected_rent
          : "",
        from_date: editRoomDetail.avalability_date
          ? editRoomDetail.avalability_date
          : "",
        to_date: editRoomDetail.avalability_end
          ? editRoomDetail.avalability_end
          : "",
      });
    }
  }, [editRoomDetail]);

  return (
    <div className="py-3 px-2">
      <div className="container pb-4 px-3">
        <div className="row">
          <div className="col pb-4">
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Choose a bed type
              </label>

              <Checkbox
                name="bed_type"
                onChange={() => updateThirdForm("bed_type", "Sofa bed")}
                checked={
                  thirdStepInfo.bed_type === "Sofa bed" ? "checked" : false
                }
              >
                Sofa bed
              </Checkbox>

              <Checkbox
                checked={
                  thirdStepInfo.bed_type === "Twin or XL Twin bed"
                    ? "checked"
                    : false
                }
                name="bed_type"
                onChange={() =>
                  updateThirdForm("bed_type", "Twin or XL Twin bed")
                }
              >
                Twin or XL Twin bed
              </Checkbox>

              <Checkbox
                className="ml-0"
                name="bed_type"
                checked={
                  thirdStepInfo.bed_type === "Full, Queen or King bed"
                    ? "checked"
                    : false
                }
                onChange={() =>
                  updateThirdForm("bed_type", "Full, Queen or King bed")
                }
              >
                Full, Queen or King bed
              </Checkbox>

              <Checkbox
                name="bed_type"
                checked={
                  thirdStepInfo.bed_type === "No bed" ? "checked" : false
                }
                onChange={() => updateThirdForm("bed_type", "No bed")}
              >
                No bed
              </Checkbox>

              {/* <div className="custom-control custom-switch">
                <input
                  type="radio"
                  name="bed_type"
                  className="custom-control-input"
                  id="sofa_bed"
                  checked={
                    thirdStepInfo.bed_type === "Sofa bed" ? "checked" : false
                  }
                  onChange={() => updateThirdForm("bed_type", "Sofa bed")}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="sofa_bed"
                >
                  Sofa bed
                </label>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  name="bed_type"
                  className="custom-control-input"
                  id="twin_or_xl_twin"
                  checked={
                    thirdStepInfo.bed_type === "Twin or XL Twin bed"
                      ? "checked"
                      : false
                  }
                  onChange={() =>
                    updateThirdForm("bed_type", "Twin or XL Twin bed")
                  }
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="twin_or_xl_twin"
                >
                  Twin or XL Twin bed
                </label>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="queen_or_king_bed"
                  name="bed_type"
                  checked={
                    thirdStepInfo.bed_type === "Full, Queen or King bed"
                      ? "checked"
                      : false
                  }
                  onChange={() =>
                    updateThirdForm("bed_type", "Full, Queen or King bed")
                  }
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="queen_or_king_bed"
                >
                  Full, Queen or King bed
                </label>
              </div> 

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="no_bed"
                  name="bed_type"
                  checked={
                    thirdStepInfo.bed_type === "No bed" ? "checked" : false
                  }
                  onChange={() => updateThirdForm("bed_type", "No bed")}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="no_bed"
                >
                  No bed
                </label>
              </div>*/}
            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Set the room’s availability
              </label>

              <div className="calender">
                <Space direction="horizontal">
                  <div className="d-flex flex-column">
                    <p className="mb-1">From</p>

                    <DatePicker
                      size="large"
                      placeholder={"Start date"}
                      style={{ width: "100%" }}
                      defaultValue={moment(thirdStepInfo.from_date, dateFormat)}
                      disabledDate={(current) =>
                        current &&
                        current &&
                        current < moment().subtract(1, "d")
                      }
                      onChange={(e, str) =>
                        setThirdStepInfo({
                          ...thirdStepInfo,
                          from_date: str,
                          to_date: moment(str, dateFormat)
                            .add(30, "days")
                            .format(dateFormat),
                        })
                      }
                    />
                  </div>

                  <div className="d-flex flex-column">
                    <p className="mb-1">
                      <Checkbox
                        checked={toDate}
                        onChange={() => setToDate(!toDate)}
                      >
                        To
                      </Checkbox>
                    </p>

                    <DatePicker
                      size="large"
                      placeholder={"End date"}
                      style={{ width: "100%" }}
                      disabled={!toDate}
                      defaultValue={
                        thirdStepInfo.to_date &&
                        moment(thirdStepInfo.to_date, dateFormat)
                      }
                      disabledDate={(current) => {
                        const fromDate = moment(
                          thirdStepInfo.from_date,
                          dateFormat
                        );
                        const isAfter = fromDate?.isAfter(moment());
                        const diffInDays = isAfter
                          ? fromDate?.diff(moment(), "days")
                          : 0;

                        return (
                          current &&
                          current < moment().add(30 + diffInDays, "days")
                        );
                      }}
                      onChange={(e, str) =>
                        setThirdStepInfo({ ...thirdStepInfo, to_date: str })
                      }
                    />
                  </div>
                </Space>
              </div>
            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Minimum stay (months)
              </label>

              <Checkbox
                name="minimum_stay"
                checked={thirdStepInfo.minimum_stay === 1 ? "checked" : false}
                onChange={() => updateThirdForm("minimum_stay", 1)}
              >
                1
              </Checkbox>

              <Checkbox
                name="minimum_stay"
                checked={thirdStepInfo.minimum_stay === 2 ? "checked" : false}
                onChange={() => updateThirdForm("minimum_stay", 2)}
              >
                2
              </Checkbox>

              <Checkbox
                name="minimum_stay"
                checked={thirdStepInfo.minimum_stay === 3 ? "checked" : false}
                onChange={() => updateThirdForm("minimum_stay", 3)}
              >
                3
              </Checkbox>

              <Checkbox
                name="minimum_stay"
                checked={thirdStepInfo.minimum_stay === 6 ? "checked" : false}
                onChange={() => updateThirdForm("minimum_stay", 6)}
              >
                6
              </Checkbox>

              {/* <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="one_month"
                  name="minimum_stay"
                  checked={thirdStepInfo.minimum_stay === 1 ? "checked" : false}
                  onChange={() => updateThirdForm("minimum_stay", 1)}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="one_month"
                >
                  1
                </label>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="two_month"
                  name="minimum_stay"
                  checked={thirdStepInfo.minimum_stay === 2 ? "checked" : false}
                  onChange={() => updateThirdForm("minimum_stay", 2)}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="two_month"
                >
                  2
                </label>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  name="minimum_stay"
                  className="custom-control-input"
                  id="three_month"
                  checked={thirdStepInfo.minimum_stay === 3 ? "checked" : false}
                  onChange={() => updateThirdForm("minimum_stay", 3)}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="three_month"
                >
                  3
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  name="minimum_stay"
                  className="custom-control-input"
                  id="six_month"
                  checked={thirdStepInfo.minimum_stay === 6 ? "checked" : false}
                  onChange={() => updateThirdForm("minimum_stay", 6)}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="four_month"
                >
                  6
                </label>
              </div> */}
            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Bills included
              </label>

              <Checkbox
                name="bills_included"
                checked={
                  thirdStepInfo.bills_included === 1 ? "checked" : false
                }
                onChange={() => updateThirdForm("bills_included", 1)}
              >
                Yes
              </Checkbox>

              <Checkbox
                name="bills_included"
                checked={
                  thirdStepInfo.bills_included === 0 ? "checked" : false
                }
                onChange={() => updateThirdForm("bills_included", 0)}
              >
                No
              </Checkbox>

              {/* <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="bills_included-y"
                  name="bills_included"
                  checked={
                    thirdStepInfo.bills_included === true ? "checked" : false
                  }
                  onChange={() => updateThirdForm("bills_included", true)}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="bills_included-y"
                >
                  Yes
                </label>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="bills_included-n"
                  name="bills_included"
                  checked={
                    thirdStepInfo.bills_included === false ? "checked" : false
                  }
                  onChange={() => updateThirdForm("bills_included", false)}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="bills_included-n"
                >
                  No
                </label>
              </div> */}
            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Distance to Public Transport in minutes
              </label>
              <input
                type="number"
                onChange={(e) => updateThirdForm("distance", e.target.value)}
                className="form-control inputBorder"
                value={thirdStepInfo.distance}
              />
            </div>
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Deposit for the room*{" "}
              </label>
              <input
                type="number"
                onChange={(e) =>
                  updateThirdForm("room_deposit", e.target.value)
                }
                className="form-control inputBorder"
                value={thirdStepInfo.room_deposit}
              />
            </div>
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                What's the monthly rent?*
              </label>
              <input
                type="number"
                onChange={(e) => updateThirdForm("rent", e.target.value)}
                className="form-control inputBorder"
                value={thirdStepInfo.rent}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Continue
      </Button>
      {/* </form> */}
    </div>
  );
}
